/* eslint-disable no-useless-escape */
import { viewImageStyle } from './style'
import { Box, Button, CircularProgress, Container, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { DialogDrawer, DocumentViewer, Slider, TextBox, TopNavBar } from '../../components';
import { useLocation } from 'react-router-dom';
import { useLazyGetUnitAssetsQuery, useLazyImageMutationQuery, useLazyUpdateUnitAssetsQuery } from '../../redux/services';
import { errorRoute, generateImageUrl, img_size, SemiBold } from '../../utils';
import { useSnackbar } from 'notistack';
import useWindowDimensions from '../../useDimension';
import NoData from '../../components/noData';



export const ViewImage = ({ t = () => false }) => {
    const location = useLocation()
    const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/i;

    const [getUnitAssets, { error: isError }] = useLazyGetUnitAssetsQuery();
    const [uploadAsset, { error: isError1 }] = useLazyUpdateUnitAssetsQuery()
    // const [loading1, setLoading1] = React.useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const getVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }
    const [assetView, setAssetView] = useState({
        assetListing: [],
        bool: true,
        drawer: false,
        selected: 0
    })
    const [load ,setLoad] = useState(false)
    const [link, setLink] = React.useState({
        bool: false,
        linkName: "",
        linkText: "",
        error: {
            linkName: "",
            linkText: "",
        }
    })

    const getUnitAssetFunc = async (payload) => {
        const response = await getUnitAssets(payload)
        setAssetView({
            ...assetView,
            assetCategory: response?.data,
            bool: false
        })


    }
    // assetDrawerOpen
    const assetDrawerOpen = (index) => {
        setAssetView({
            ...assetView,
            drawer: true,
            selected: index

        })
    }
    // assetDrawerClose
    const assetDrawerClose = () => {
        setAssetView({
            ...assetView,
            drawer: false
        })
    }
    const getAssetsAPI = async (initial) => {
        getUnitAssetFunc({
            asset_type: location?.state?.data?.id,
            id: location?.state?.id?.id,
        })
    }

    const accept= {
        4:"application/pdf"
    }

    const null_accept = "image/jpeg,image/png"

    // const loadOptions = async (search = "", array, type) => {

    //     setLoading1(type);
    //     let result
    //     switch (type) {
    //         case "categorySelect":
    //             result = await getAssets({
    //                 search
    //             })
    //             setLoading1(null);
    //             return {
    //                 options: [...result?.data],
    //                 hasMore:
    //                     array?.length + result?.data?.length <
    //                     result?.data?.count,
    //             };
    //         default:
    //             return { options: [] };
    //     }
    // };

    const upload = async (payload) => {
        setLoad(true)
        const imageUpload = await uploadAsset(payload)
        if (imageUpload?.isSuccess) {
            enqueueSnackbar(t('Uploaded Successfully'), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            setLoad(false)
            setTimeout(() => {
                closeSnackbar()
            }, 2000)
        }
        else {
            setLoad(false)
            if (isError1?.status === 406) {
                enqueueSnackbar(t('Limit Exceed'), {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }
            else {
                enqueueSnackbar(t('Some Thing Went Wrong'), {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }
            setTimeout(() => {
                closeSnackbar()
            }, 2000)

        }

    }
    const [imageMutation] = useLazyImageMutationQuery()
    // const handleChange = (e) => {
    //     setState(e)
    //     getUnitAssetFunc({
    //         asset_type: location?.state?.data?.id,
    //         id: location?.state?.id
    //     })
    // }
    // updateState Function
    const assetUpdateState = async (e) => {
        setLoad(true)

try{
    if (e?.target?.files) {
        let files = e.target.files;
        const images = []
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append("files", e?.target?.files[i]);
            const image = await imageMutation(formData)
            images.push(image?.data)

        }
        await upload({
            unit_id: location?.state?.id?.id,
            "size_type": "unit_size",
            uom_id: location?.state?.id?.uom_id,
            total_area: location?.state?.id?.total_area,
            assetsData: images?.map((x) => {
                return {
                    url: x,
                    asset_type: location?.state?.data?.id,
                    priority: 1
                }
            }),
            deleteAssets: []


        })
        await getUnitAssetFunc({
            asset_type: location?.state?.data?.id,
            id: location?.state?.id?.id
        })

    }
}
catch(err){
    setLoad(false)

}

    }
    const size = useWindowDimensions();

    React.useEffect(() => {
        getAssetsAPI(true)

        // eslint-disable-next-line
    }, [location?.state])

    React.useEffect(() => {
        if (isError?.status === 423 || isError1?.status === 423) return errorRoute()
    }, [isError, isError1])

    const updateStateLink = (key, value) => {
        setLink({
            ...link,
            [key]: value,
            error: {
                ...link.error,
                [key]: ""
            }
        })
    }

    const onCloseLink = () => {
        setLink({
            bool: false,
            linkName: "",
            linkText: "",
            error: {
                linkName: "",
                linkText: "",
            }
        })
    }

    const validateLink = () => {
        let isValid = true
        let error = link.error

        if (link.linkName === "") {
            isValid = false
            error.linkName = "Link Name is Required"
        }

        if (link.linkText === "") {
            isValid = false
            error.linkText = "Link Text is Required"
        }
        setLink({
            ...link,
            error: {
                ...link.error,
                ...error
            }
        })        
        return isValid
    }

    const uploadVideoLink = async () => {
        if (validateLink()) {
            await upload({
                unit_id: location?.state?.id?.id,
                "size_type": "unit_size",
                uom_id: location?.state?.id?.uom_id,
                total_area: location?.state?.id?.total_area,
                assetsData: [
                    {
                        url: link?.linkText ?? "",
                        asset_type: location?.state?.data?.id,
                        priority: 1
                    }
                ],
                deleteAssets: []
            })
            setLink({
                bool: false,
                linkName: "",
                linkText: "",
                error: {
                    linkName: "",
                    linkText: "",
                }
            })
            await getUnitAssetFunc({
                asset_type: location?.state?.data?.id,
                id: location?.state?.id?.id
            })
        }
    }

    return (
        <Container
            maxWidth="sm"
            sx={viewImageStyle?.containerSm}
        >
            <Box sx={viewImageStyle?.sticky}>
                <Container maxWidth="sm" sx={{ padding: '0px!important' }}>
                    <TopNavBar isDirect title={location?.state?.data?.name} isBack />
                </Container>
            </Box>
            <Box sx={viewImageStyle?.root} style={{ height: size?.height }}>
                {/* <SelectBox
                    onChange={handleChange}
                    loading={loading1 === "categorySelect"}
                    isPaginate={true}
                    loadOptions={(search, array, handleLoading) =>
                        loadOptions(search, array, "categorySelect")}
                    value={state}
                /> */}
                <Box sx={viewImageStyle?.content}>
                    <Grid container spacing={1}>
                        {
                            assetView?.assetCategory?.length > 0 ?
                                assetView?.assetCategory?.map((e, i) => {
                                    return (
                                        e?.asset_type === 4 ?
                                            <Grid item xs={12} onClick={() => assetDrawerOpen(i)}>
                                                <Box sx={viewImageStyle?.imgSec}>
                                                    <DocumentViewer url={e?.url} />
                                                </Box>
                                            </Grid>
                                            :

                                            <>
                                                {e?.asset_type === 3 ?
                                                    youtubeRegex.test(e?.url) ?
                                                        <iframe
                                                            title={"myframe"}
                                                            style={{
                                                                objectFit: "cover",
                                                                height: "200px",
                                                                width: "100%",
                                                                borderRadius: "12px",
                                                            }}
                                                            src={"https://www.youtube.com/embed/" + getVideoId(e?.url)}>
                                                        </iframe>
                                                        :
                                                        <video
                                                            style={{
                                                                objectFit: "cover",
                                                                height: "150px",
                                                                width: "100%",
                                                                borderRadius: "12px",
                                                            }}
                                                            onClick={() => assetDrawerOpen(i)}
                                                            controls
                                                        >
                                                            <source src={e?.url} type="video/mp4" />
                                                        </video>

                                                    :

                                                    // <Grid container>
                                                    <Grid item xs={4} onClick={() => assetDrawerOpen(i)}>
                                                        <Box sx={viewImageStyle?.imgBox} >
                                                            <img width="100%" height="100%" style={{ objectFit: 'cover' }} alt='' src={generateImageUrl(e?.url, img_size.small_square)}></img>
                                                        </Box>
                                                    </Grid>

                                                    //  </Grid>
                                                }

                                            </>




                                    )
                                })
                                :
                                <NoData style={{ display: "flex", alignItems: "center" }} />

                        }

                    </Grid>

                </Box>
            </Box>
            {location?.state?.data?.value?.length === 0 ? "" :
                <Box padding={'16px'} sx={viewImageStyle?.stickyBottom} >
                    <Container maxWidth="sm" sx={{ padding: '0px!important' }}>

                        <Grid item xs={12}>
                            {
                                location?.state?.data?.id === 3 ?
                                    <label for="fileInput" class="custom-file-upload">
                                   
                                            <span style={{ fontFamily: SemiBold }}
                                            onClick={() => setLink({
                                                ...link,
                                                bool: true
                                            })}>Upload Link</span>
                                      
                                    </label>
                                    :
                                    <label for="fileInput" class="custom-file-upload">
                                                    {
                                        load ? 
                                        <span>
                                          <CircularProgress style={{fontSize:6 , color:"#fff"}}/>
                                        </span>
                                        :
                                      <>
                                        <span style={{ fontFamily: SemiBold }}>Upload Assets</span>
                                        <input
                                            type="file"
                                            name="myImage"
                                            onChange={(e) => {
                                                assetUpdateState(e);
                                            }}
                                            onClick={(event) => {
                                                event.target.value = null
                                            }}
                                            style={{ display: "none" }}
                                            id="fileInput"
                                            multiple
                                            accept={accept[location?.state?.data?.id] ?? null_accept}
                                        /></>
}
                                    </label>}



                        </Grid>

                    </Container>


                </Box>
            }
            {/* assetUpload */}
            <DialogDrawer
                maxWidth="sm"
                open={assetView?.drawer}
                isnotTitle
                padding={"16px 16px 0px"}
                height_style={
                    { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                }
                component={
                    <>
                        <Slider
                            title={t("Images")}
                            height={"100% !important"}
                            borderRadius={"4px"}
                            imageHeigth={"300px !important"}
                            assets={assetView?.assetCategory?.map((x) => {
                                return {
                                    meta_data: {
                                        file_type: "image",
                                    },
                                    url: x?.url,
                                }
                            })}
                            onClose={() => assetDrawerClose()}
                            selected={assetView?.selected ?? 0}
                        />
                    </>
                }

                onClose={() => assetDrawerClose()}
            />

            {/* upload video link */}

            <DialogDrawer
                maxWidth="sm"
                open={link?.bool}
                isnotTitle
                padding={"16px 16px 0px"}
                height_style={
                    { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                }
                component={
                    <>
                        <Stack rowGap={2}>
                            <TextBox
                                value={link?.linkName}
                                label="Link Name"
                                isRequired
                                placeholder={"Enter Link Name"}
                                onChange={(val) => updateStateLink("linkName", val.target.value)}
                                isError={link?.error?.linkName?.length > 0}
                                errorMessage={link?.error?.linkName}
                            />
                            <TextBox
                                value={link?.linkText}
                                label="Link"
                                isRequired
                                placeholder={"Enter Link"}
                                onChange={(val) => updateStateLink("linkText", val.target.value)}
                                isError={link?.error?.linkText?.length > 0}
                                errorMessage={link?.error?.linkText}
                            />
                            <Button variant='contained' fullWidth disableElevation onClick={() => uploadVideoLink()}>Save</Button>
                        </Stack>
                    </>
                }
                onClose={() => onCloseLink()}
            />
        </Container>
    )
}

