import React from "react";
import { ParkingSlot } from "./parkingslot";
import { withTranslation } from 'react-i18next';

class ParkingSlotParent extends React.Component {
    constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <ParkingSlot  {...this?.props}/>;
  }
}

export default withTranslation('Parking') (ParkingSlotParent);
