import { Box, Container, Grid, Skeleton, Stack, Typography } from "@mui/material"
import { companySelectStyle } from "./style"
import React, { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { CompanyList } from "./companyList"
import { LocalStorageKeys, RetainLocalStorage, errorRoute } from "../../utils"
import { AppRoutes } from "../../router/routes"
import { useLazyGetAllClientQuery, useLazyGetAllRoleQuery, useLazyInitialRenderQuery } from "../../redux/services";
import { useSnackbar } from 'notistack';
import { Buttons } from '../../components'
import { initital_screen_state } from "../../utils/login/utils"
import { TitleBarNew } from "../../components/titlebarNew"


export const CompanySelect = ({ t = () => false }) => {
    const version = localStorage.getItem(LocalStorageKeys.version);
    const location = useLocation();
    const { enqueueSnackbar , closeSnackbar } = useSnackbar()
    const navigate = useNavigate();
    const [selected, setSelected] = React.useState({})
    const [initialRender] = useLazyInitialRenderQuery();

    // store
    const [getAllClient, { isLoading: btnLoading, error }] = useLazyGetAllClientQuery();


    const [getAllRole, { isSuccess, error: Error }] = useLazyGetAllRoleQuery();
    let roles = {
        tenant: "Tenant",
        owner: "Owner"
    }
    const [is_loading, set_is_loading] = useState(true);
    const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
    const [screen_state, set_screen_state] = React.useState(initital_screen_state);

    React.useEffect(() => {
        initialLoad();
        // eslint-disable-next-line
    }, []);

    const initialLoad = () => {
        getInitialRender();
    }



    const getInitialRender = () => {
        initialRender({}).then((res) => {
            let is_custom_domain = res?.data?.data?.is_custom_domain;
            let _ = res?.data?.data?.render_data?.[0]
            if (_) {
                let temp_screen_state = {
                    ...screen_state,
                    [_?.id && "id"]: _?.id,
                    [_?.region && "region"]: _?.region,
                    [_?.login_logo && "logo"]: _?.login_logo,
                    [_?.web_background && "web_background"]: _?.web_background,
                    [_?.mobile_background && "mob_background"]: _?.mobile_background,
                    [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
                    [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
                    [_?.flag && "flag"]: _?.flag,
                    [_?.domain && "domain"]: _?.domain,
                    [is_custom_domain && "is_custom_domain"]: is_custom_domain,
                }
                set_screen_state(temp_screen_state);
            }
            set_is_loading(false);
        }).catch(() => {
            enqueueSnackbar(t('Some thing Went Wrong'), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
            setTimeout(()=>{
                closeSnackbar()
            },2000)
            set_is_loading(false);
        });
    }
    const submit = async () => {
        let client = await getAllClient({ client_id: selected?.id })

        if (client?.error?.data?.message) {
            // return window.location.href= AppRoutes?.restoreErrorHandling
            RetainLocalStorage();
            navigate(AppRoutes.welcome)
             enqueueSnackbar(`${client?.error?.data?.message}`, {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
            return setTimeout(()=>{
                closeSnackbar()
            },2000)
        }
        localStorage.setItem(LocalStorageKeys.userProfileId, client?.data?.userProfile?.[0]?.id)

        const rolesName = client?.data?.userProfile?.[0]?.roles.map((val) => { return val.name });
        const isOwner = (name) => name === roles.owner;
        //set datas in local
        let role = client?.data?.userProfile?.[0]?.roles?.filter((val) => val.name === roles.owner);

        if (rolesName?.some(isOwner)) {
            const value = await getAllRole({ roleID: role?.[0]?.role_id, token: client?.data?.token })
            if (value?.isError) {
                RetainLocalStorage();
                navigate(AppRoutes.welcome)
                 enqueueSnackbar(`${client?.error?.data?.message}`, {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" }

                })
                return setTimeout(()=>{
                    closeSnackbar()
                },2000)
            }
            localStorage.setItem(LocalStorageKeys.authToken, value?.data?.token)
            localStorage.setItem(LocalStorageKeys.accessType, JSON.stringify(role))
            localStorage.setItem(LocalStorageKeys.client, client?.data?.selected_client.id)

            if (client?.data?.client_currency?.id) {
                localStorage.setItem(LocalStorageKeys.currency, JSON.stringify(client?.data?.client_currency))
            }
            navigate(AppRoutes?.home)
        }
        else {
            navigate(AppRoutes.welcome)
            RetainLocalStorage();

        }
    };

    const companySelect = (client) => {
        setSelected(client)
    }

    React.useEffect(() => {
        if (location?.state?.data?.length === 1) {
            setSelected(location?.state?.data?.[0])
            const submit = async () => {
                let client = await getAllClient({ client_id: location?.state?.data?.[0]?.id })
                if (client?.error?.data?.message) {
                    RetainLocalStorage();
                    navigate(AppRoutes.welcome)
                     enqueueSnackbar(`${client?.error?.data?.message}`, {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" }

                    })
                    return setTimeout(()=>{
                        closeSnackbar()
                    },2000)
                }
                const rolesName = client?.data?.userProfile?.[0]?.roles.map((val) => { return val.name });
                const isOwner = (name) => name === roles.owner;
                if (rolesName?.some(isOwner)) {
                    //set datas in local
                    let role = client?.data?.userProfile?.[0]?.roles?.filter((val) => val.name === roles.owner);
                    //get client
                    if (client?.isSuccess) {
                        localStorage.setItem(LocalStorageKeys.accessType, JSON.stringify(role))
                        localStorage.setItem(LocalStorageKeys.userProfileId, client?.data?.userProfile?.[0]?.id)
                        const value = await getAllRole({ roleID: role?.[0]?.role_id, token: client?.data?.token })
                        if (value?.isSuccess) {
                            localStorage.setItem(LocalStorageKeys.client, client?.data?.selected_client.id)
                            localStorage.setItem(LocalStorageKeys.authToken, value?.data?.token)
                            if (client?.data?.client_currency?.id) {
                                localStorage.setItem(LocalStorageKeys.currency, JSON.stringify(client?.data?.client_currency))
                            }

                            navigate(AppRoutes?.home)

                        }
                        else {
                            navigate(AppRoutes.welcome)
                            RetainLocalStorage();
                            enqueueSnackbar(t(`You do not have any access`), {

                                variant: "error",
                                anchorOrigin: { horizontal: "center", vertical: "top" }

                            })
                         setTimeout(()=>{
                                closeSnackbar()
                            },2000)
                        }

                    }

                }
                else {
                    navigate(AppRoutes.welcome)
                    RetainLocalStorage();
                    enqueueSnackbar(t(`You do not have any access`), {

                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" }

                    })
                     setTimeout(()=>{
                        closeSnackbar()
                    },2000)
                }
            };
            submit()
        }
        if (isSuccess) {

            navigate(AppRoutes.home)
            enqueueSnackbar(t(`Client Selected Successfully`), {

                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
            setTimeout(()=>{
                closeSnackbar()
            },2000)
        }


        // eslint-disable-next-line
    }, [navigate, location?.state?.data])


    React.useEffect(() => {
        if (location?.state?.data?.length > 0) {
            setSelected(location?.state?.data?.[0])
        }
    }, [location?.state?.status, location?.state?.data])


    React.useEffect(() => {
        if (error?.status === 423 || Error?.status === 423) return errorRoute()
    }, [error, Error])


    // React.useEffect(() => {
    //     if (isError) {
    //         localStorage.clear()
    //         navigate(AppRoutes.login)
    //         enqueueSnackbar(t(`User authentication wrong`), {
    //             variant: "error",
    //             anchorOrigin: { horizontal: "center", vertical: "top" }

    //         });
    //     }
    //     // eslint-disable-next-line
    // }, [isError, enqueueSnackbar])



    const handleGoBack = () => {
        localStorage.removeItem(LocalStorageKeys.authToken)
        navigate(-1);
    }

    const handleImageOnLoad = () => {
        setIsBackgroundImageLoading(false);
    }

    const handleImageOnError = () => {
        set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
    }

    return (
        is_loading
            ? <></>
            : <>
                <TitleBarNew is_back_button title={t("Clients")} goBack={handleGoBack} />
                <Container maxWidth="sm" style={{ padding: 0, height: "calc(100vh - 54px)", }}>
                    <Grid container>
                        <Grid
                            item
                            sx={companySelectStyle?.content}>
                            <Grid item xs={12}>
                                <Box sx={companySelectStyle?.mob_background}>
                                    {isBackgroundImageLoading && <Skeleton variant="rounded" sx={companySelectStyle?.mob_background_image} />}
                                    <img
                                        style={{
                                            ...companySelectStyle?.mob_background_image,
                                            display: isBackgroundImageLoading ? "none" : "block",
                                        }}
                                        src={screen_state?.mob_background} alt=""
                                        onLoad={handleImageOnLoad}
                                        onError={handleImageOnError} />
                                </Box>
                            </Grid>
                            <Box height={"16px"} />
                            <Grid item xs={12}>
                                <Typography sx={companySelectStyle?.text}>
                                    {t("Select Client")}
                                </Typography>
                            </Grid>
                            <Box height={"16px"} />
                            <Grid item xs={12}>
                                <Box sx={companySelectStyle?.clientBox} >
                                    {location?.state?.data && location?.state?.data?.map((value, index) => {
                                        return (
                                            <Box onClick={() => setSelected(value)} key={index}>
                                                <CompanyList
                                                    t={t}
                                                    data={value}
                                                    selected={selected?.id}
                                                    companySelect={companySelect}
                                                />
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Grid>
                            <Box height={"16px"} />
                        </Grid>
                    </Grid>
                    <Box sx={companySelectStyle?.companySelectDiv1}>
                        <Container maxWidth="sm" sx={{ padding: "16px", backgroundColor: "#fff" }} >
                            <Box sx={companySelectStyle?.btnGrid} xs={12}>
                                <Buttons fullWidth variant="contained" loading={btnLoading}
                                    sx={companySelectStyle?.btn} onClick={submit} btnName={t("Continue")} btnLoading={btnLoading} />
                            </Box>
                            <Box height={"16px"} />
                            <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                justifyContent={"space-between"} alignItems={"center"}>
                                <img style={companySelectStyle?.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                {version &&
                                    <Typography sx={companySelectStyle?.version_no}>
                                        {`V ${version}`}
                                    </Typography>}
                            </Stack>
                        </Container>
                    </Box>
                </Container>
            </>
    )
}