
import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FooterComponent, FormGenerator, TopNavBar } from "../../components";
import { CreateContact_style } from './style'
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { errorRoute, gender, LocalStorageKeys, SemiBold, yesOrNo } from "../../utils";
import { useLazyGetContactsCreateQuery, useLazyGetContactsUpdateQuery, useLazyGetProfessionMaterQuery, useLazyGetRequestUnitQuery } from "../../redux/services";
import { useSnackbar } from "notistack";
import useWindowDimensions from "../../useDimension";



const initial = () => {
    return {
        contactName: "",
        primaryContact: "",
        gender: [],
        selectprofession: "",
        unitreference: "",
        isShare: [],
        error: {
            contactName: "",
            primaryContact: "",
            gender: [],
            selectprofession: "",
            unitreference: "",
            isShare: [],
        }
    }
}

export const CreateContact = ({
    t = () => false,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({ ...initial() })
    const [page, setPage] = useState("create")
    const [GetContactCreate , {error}] = useLazyGetContactsCreateQuery();
    const [GetContactUpdate , {error:isError}] = useLazyGetContactsUpdateQuery()
    const [GetOwnerUnit] = useLazyGetRequestUnitQuery();
    const [GetProfessionMaster] = useLazyGetProfessionMaterQuery();
    const { enqueueSnackbar ,closeSnackbar} = useSnackbar()
    const user_profile_id = localStorage.getItem(LocalStorageKeys?.userProfileId);
    // eslint-disable-next-line
    const [loading1, setLoading1] = React.useState(null);
    const size = useWindowDimensions();


    const property_id = location?.state?.value?.property_id
    // updateState
    const updateState = (k, v) => {
        let error = data?.error
        error[k] = "";
        setData({ ...data, [k]: v, error });
    };

    // save btn
    const Save = async () => {
        if (validate()) {

            const payload = {
                contact_number: data?.primaryContact?.mobile,
                country_code: data?.primaryContact?.mobile_code,
                gender: data?.gender,
                is_share: data?.isShare?.value ?? data?.isShare,
                name: data?.contactName,
                profession_id: data?.selectprofession?.value,
                property_id: property_id === !undefined ? property_id : data?.unitreference?.property_id,
                unit_id: data?.unitreference?.value,
                user_profile_id: user_profile_id ? user_profile_id : null
            }
            if (data.id) {

                payload['contact_id'] = data?.id
                let ContactUpdateRes = await GetContactUpdate(payload)
                if (ContactUpdateRes?.data?.type === "success") {

                    enqueueSnackbar(
                        t("Contact Updated successfully"), {
                        variant: "success",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })
                    setTimeout(()=>{
                        closeSnackbar()
                    },2000)
                    navigate(AppRoutes?.contacts)
                } else {
                    enqueueSnackbar(
                        t("Contact Updated Unsuccessfully"), {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })
                    setTimeout(()=>{
                        closeSnackbar()
                    },2000)
                }
            } else {
                let ContactCreateRes = await GetContactCreate(payload)
                if (ContactCreateRes?.data?.type === "success") {

                    enqueueSnackbar(
                        t("Contact Created successfully"), {
                        variant: "success",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })
                    setTimeout(()=>{
                        closeSnackbar()
                    },2000)
                    navigate(AppRoutes?.contacts)
                } else {
                    enqueueSnackbar(
                        t("Contact Created Unsuccessfully"), {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })
                    setTimeout(()=>{
                        closeSnackbar()
                    },2000)
                }
            }

        }

    }
    const loadOptions = async (search = "", array, type) => {
        setLoading1(type);
        let result,
            offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }

        switch (type) {
            case "professionalMaster":
                if(!data?.unitreference?.value){
                    setLoading1(null);

                    return []
                }
                result = await GetProfessionMaster({
                    offset,
                    limit: 10,
                    search,
                    is_active:true,
                    unit_id:data?.unitreference?.value ?? data?.unitreference?.id
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data?.data],
                    hasMore:
                        array?.length + result?.data?.data?.data?.length <
                        result?.data?.data?.count,
                };
            case "get-owner-unit":
                result = await GetOwnerUnit({
                    offset,
                    limit: 10,
                    search
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data?.map((x) => {
                        return {
                            value: x?.unit_id,
                            label: x?.unit_name,
                            property_id: x?.property_id,
                            company_id: x?.company_id

                        }
                    })],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.count,
                };

            default:
                return { options: [] };

        }
    };

    const validate = () => {

        let isValid = true;
        let error = data.error
        if (data?.contactName.length === 0) {
            isValid = false
            error.contactName = t("Contact Name is required")
        }


        if (data?.primaryContact?.length === 0) {
            isValid = false
            error.primaryContact = t("Primary Contact is Required")
        }
        if (data?.gender?.length === 0) {
            isValid = false
            error.gender = t("Gender is Required")
        }

        if (data?.selectprofession?.length === 0) {
            isValid = false
            error.selectprofession = t("Select Profession is Required")
        }
        if (data?.unitreference?.length === 0) {
            isValid = false
            error.unitreference = t("Unit Reference is Required")
        }
        if (data?.isShare?.length === 0) {
            isValid = false
            error.type = t("Required")
        }
        setData({ ...data, error })
        return isValid
    }
    const editFunctions = (edit) => {
        setPage(edit)
        if (location?.state) {
            const editData = location?.state?.value
            setData({
                id: editData?.id,
                contactName: editData?.name,
                primaryContact: {
                    mobile_code: editData?.country_code,
                    mobile: editData?.contact_number
                },
                gender: editData?.gender,
                selectprofession: {
                    label: editData?.profession,
                    value: editData?.profession_id
                },
                unitreference: {
                    label: editData?.unit_name,
                    value: editData?.unit_id,
                    property_id: editData?.property_id
                },
                isShare: editData?.is_share,
                error: {
                    contactName: "",
                    primaryContact: "",
                    gender: [],
                    selectprofession: "",
                    unitreference: "",
                    isShare: [],
                }
            })

        }

    }

    // cancel btn
    const cancel = () => {
        setData({ ...initial() })
        navigate(-1)
    }

    useEffect(() => {
        if (location?.state?.page === "view") {
            editFunctions(location?.state?.page)
        }
        // eslint-disable-next-line
    }, [location?.state?.page])


    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423) return errorRoute() 
     },[error , isError])

    return (
        <Container
            sx={CreateContact_style.container}
            maxWidth="sm"
        >
            {/* topNavbar */}

<Box sx={{position:"fixed" , top:"0px" , left:0 , right:0 , zIndex:1}}>
    <Container maxWidth="sm" sx={{padding:"0px !important"}}>
    <TopNavBar title={page === "view" ? t('View Contact') : page === "edit" ? t('Edit Contact') : t('Create Contact')} isDirect route={() => navigate(AppRoutes.contacts)} t={t} />

    </Container>
</Box>
            {/* CreateInvestment */}
            <Box sx={CreateContact_style.root} height={size?.height - 100}>
                <FormGenerator
                    components={[
                        {
                            isActive: true,
                            component: "text",
                            label: t("Contact Name"),
                            value: data?.contactName,
                            placeholder: t("Enter Contact Name"),
                            onChange: (e) => updateState("contactName", e?.target?.value),
                            isRequired: true,
                            error: data?.error?.contactName,
                            isReadonly: page === "view" ? true : false,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "mobileNumber",
                            label: t("Primary Contact"),
                            value: data?.primaryContact,
                            placeholder: t("Enter Primary Contact"),
                            onChange: (e) => updateState("primaryContact", e),
                            error: data?.error?.primaryContact,
                            endAdornment: data?.type,
                            isReadonly: page === "view" ? true : false,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            component: "toggleBlockButton",
                            value: data?.gender,
                            label: t("Gender"),
                            onChange: (e) => updateState("gender", e),
                            error: data?.error?.gender,
                            multiline: true,
                            isRequired: true,
                            is_disable: page === "view" ? true : false,
                            options: gender,
                            size: {
                                xs: 12,
                            },
                            spacing: 2,
                            isActive: true,
                        },
                        {
                            isActive: true,
                            component: "select",
                            label: t("Unit Reference"),
                            value: data?.unitreference,
                            placeholder: t("Enter unit reference"),
                            onChange: (value) => updateState("unitreference", value),
                            error: data?.error?.unitreference,
                            isRequired: true,
                            loading: loading1 === "get-owner-unit",
                            isReadonly: page === "view" ? true : false,
                            loadOptions: (search, array, handleLoading) => {
                                return loadOptions(search, array, "get-owner-unit")
                            },
                            isPaginate: true,
                            size: {
                                xs: 12,
                            },

                        },
                        {
                            isActive: true,
                            component: "select",
                            label: t("Select profession"),
                            value: data?.selectprofession,
                            placeholder: t("Select profession"),
                            onChange: (value) => updateState("selectprofession", value),
                            error: data?.error?.selectprofession,
                            isRequired: true,
                            isPaginate: true,
                            loading: loading1 === "professionalMaster",
                            isReadonly: page === "view" ? true : false,
                            loadOptions: (search, array) => {
                                return loadOptions(search, array, "professionalMaster")
                            },
                            size: {
                                xs: 12,
                            },
                            key:JSON.stringify(data?.unitreference)
                        },
                        {
                            component: "toggleBlockButton",
                            label: t("Make It Public"),
                            value: data?.isShare,
                            onChange: (e) => updateState("isShare", e),
                            error: data?.error?.isShare,
                            multiline: true,
                            options: yesOrNo,
                            is_disable: page === "view" ? true : false,
                            size: {
                                xs: 12,
                            },
                            spacing: 2,
                            isRequired: true,
                            isActive: true,
                        },
                    ]}

                />

            </Box>

            <Box sx={{position:"fixed" , bottom:"0px" , left:0 , right:0 , zIndex:1}}>
    <Container maxWidth="sm" sx={{padding:"0px !important"}}>
    <Box>
                {/* footer Component */}
                <FooterComponent
                    containedonClick={page === "view" ? () => editFunctions("edit") : () => Save()}
                    outlinedonClick={() => cancel()}
                    loading={true}
                    outlinedText={t("Cancel")}
                    containedText={data?.id ? page === "edit" ? t("Update") : t("Edit") : t("Save")}
                />
            </Box>
    </Container>
</Box>

   

        </Container>

    );
}
