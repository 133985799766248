import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { setAuth } from '../../slices/auth';
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";


// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_auth_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        const deviceToken = localStorage.getItem(LocalStorageKeys.deviceToken);

        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");
            if(deviceToken){
                headers.set("devicetoken", deviceToken);
            }

        }
        return headers;
    },
});
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

export const refreshAPI = createApi({
    reducerPath: "RefreshAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ['Refresh'],
    endpoints: (builder) => ({
        // Refresh API
        refresh: builder.query({
            query: (payload) => ({
                url: `refresh`,
                method: "GET",
                // body: { refresh: localStorage.getItem(LocalStorageKeys.authToken) },
                // headers: { 'Authorization': 'Bearer ' + localStorage.getItem(LocalStorageKeys.accessToken) }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    //Saving the response in Auth Slice
                    dispatch(setAuth({ ...data }));
                    localStorage.setItem(LocalStorageKeys.image_url, data?.userProfile?.[0]?.image_url)

                } catch (error) {
                    // Removing the refresh tokens in local storage
                    localStorage.removeItem(LocalStorageKeys.authToken);
                }
            }
        }),
        // getRefresh:builder.mutation({
        //     query: () => ({
        //         url: `refresh`,
        //         method: "GET"
        //     }),
        //     transformResponse: (response, meta, arg) => {
        //         if (response?.type === "success") {
        //             return response;
        //         }
        //         return [];
        //     },

        // }),
        getRefresh: builder.mutation({
            query: (payload) => ({ url: `refresh`, method: "GET"}),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 200) {
                    return response?.data
                }
                return ""
            },
        }),

    }),
})

// Export hooks for usage in functional components
export const { useLazyRefreshQuery ,  useGetRefreshMutation} = refreshAPI