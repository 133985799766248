import { BellIcon, Lanuage } from '../assets'
import jwt_decode from "jwt-decode";
import { AppRoutes } from '../router/routes';
import { config } from "../config"
import Axios from "axios";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */

const language = localStorage.getItem("i18nextLng")

export const UserRoles = {
  role: "role",
};
/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};
/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};
/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};
/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
  accessType: "accessType",
  client: "client",
  currency: "currency",
  userProfileId: "userProfileId",
  welcomeToken: "welcome_token",
  deviceToken: "device_token",
  image_url: "image_url",
  lastCancelClickTime: "lastCancelClickTime",
  i18nextLng:"i18nextLng"

};
// To build version string
export const VersionFetchURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`;
//fonts function
export const Regular = language === "ar" ? "NotoSans-Arabic-Regular" : "NotoSans-Regular";
export const Bold = language === "ar" ? "NotoSans-Arabic-Bold" : "NotoSans-Bold";
export const SemiBold = language === "ar" ? "NotoSans-Arabic-SemiBold" : "NotoSans-SemiBold";
export const ExtraBold = language === "ar" ? "NotoSans-Arabic-ExtraBold" : "NotoSans-ExtraBold";
export const Italic = language === "ar" ? "NotoSans-Arabic-Italic" : "NotoSans-Italic"
export const topNabBarData = () => [
  // {
  //   image: <Document />,
  //   onclick: AppRoutes?.filemanager
  // },
  {
    image: <BellIcon />,
    is_badge: true,
    onclick: AppRoutes?.announcement
  },
  {
    image: <Lanuage />,
    is_click: true
  }
]
export const LanguageOptions = (t) => {
  return [
    { value: "en", label: t("English") },
    { value: "es", label: t("Spanish") },
    { value: "ar", label: t("Arabic") },
    { value: "fr", label: t("French") },
  ]
}
export const topNavBarTittle = (t) => {
  return {
    "Properties": t("Units"),
    "Statistics": t("Statistics"),
    "Profile": t("Profile"),
    "Get Support": t("Get Support"),
    "Profile Edit": t("Profile Edit"),
    "Map": t("Map"),
    "KYC Information": t("KYC Information"),
    "Add ID Proof": t("Add ID Proof")
  }
}
export const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" }
]


export const yesOrNo = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }

]
export const lanuages = [{ value: "en", label: "English" },
{ value: "ta", label: "Tamil" },
{ value: "hi", label: "Hindi" },
{ value: "ar", label: "Arabic" }]

export const getHeader = () => {
  const token = localStorage.getItem(LocalStorageKeys.authToken);
  //get slug
  let client = jwt_decode(token)?.selected_client
  let slug = client?.tenants?.slug
  return [{
    value: config?.xbuildCode,
    label: "x-build-code"
  },
  {
    value: "Bearer " + token,
    label: "Authorization"
  }, {
    value: slug,
    label: "Slug"
  },
  {
    value: "*",
    label: "'Access-Control-Allow-Origin'"
  }
  ];
}
export const getauthHeader = () => {
  const token = localStorage.getItem(LocalStorageKeys.authToken);
  return [{
    value: config?.xbuildCode,
    label: "x-build-code"
  },
  {
    value: "Bearer " + token,
    label: "Authorization"
  }];
}
export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
  else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
  else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
  else if (bytes > 1) { bytes = bytes + " bytes"; }
  else if (bytes === 1) { bytes = bytes + " byte"; }
  else { bytes = "0 bytes"; }
  return bytes;
}

export const img_size = {
  small_square: { width: 100, height: 100, },
  small_rectangle: { width: 192, height: 108, },
  medium_square: { width: 500, height: 500, },
  medium_rectangle: { width: 896, height: 504, },
  large_square: { width: 1000, height: 1000, },
  large_rectangle: { width: 1792, height: 1008, },
}


/**
* Generate Image URL
* @param {String} image_url - S3 Image URL
* @param {int} width - Required width
* @param {int} height - Required height
* @returns String - Converted image size URL
*/
export const generateImageUrl = (image_url, { width, height }) => {
  if (image_url && typeof image_url === 'string' && image_url?.includes("/")) {
    let image_url_split = image_url?.split('/')
    if (Boolean(image_url_split?.[image_url_split?.length - 2]?.includes("X"))) {
      image_url_split[image_url_split?.length - 2] = `${width}X${height}`
      return image_url_split?.join('/')
    } else {
      return image_url
    }
  } else { return "" }

}

export const randomColor = (name) => {
  var colors = ['#5AC782', '#5078E1', '#b05f6d', '#ffc153', '#47b39d'];

  return colors[((name ? name?.length : 0) % colors.length)]
}


export const stringAvatar = (name = "", fontSize = 12) => {  
  let capName = name ? name?.toUpperCase() : ""
  return {
    sx: {
      bgcolor: randomColor(name),
      fontSize: FontSizeCalc(fontSize),
      fontFamily: SemiBold
    },
    children: capName &&
      (capName.split(" ")?.length > 1
        ? `${capName.split(" ")[0][0]}${capName.split(" ")[1][0]}`
        : `${capName.split(" ")[0][0]}`),
  };
}


export const errorRoute = () => {
  return window.location.href = AppRoutes?.restoreErrorHandling

}

export const getTotal = (data, value) => {
  // get total count
  let total = data?.reduce(function (prev, current) {
    return prev + +current[value];
  }, 0);

  return total;
};
// Calculation for the Px into Rem
export const FontSizeCalc = val => `${(val < 12 ? (12 / 16) : (val / 16))}rem`;


export const checkYouTubeLink = (url) => {
  var pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  return pattern.test(url);
}


export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export const RetainLocalStorage = () => {
  let i18nextLng = localStorage.getItem(LocalStorageKeys?.i18nextLng);
  let version = localStorage.getItem(LocalStorageKeys?.version);
  let deviceToken = localStorage.getItem(LocalStorageKeys?.deviceToken);
  localStorage.clear();
  localStorage.setItem(LocalStorageKeys?.i18nextLng, i18nextLng ?? "en-US");
  localStorage.setItem(LocalStorageKeys?.version, version);
  localStorage.setItem(LocalStorageKeys?.deviceToken, deviceToken);

}

export const NetworkCall = async (
  url,
  method,
  body,
  headers,
  isAuthorized = false,
  notValidateURL = false,
  otherProps = {}
) => {
  const token = localStorage.getItem(LocalStorageKeys.authToken);
  //get slug
  let client = jwt_decode(token)?.selected_client
  let slug = client?.tenants?.slug
  //Check for body of the POST method
  if (method && method === NetWorkCallMethods.post && !body) {
    return Promise.reject({
      message: "POST method must contain Request Body.",
    });
  }
  //Checking the Internet connection
  if (!navigator.onLine) {
    return Promise.reject({ message: "Unable to connect with Internet!" });
  }
  //Initializing the header
  let newHeader = {
    ...headers,
    "x-build-code": config.xbuildCode,
  };

  //Adding Authorization to headers if it is requested



  if (isAuthorized) {
    newHeader = {
      ...headers,
      Authorization: "Bearer " + token,
      Slug: slug,
      "x-build-code": config.xbuildCode,
      "'Access-Control-Allow-Origin'": "*"
    };
  }

  try {
    return await Axios({
      method: method,
      url: url,
      data: body,
      headers: newHeader,
      ...otherProps
    });
  } catch (error) {
    if (error?.response?.status === 423) {
      errorRoute();
      RetainLocalStorage();
    } else {
      return Promise.reject(error);
    }
  }
};


const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  era: 'short',
  calendar: 'islamic-umalqura' // Specify the Hijri calendar
};

export const hijriFormatter = new Intl.DateTimeFormat('ar-SA-u-ca-islamic-umalqura', options);
