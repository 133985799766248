import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { PropertyUnitNewStyle } from './style'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkYouTubeLink } from '../../utils'
import { DialogDrawer, DocumentViewer, TopNavBar } from '../../components'
import NoDataShow from '../../assets/noData'
import { ImageSlider } from '../../components/imageSlider'

const AssetViewPage = ({
    t = () => false
}) => {
    const classes = PropertyUnitNewStyle
    const location = useLocation()
    const navigate = useNavigate()
    const tab = [
        {
            label: "Image",
            value: 1,
            type: "image",
            isActive: true
        },
        {
            label: "Pdf",
            value: 2,
            type: "pdf",
            isActive: true
        },
        {
            label: "Video",
            value: 3,
            type: "video",
            isActive: location?.state?.hideVideoTab ? false : true
        }
    ]
    

    const [selectedTab, setSelectedTab] = React.useState(tab?.[0])
    const [selectedImage, setSelectedImage] = React.useState()
    const [view, setView] = React.useState({
        bool: false,
        data: "",
        selectedImageIndex: 0
    })

    React.useEffect(() => {
        filterUnitImage("image")
        // eslint-disable-next-line
    }, [])

    const handleChangeTab = (value) => {
        setSelectedTab(value)
    }

    const getVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    const filterUnitImage = (type) => {
        let data = []
        switch (type) {
            case "image":
                data = location?.state?.assets?.map((i) => {
                    return (!checkYouTubeLink(i?.url) && i?.url?.slice(i?.url?.length - 3, i?.url?.length) !== "pdf") ? i : false
                })
                break
            case "video":
                data = location?.state?.assets?.map((i) => {
                    return (checkYouTubeLink(i?.url) && i?.url?.slice(i?.url?.length - 3, i?.url?.length) !== "pdf") ? i : false
                })
                break
            case "pdf":
                data = location?.state?.assets?.map((i) => {
                    return (!checkYouTubeLink(i?.url) && i?.url?.slice(i?.url?.length - 3, i?.url?.length) === "pdf") ? i : false
                })
                break

            default:
                break;
        }
        return data?.filter((i) => i !== false)
    }
    return (
        <>
            <TopNavBar title={"Asset View"} isDirect route={() => navigate(-1)} t={t} />
            <Container maxWidth={'sm'} sx={{ position: 'relative', padding: "0px !important", backgroundColor: "#fff", height: "calc(100vh - 58px)" }}>
                <Box p={2}>
                    <Grid container sx={classes.tabBox}>
                        {
                            tab?.map((e) => {
                                return (
                                    <>
                                        {
                                            e?.isActive &&
                                            <Grid item xs={location?.state?.hideVideoTab ? 6 : 4}>
                                                <Typography sx={selectedTab?.value === e?.value ? classes.selectedTab : classes.unSelectedTab}
                                                    onClick={() => handleChangeTab(e)}>
                                                    {e?.label}
                                                </Typography>
                                            </Grid>
                                        }
                                    </>
                                )
                            })
                        }
                    </Grid>
                    {
                        location?.state?.assets?.length === 0 ?
                            <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "calc(100vh - 135px)" }}>
                                <NoDataShow />
                            </Stack>
                            : <>
                                {
                                    filterUnitImage(selectedTab?.type)?.length === 0 ?
                                        <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "calc(100vh - 100px)" }}>
                                            <NoDataShow />
                                        </Stack>
                                        :
                                        <Grid container spacing={1} sx={{ height: "calc(100vh - 135px)", overflow: "auto" }} mt={2}>
                                            {filterUnitImage(selectedTab?.type)?.map((e, i) => {
                                                return (
                                                    <Grid item xs={selectedTab?.value === 1 ? 6 : 12}
                                                        onClick={() => {
                                                            setView({
                                                                bool: true,
                                                                data: e,
                                                                selectedImageIndex: i
                                                            })
                                                            setSelectedImage(filterUnitImage(selectedTab?.type)?.[0])
                                                        }}
                                                    >
                                                        {
                                                            selectedTab?.value === 1 &&
                                                            <img src={e?.url} alt='' style={{ height: "150px", width: "100%", objectFit: "cover", borderRadius: "8px" }} />
                                                        }
                                                        {
                                                            selectedTab?.value === 2 &&
                                                            <Box sx={{ height: "300px", width: "100%" }}>
                                                                <DocumentViewer url={e?.url} />
                                                            </Box>
                                                        }
                                                        {
                                                            selectedTab?.value === 3 &&
                                                            <Box>
                                                                <iframe
                                                                    title={"myframe"}
                                                                    style={{
                                                                        objectFit: "cover",
                                                                        height: "300px",
                                                                        width: "100%",
                                                                        borderRadius: "12px",
                                                                    }}
                                                                    src={"https://www.youtube.com/embed/" + getVideoId(e?.url)}>
                                                                </iframe>
                                                            </Box>
                                                        }
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                }
                            </>
                    }
                </Box>
            </Container>

            <DialogDrawer
                maxWidth="sm"
                open={view?.bool}
                header={selectedTab?.value === 1 ? selectedImage?.file_meta?.name ? selectedImage?.file_meta?.name : "View" : "View"}
                padding="0px"
                onClose={() => setView({
                    bool: false,
                    data: ""
                })}
                height={"500px"}
                component={
                    <Box p={2}>
                        {
                            selectedTab?.value === 1 &&
                            // <CarouselSliders autoScroll={false} data={filterUnitImage("image")} />
                            <ImageSlider image={filterUnitImage("image")} hideViewAll={true} hidelabel={true} height={"450px"} onCurrentImage={(value) => setSelectedImage(value)} customSelectedImage={view?.selectedImageIndex} />
                            // <img src={view?.data?.url} alt='' style={{ height: "470px", width: "100%", objectFit: "contain" }} />
                        }
                        {
                            selectedTab?.value === 2 &&
                            <Box sx={{ height: "470px", width: "100%" }}>
                                <DocumentViewer url={view?.data?.url} />
                            </Box>
                        }
                        {
                            selectedTab?.value === 3 &&
                            <Box>
                                <iframe
                                    title={"myframe"}
                                    style={{
                                        objectFit: "cover",
                                        height: "470px",
                                        width: "100%",
                                        borderRadius: "12px",
                                    }}
                                    src={"https://www.youtube.com/embed/" + getVideoId(view?.data?.url)}>
                                </iframe>
                            </Box>
                        }
                    </Box>
                }
            />
        </>
    )
}

export default AssetViewPage