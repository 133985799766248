import React from "react";
import { Box, Container, Typography } from "@mui/material"
import { TopNavBar, InvoiceCard, BottomInvoice, InvoiceComplaintCard, FooterComponent, DialogDrawer, PaymentModal, DocumentViewer, LoderSimmer, AgreementCard, TopDrawerTab } from "../../components";
import { useLazyAlreadypaidCreationQuery, useLazyGetOwnerAccountsQuery, useLazyGetRequestStatusEnumQuery, useLazyInvoicesOverdueQuery, useLazyInvoicesPdfQuery, useLazyInvoicesQuery } from "../../redux/services";
import { Invoices_style } from "./style"
import { AlreadyPaidDrawer } from "./alreadyPaidDrawer";
import NoData from "../../components/noData";
import { InvoiceSimmerCard, InvoiceSimmmer } from "./simmerComponent/invoiceSimmer";
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "../../router/routes";
import { currencyFormatted, errorRoute } from "../../utils";
import { useSnackbar } from "notistack";
import { TopDrawerTab_style } from "../../components/topdrawertab/style";

export const Invoices = ({ t = () => false }) => {
    const location = useLocation()

    const agreement_id = location?.state
    const initialData = {
        amount_paid: "",
        type: "",
        date: "",
        bank_location: "",
        transaction_id: "",
        notes: "",
        error: {
            amount_paid: "",
            date: "",
            type: "",
            bank_location: "",
            transaction_id: "",
            notes: "",

        }
    }
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()


    const [drawer, setDrawer] = React.useState({
        accountsDrawer: false,
        paymentDrawer: false,
        alreadyPaidDrawer: false
    });

    const [isQuickPay, setIsQuickPay] = React.useState(false);

    const [paymentMode, setPaymentMode] = React.useState([]);

    const [invoiceData, setInvoiceData] = React.useState({
        invoice_list: [],
        simmer: true
    })
    const [overdue, setOverdue] = React.useState({
        listingDatas: {},
        simmer: true
    });
    const [paymentForm, setPaymentForm] = React.useState(initialData);
    const [selectedInvoice, setSelectedInvoice] = React.useState({
        id: "",
        is_checked: false,
        items: []
    });
    const [pdf, setPdf] = React.useState({
        pdf: "",
        bool: false,
        open: false
    })
    const [accounts, setAccounts] = React.useState([]);
    const [ownerAccountsAPI, { error }] = useLazyGetOwnerAccountsQuery()
    const [InvoicesOverdueAPI, { error: isError }] = useLazyInvoicesOverdueQuery()
    const [InvoicesListingAPI, { error: isError1 }] = useLazyInvoicesQuery()
    const [PaymentEnumAPI, { error: isError2 }] = useLazyGetRequestStatusEnumQuery()
    const [AlreadypaidCreation, { error: isError3 }] = useLazyAlreadypaidCreationQuery()
    const [InvoicePdf, { error: isError4 }] = useLazyInvoicesPdfQuery()
    const [selectedAccount, setSelectedAccount] = React.useState({})
    const InvoicesOverdue = async (payload) => {
        const InvoicesOverdueRes = await InvoicesOverdueAPI(payload)
        return setOverdue({
            ...overdue,
            listingDatas: InvoicesOverdueRes?.data?.data,
            simmer: false
        })
    }

    const updateState = (key, value) => {
        let error = paymentForm?.error
        error[key] = "";
        setPaymentForm({
            ...paymentForm, [key]: value, error
        })
    }

    const paymentEnum = async () => {
        const paymentEnumRes = await PaymentEnumAPI(["payment_mode"])
        setPaymentMode(paymentEnumRes?.data?.data)
    }

    const InvoicesListing = async (payload) => {
        const InvoicesRes = await InvoicesListingAPI(payload)
        let invoiceListing = InvoicesRes?.data?.data?.data?.map((e) => {
            return { ...e, checked: false }
        })
        setInvoiceData({
            ...invoiceData,
            invoice_list: invoiceListing,
            simmer: false
        }
        )
    }

    const handlePayNow = () => {
        handlePaymentDrawerOpen()
        setInvoiceData({
            ...invoiceData, invoice_list: invoiceData?.invoice_list?.map(e => {
                return { ...e, checked: true }
            })
        })
    }
    const handlePaymentDrawerClose = () => {
        setDrawer({
            ...drawer, paymentDrawer: false
        });
    };

    const handlePaymentDrawerOpen = (x) => {
        setDrawer({
            ...drawer, paymentDrawer: true
        });
    };

    const handleAlreadyPaid = () => {
        paymentEnum()
        setDrawer({
            ...drawer, alreadyPaidDrawer: true
        })
    }

    const handleAlreadyPaidClose = () => {
        setDrawer({
            ...drawer, alreadyPaidDrawer: false
        })
    }

    const handleChecked = (e) => {
        if (selectedInvoice?.items?.map((v) => v?.id).includes(e?.id)) {
            setSelectedInvoice({
                ...selectedInvoice,
                id: e?.id,
                is_checked: true,
                items: selectedInvoice?.items?.filter((x) => x?.id !== e?.id)
            })
            setIsQuickPay(false)
        }
        else {
            setSelectedInvoice({
                ...selectedInvoice,
                items: [...selectedInvoice?.items, e]
            })
            setIsQuickPay(false)
        }
    }

    const handleDelete = (e, index) => {
        handleChecked(e)
    }

    const listingAPIs = () => {
        return new Promise(async () => {
            try {
                const OwnerRes = await ownerAccountsAPI()
                setAccounts(OwnerRes?.data?.data)
                setSelectedAccount(OwnerRes?.data?.data?.[0])
                await InvoicesListing({
                    agreement_id: agreement_id ?? undefined,
                    account_id: OwnerRes?.data?.data?.[0]?.id

                })

                await InvoicesOverdue({
                    agreement_id: agreement_id ?? undefined,
                    account_id: agreement_id ? undefined : OwnerRes?.data?.data?.[0]?.id
                })
            }
            catch (err) {
                console.log(err, 'err')
            }
        })
    }

    const validate = () => {
        let isValid = true;
        let error = paymentForm?.error
        if (paymentForm?.amount_paid?.length === 0) {
            isValid = false
            error.amount_paid = t("Paid amount is Required")
        }
        if (paymentForm?.type?.length === 0) {
            isValid = false
            error.type = t("Type is Required")
        }
        if (paymentForm?.date?.length === 0) {
            isValid = false
            error.date = "Date is Required"
        }
        if (paymentForm?.bank_location?.length === 0) {
            isValid = false
            error.bank_location = t("Bank name/Location is Required")
        }
        if (paymentForm?.transaction_id?.length === 0) {
            isValid = false
            error.transaction_id = t("Transaction Id is Required")
        }
        if (paymentForm?.notes?.length === 0) {
            isValid = false
            error.notes = t("Notes is Required")
        }

        setPaymentForm({ ...paymentForm, error })
        return isValid
    }


    const SaveReconcile = async () => {
        if (validate()) {
            const payload = {
                amount_paid: paymentForm?.amount_paid,
                bank: paymentForm?.bank_location,
                invoice_id: selectedInvoice?.id,
                notes: paymentForm?.notes,
                paid_on: paymentForm?.date,
                payment_mode: paymentForm?.type,
                trx_no: paymentForm?.transaction_id
            }
            const payed = await AlreadypaidCreation({ payload })

            if (payed?.isSuccess) {
                handleAlreadyPaidClose()
                setPaymentForm(initialData)
                listingAPIs()
                handlePaymentDrawerClose()
                navigate(AppRoutes?.payment, { state: true })

            }
            else {
                navigate(AppRoutes?.payment, { state: false })

            }

        } else {
            enqueueSnackbar(t(`Please Fill Mandatory Fields`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
            })
        }
    }


    // select invoice
    const selectedInvoices = (x) => {
        if (x) {
            setSelectedInvoice({ ...selectedInvoice, items: [x] })

        }
        setDrawer({
            ...drawer, paymentDrawer: true
        });
    }

    const onClickPDF = async (x) => {
        await setPdf({
            pdf: "",
            bool: true,
            open: true
        })
        const data = await InvoicePdf({
            invoice_id: x?.id
        })
        if (data?.error?.originalStatus === 200) {
            await setPdf({
                pdf: data?.error?.data ?? "",
                bool: false,
                open: true
            })
        }
        else {
            enqueueSnackbar(t(`Some Thing Went Wrong`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
            })
        }

    }

    const handlePdfDrawerClose = () => {
        setPdf({
            ...pdf,
            open: false
        })
    }
    const updateAccounts = async (e) => {
        setSelectedAccount(e)
        setDrawer(false)
        await InvoicesOverdue({
            account_id: e?.id
        })
        await InvoicesListing({
            account_id: e?.id
        })
        setSelectedInvoice({
            ...selectedInvoice, items: []
        })
    }


    React.useEffect(() => {
        listingAPIs()

        // eslint-disable-next-line
    }, [])
    const handleDrawerOpen = () => {
        setDrawer({
            ...drawer, accountsDrawer: true
        });
    };
    const handleDrawerClose = () => {

        setDrawer({
            ...drawer, accountsDrawer: false
        });
    };




    React.useEffect(() => {
        if (error?.status === 423 || isError?.status === 423 || isError1?.status === 423 || isError2?.status === 423 || isError3?.status === 423 || isError4?.status === 423) return errorRoute()
    }, [error, isError, isError1, isError2, isError3, isError4])

    return (
        <Box sx={{
            paddingTop: '3.5rem', // Default style
            '@media screen and (min-width: 600px)': {
                paddingTop: '4rem', // Media query style for `md` breakpoint
            },
        }}>
            <Container maxWidth={'sm'} sx={{ padding: "0px !important" }}>
                <Box sx={Invoices_style?.Topnav}>
                    <Container maxWidth={'sm'} sx={{ padding: "0px !important" }}>
                        <TopNavBar title={t("Invoices")}
                            isDirect isBack t={t} position={'absolute'} />
                    </Container>
                </Box>
                <Box sx={Invoices_style?.root}>
                    {
                        !agreement_id ?
                            <>
                                <TopDrawerTab
                                    t={t}
                                    data={accounts}
                                    selectedAccount={selectedAccount}
                                    handleDrawerClose={handleDrawerClose}
                                    handleDrawerOpen={handleDrawerOpen}
                                    drawer={drawer?.accountsDrawer}
                                    component={<>
                                        <Box sx={TopDrawerTab_style?.drawerSx}>
                                            <Typography sx={TopDrawerTab_style?.title} noWrap>
                                                {`Accounts (${accounts?.length})`}</Typography>
                                        </Box>

                                        <Box >
                                            <AgreementCard
                                                data={accounts}
                                                selectedAccount={selectedAccount}
                                                checked={accounts?.checked}
                                                updateAccounts={updateAccounts}
                                                t={t}
                                            />
                                        </Box>

                                    </>}
                                />
                                <Box sx={{ height: '16px' }} />
                            </>

                          
                    : ""
                    }


                    {/* invoicecard */}
                    {
                        overdue?.simmer ? <InvoiceSimmmer /> :
                            overdue?.listingDatas?.data?.length > 0 ?
                                <InvoiceCard
                                    selectedInvoices={selectedInvoices}
                                    title={`${overdue?.listingDatas?.data?.length ? overdue?.listingDatas?.data?.length : 0} 
                            ${t("Unpaid Invoice")}`}
                                    amt={`${overdue?.listingDatas?.data?.find((x) => x?.currency_code)?.currency_code} ${currencyFormatted(overdue?.listingDatas?.totalOutStandingAmount)}`}
                                    amttype={t("Outstanding amount")}
                                    overdueamount=
                                    {`${currencyFormatted(overdue?.listingDatas?.totalOverDueAmount)} 
                                ${t("is overdue amount")}`}
                                    paytype={t("Quick pay")}
                                    btnText={t("Pay now")}
                                    handlePayNow={handlePayNow}
                                    invoicecomponent={""}
                                    data={overdue?.listingDatas?.data}
                                    t={t}
                                /> : ""
                    }
                    {
                        overdue?.listingDatas?.data?.length > 0 ? <Box sx={{ paddingTop: '16px' }} /> : ""
                    }

                    {
                        invoiceData?.invoice_list?.length > 0 ?
                            <Box sx={selectedInvoice?.items?.length > 0 ? Invoices_style?.card2 : Invoices_style?.card1}>
                                {
                                    invoiceData?.invoice_list?.length > 0 &&
                                    <Typography sx={Invoices_style.title}>
                                        {`${t("Invoices")} (${invoiceData?.invoice_list?.length})`}
                                    </Typography>

                                }


                                <Box >
                                    {invoiceData?.simmer ?
                                        <InvoiceSimmerCard /> :
                                        invoiceData?.invoice_list?.length > 0 &&
                                        invoiceData?.invoice_list?.map((x, index) => {
                                            return (
                                                <InvoiceComplaintCard
                                                    t={t}
                                                    deleteDrawer={false}
                                                    isStatus={true}
                                                    InvoicecardStyle={{ borderBottom: invoiceData?.invoice_list?.length === index + 1 ? "none" : "1px solid #E4E8EE !important" }}
                                                    data={x}
                                                    handleChecked={() => handleChecked(x, index)}
                                                    is_checked=
                                                    {selectedInvoice?.items?.map((v) => v?.id).includes(x?.id)}
                                                />
                                            )

                                        })}
                                </Box>

                            </Box>
                            :
                            <NoData style={{
                                display: "flex", alignItems: "center",
                                height: "calc(100vh - 220px)", backgroundColor: "#F6F6F6"
                            }} />
                    }

                </Box>
                {selectedInvoice?.items?.length > 0 &&
                    <Box sx={Invoices_style?.card}>
                        <Container maxWidth="sm">
                            <Box sx={Invoices_style?.cardBack}>
                                <BottomInvoice
                                    price={currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_due_amount).reduce((a, b) => a + b, 0))}
                                    total={selectedInvoice?.items?.length}
                                    btnText={t('Proceed Payment')}
                                    handlePaymentDrawerOpen={handlePaymentDrawerOpen}
                                    handlePaymentDrawerClose={handlePaymentDrawerClose}
                                    drawer={drawer?.paymentDrawer}
                                    selectedInvoice={selectedInvoice?.items}
                                    handleDelete={handleDelete}
                                    handleChecked={handleChecked}
                                    handleAlreadyPaid={handleAlreadyPaid}
                                    currency={selectedInvoice?.items?.find((x) => x?.symbol)?.symbol}
                                    t={t} />
                            </Box>
                        </Container>
                    </Box>}
                <DialogDrawer
                    maxWidth="sm"
                    open={drawer?.alreadyPaidDrawer}
                    onClick={handleAlreadyPaidClose}
                    header={t("Already Paid ?")}
                    height_style={
                        { height: "429px !important" }
                    }
                    header_style={{
                        padding: "8px !important"
                    }}
                    footer_style={{
                        padding: "8px !important"
                    }}
                    component={<AlreadyPaidDrawer
                        t={t}
                        updateState={updateState}
                        paymentMode={paymentMode}
                        data={paymentForm} />}
                    footer={
                        <>
                            {/* footer Component */}
                            < FooterComponent
                                single
                                containedText={t("Reconcile")}
                                containedonClick={SaveReconcile}
                            />
                        </>
                    }

                    onClose={() => handleAlreadyPaidClose()}
                />

                <DialogDrawer
                    maxWidth="sm"
                    open={drawer?.paymentDrawer}
                    onClick={handlePaymentDrawerClose}
                    header={t("Payment")}
                    height_style={
                        { height: "auto !important" }
                    }
                    header_style={{
                        padding: "8px !important"
                    }}
                    footer_style={{
                        padding: "8px !important"
                    }}
                    component={
                        <>
                            <PaymentModal
                                symbol={selectedInvoice?.items?.find((x) => x?.symbol)?.symbol}
                                t={t}
                                price={
                                    currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_due_amount).reduce((a, b) => a + b, 0))}
                                invoice_total_tax_amount={
                                    currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_total_tax_amount).reduce((a, b) => a + b, 0))}
                                selectedInvoice={selectedInvoice?.items}
                                handleDelete={handleDelete}
                                isQuickPay={isQuickPay}
                                onClickPDF={onClickPDF}
                                handleChecked={handleChecked}
                            />
                        </>
                    }
                    footer={
                        <>
                            {/* footer Component */}
                            < FooterComponent
                                outlinedDisabled={selectedInvoice?.items?.length !== 1 ?? true}
                                outlinedText={t("Already Paid?")}
                                containedText={t("Pay now")}
                                containedonClick={() => null}
                                outlinedonClick={() => {
                                    handleAlreadyPaid()
                                }}
                            />

                        </>

                    }

                    onClose={() => handlePaymentDrawerClose()}
                />

                <DialogDrawer
                    maxWidth="sm"
                    open={pdf?.open}
                    onClick={handlePdfDrawerClose}
                    header={t("Invoice")}
                    height_style={
                        { height: "500px !important" }
                    }
                    header_style={{
                        padding: "8px !important"
                    }}
                    footer_style={{
                        padding: "8px !important"
                    }}
                    component={
                        <>
                            {
                                pdf?.bool ?
                                    <>
                                        <LoderSimmer card count={1} size={500} variant={"rectangular"} animation={"wave"} />
                                    </>
                                    :
                                    !!pdf?.pdf ?
                                        <DocumentViewer url={`data:application/pdf;base64,${pdf?.pdf}`} /> : <NoData />
                            }
                        </>
                    }
                    onClose={() => handlePdfDrawerClose()}
                />
            </Container >
        </Box>

    )
}