import { Box, Container } from "@mui/material"
import { CorrespondenceCard, DialogDrawer, SearchBarFilter, TopNavBar } from "../../components"
import { CaseManagement_style } from "./style"
import { useNavigate } from 'react-router-dom';
import { useLazyCasemanageQuery, useLazyFilterEnumQuery, useLazyTypeMasterQuery } from "../../redux/services"
import React, { useState, useLayoutEffect } from "react";
import moment from "moment-timezone"
import { AppRoutes } from "../../router/routes"
import Filter from "../../components/filter";
import { CaseManagementSimmer } from "./simmerComponent/caseSimmer";
import NoData from "../../components/noData";
import { errorRoute } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../useDimension";
export const CaseManagement = ({ t = () => false }) => {
    const navigate = useNavigate();
    const [casemanage , {error}] = useLazyCasemanageQuery()
    const [filterType , {error:isError}] = useLazyTypeMasterQuery()
    const [filterPrirority  , {error:isError1}] = useLazyFilterEnumQuery()
    const [offSet, setOffSet] = useState(0);

    const [correspond, setCorrespond] = useState({
        listingDatas: [],
        listingDocument: [],
        viewData: [],
        bool: true
    })
    const [enumValue, setEnumValue] = React.useState({
        priority: [],
        type: []
    })
    const [filterData, setFilterData] = React.useState({
        priority: [],
        type: []

    })
    const [drawer, setDrawer] = React.useState({
        addReq: false,
        filterReq: false
    })

    const FilterFunction = async (data, payload) => {
        let priority = await filterPrirority(data)
        let type = await filterType()
        let CasemanageDatas = await casemanage({
            filters: {
                priority: priority?.data?.data?.urgent_type?.map((x) => x?.value),
                type: type?.data?.data?.data?.map((x) => x?.id)
            },
            offset: 0, limit: 10, status: 2
        })


        setCorrespond({
            ...correspond,
            listingDatas: CasemanageDatas?.data?.data?.list,
            bool: false
        })
        setEnumValue({
            ...enumValue,
            priority: priority?.data?.data?.urgent_type,
            type: type?.data?.data?.data?.map((x) => {
                return {
                    value: x?.id,
                    label: x?.name
                }
            })
        })
        setFilterData({
            ...filterData,
            priority: priority?.data?.data?.urgent_type?.map((x) => x?.value),
            type: type?.data?.data?.data?.map((x) => x?.id)
        })
    }
    const filterDataJson = [
        {
            id: "1",
            title: t("Prirority"),
            key: "priority",
            filterType: "CHECKBOX",
            values: enumValue?.priority,
        },
        {
            id: "2",
            title: t("Type"),
            key: "type",
            filterType: "CHECKBOX",
            values: enumValue?.type,
        },

    ];
    const applyFilter = async (data) => {
        setDrawer({ ...drawer, filterReq: false });
        const chcek = await casemanage({
            filter: {
                priority: data?.priority,
                type: data?.type
            },
            offset: 0, limit: 20, status: 1
        })
        setCorrespond({
            ...correspond,
            listingDatas: chcek?.data?.data?.list,
            bool: false
        })
        setFilterData(data)

    }
    const filterdraweropen = () => {
        setDrawer({
            ...drawer, filterReq: true
        });
    };
    const filterdrawerclose = () => {
        setDrawer({
            ...drawer, filterReq: false
        });
    };
    React.useEffect(() => {
        FilterFunction({ enumName: ["urgent_type"] })
        // eslint-disable-next-line 
    }, [])
    useLayoutEffect(() => {
        FilterFunction({ enumName: ["urgent_type"] })
        // eslint-disable-next-line 
    }, [])
    const handleRoute = (val) => {
        setCorrespond({
            ...correspond,
            viewData: val
        })
        navigate(AppRoutes.correspondence, { state: val })
    }
    const fetchMoreData =async () => {
        const chcek = await casemanage({
            filter: {
                priority: filterData?.priority,
                type: filterData?.type
            },
            offset: 0, limit: 20, status: 1
        })
        setCorrespond({
            ...correspond,
            listingDatas: chcek?.data?.data?.list,
            bool: false
        })
        setOffSet(offSet + 10);
      };

      const size = useWindowDimensions();

    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423|| isError1?.status === 423) return errorRoute() 
     },[error , isError , isError1])
    return (
        <Container sx={CaseManagement_style?.container} maxWidth="sm">
       <Box sx={{
        paddingTop: '51px', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '59px', // Media query style for `md` breakpoint
        },
      }}>
       <Box sx={CaseManagement_style?.Topnav}>
               <Container maxWidth="sm" sx={{padding:"0px !important"}}>
               <TopNavBar title={t("Letter Management")} isDirect isBack t={t} />
               </Container>
            </Box>

            <Box sx={CaseManagement_style?.sx}>
                <Box sx={CaseManagement_style?.topbar}>
                    {/* <Typography sx={CaseManagement_style?.title}>
                        {`${t("Case Management")} (${correspond?.listingDatas?.length})`}</Typography>
                    <FilterIcon onClick={filterdraweropen} /> */}

                    <SearchBarFilter text= {`${t("Letter Management")} (${correspond?.listingDatas?.length})`} handleFilterOpen={filterdraweropen} filterIcon/>

                </Box>
            

{correspond?.bool ?
          <Box>
            <CaseManagementSimmer />
          </Box>
          :
            correspond?.listingDatas?.length > 0 ?
              <InfiniteScroll
                dataLength={correspond?.listingDatas?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 150}

              >
                {correspond?.listingDatas?.map((val, index) => {
                  return (
                    <Box key={index} >
                                    <CorrespondenceCard
                                        color={'notebg.primary'}
                                        content={val?.subject}
                                        type={val.type}
                                        status={val?.priority}
                                        postedDate={moment(val?.incident_date).format(
                                            "DD-MMM-YY"
                                        )}
                                        deadline={moment(val?.dead_line_date).format(
                                            "DD-MMM-YY"
                                        )}
                                        t={t}
                                        bgColor={{ backgroundColor: val?.priority === "low"?"#06D6A0":val?.priority==="high"?"#EFB93C":val?.priority==="medium"?"#FFD166":'notebg.primary' }}
                                        replied={val?.is_replied}
                                        handleRoute={() => handleRoute(val)}
                                    />
                                </Box>
                  );

                })
                }
              </InfiniteScroll>
              : <NoData
                style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
            
        }
            </Box>
       </Box>
            {/* filter Drawer */}
            <DialogDrawer
                maxWidth="sm"
                open={drawer?.filterReq}
                header={t("Filter By")}
                padding="0px"
                component={
                    <Box>
                        <Filter
                            t={t}
                            filters={{
                                priority: filterData?.priority,
                                type: filterData?.type
                            }}
                            filterDataJson={filterDataJson}
                            onGoBack={filterdrawerclose}
                            selectedList={filterDataJson[0]}
                            onApply={applyFilter}
                            onClose={filterdrawerclose}
                        />

                    </Box>
                }
                onClose={() => filterdrawerclose()}
            />
        </Container>
    )
}

