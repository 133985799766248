import { Bold, Regular } from "../../utils";



export const InspectionOrdersStyle = {
  root: {
    padding: "16px 0px",
  },
  main: {
    padding: "16px 16px",
    backgroundColor: "background.main",
    position: "relative",
    overflow: "hidden"
  },
  username: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "text.main",
  },
  msg: {
    fontSize: "0.875rem",
    fontFamily: Regular,
    marginTop: "5px",
    color: "text.main",
  },
  favTitle: {
    textAlign: "left",
    fontFamily: Bold,
    fontSize: "0.875rem",
    color: "border.primary",
  },
  favParent: {
    display: "flex",
    alignItems: "center",
    overflowY: "none",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  conParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between'
  },
  contracts: {
    height: "156px",
    backgroundColor: "error.contrastText",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "12px",
    opacity: 1,
    padding: '16px'
  },
  contractTitle: {
    textAlign: "left",
    fontFamily: Bold,
    fontSize: "0.875rem",
    color: "border.primary",
    textTransform: "capitalize",
  },
  AddIcon: {
    position: "fixed",
    bottom: "80px",
    cursor: "pointer",
    zIndex: "300",
    right: 0,
    width: "100%"
  },
  AddIconChild: {
    cursor: "pointer",
    zIndex: "300",
    backgroundColor: "primary.main",
    float: "right",
    color: "primary.contrastText",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },
    width: "50px",
    height: "50px"
  },
  reqCard: {
    overflow: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  infiniteScroll: {
    padding: "16px 0px 0px 0px",
    backgroundColor: "none !important",
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  filterIcon: {
    cursor: "pointer"
  },
};

export const ReqTypeCardStyle = {
  main: {
    borderRadius: "12px",
    margin: "4px 0",
    backgroundColor: "primary.contrastText",
    padding: {
      sm: "15px 15px",
      xs: "11px 11px"
    }
  },
  svgItem1: {
    backgroundColor: "background.weight",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "38px",
    height: "38px"
  },
  svgItem: {
    backgroundColor: "background.primary",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "38px",
    height: "38px"
  },
  svgDetails: {
    marginLeft: "7px",
    "& p:nth-of-type(1) ": {
      color: "text.main",
      fontSize: "1rem",
      fontFamily: Bold
    },
    "& p:nth-of-type(2) ": {
      color: "text.Teritary",
      fontSize: {
        sm: "0.75rem",
        xs: "0.75rem"
      },
      fontFamily: Regular,
      whiteSpace: "nowrap"
    }
  },
  subtext: {
    color: "text.Teritary",
    fontSize: "0.75rem !important",
    fontFamily: Regular
  }
}

export const CardStyle =   {
  main: {
    borderRadius: "12px",
    backgroundColor: "primary.contrastText",
    padding: {
      sm: "14px ",
      xs: "11px"
    },
    cursor: "pointer",
    margin: "8px 0"
  },
  details: {
    marginInlineStart: {
      sm: "12px",
      xs: "7px"
    },
  },
  repair: {
    color: "text.main",
    fontSize: {
      xs: "0.875rem",
      sm: "0.875rem"
    },
    fontFamily: Bold,
    whiteSpace: {
      xs: "nowrap"
    },

  },
  imgDiv: {
    marginBottom: "24px",
    width: {
      sm: "30px",
      xs: "30px"
    },
    height: {
      sm: "50px",
      xs: "40px"
    },
    "& div": {
      width: "100%",
      height: "100%"
    }
  },
  dotPoints: {
    margin: {
      sm: "7px 0",
      xs: "4px 0"
    },
    "& p": {
      color: "text.Teritary",
      fontSize: "0.75rem",
      whiteSpace: "nowrap",
      fontFamily: Regular,
    },
    "& div": {
      width: '6px',
      height: "6px",
      backgroundColor: "border.Teritary",
      margin: "0 6px",
      borderRadius: "50%"
    }
  },
  status: {
    paddingTop: "2px",
    borderRadius: "0px 0px 5px 5px",
    textAlign: "center",
    color: "primary.contrastText",
    fontSize: "0.75rem",
    fontFamily: Bold,
    // width: "fit-content",
    width: "100%"
  },
  topStack: {
    borderBottom: "1px solid #5C86CB2E",
    padding: "0px 0 6px 0",
  },
  bottomStack: {
    padding: "10px 0 2px 0"
  },
  rightBorder: {
    borderInlineEnd: "1px solid #5C86CB2E"
  },
  childStack: {
    "& p": {
      color: "text.Teritary",
      fontSize: "0.75rem",
      marginInlineStart: "8px",
      fontFamily: Regular
    }
  },
  arrowIcon: {
    width: "32px !important",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: "background.secondary",
    border: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "0.875rem"
    }
  },
  arrowIconGrid: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center"
  },
  avat: {
    borderRadius: "4px",
    // width:"80px",
    // height:"80px"
  }

}


