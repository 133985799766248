import {
  BuildingIcon,
  Order,
  ManChat,
} from "../assets";
import ContactUsIcon from "../assets/contactUs";
import { AppRoutes } from "../router/routes";

export const dashboardCards = (t, countDetails) => [
  {
    title: t("Total"),
    description: t("Units"),
    count: countDetails?.total_units,
    image: <BuildingIcon fill={"#ebeffc"} fill1={"#6c68ea"} />,
    onClick: "/properties",
    type: "total"
  },
  {
    title: t("Service"),
    description: t("Requests"),
    count: parseInt(countDetails?.total_maintenance_request) + parseInt(countDetails?.total_general_request),
    image: "/images/seticon.svg",
    is_image: true,
    onClick: "/request",
    type: "request"

  },
  {
    title: t("Service"),
    description: t("Reports"),
    count: countDetails?.total_inspections_count,
    image: <Order />,
    onClick: "/inspection",
    type: "inspection"
  },
  // {
  //   title: t("Purchased"),
  //   description: t("Units"),
  //   count: countDetails?.total_purchased_units ?? 0,
  //   image: <WorkerImage />,
  //   onClick: "/properties",
  //   type: "purchased"
  // },
];
export const Contract = (t) => [
  {
    image: "/images/contract.svg",
    title: t("Contracts"),
    is_image: true,
    onClick: "/contracts"
  },
  {
    image: "/images/invoice.svg",
    title: t("Invoices"),
    is_image: true,
    onClick: "/invoices"
  }
];
export const Action = [
  {
    amount: "SAR 12,850.00",
    purpose: "Plumbing",
    purposecode: "MNT-2022-02-01",
    image: <ManChat />,
    duetext: "Over Due 02 Days",
    type: "General",
  },
  {
    amount: "SAR 12,850.00",
    purpose: "Rent",
    purposecode: "INV/DNT/2022-001",
    image: <ManChat />,
    duetext: "Opened on 19 Jan",
    type: "Invoice",
  },
  {
    amount: "SAR 12,850.00",
    purpose: "Plumbing",
    purposecode: "MNT-2022-02-01",
    image: <ManChat />,
    duetext: "Over Due 02 Days",
    type: "General",
  },
];
export const quickAccessCards = (t) => {
  return [
    {
      title: t("Vehicles"),
      key_name:"Vehicles",
      image: "/images/vehicleRevamp.png",
      is_image: true,
      router: AppRoutes?.vehicle,

    },
    {
      image: "/images/spendingsRevamp.png",
      title: t("Spendings"),
      key_name:"Spendings",
      is_image: true,
      router: AppRoutes?.spending

    },
    {
      image: "/images/investmentRevamp.png",
      title: t("Investments"),
      key_name:"Investments",
      is_image: true,
      router: AppRoutes.investment
    },
    {
      image: "/images/contactRevamp.png",
      title: t("Contacts"),
      key_name:"Contacts",
      is_image: true,
      router: AppRoutes.contacts

    },
    {
      image: "/images/kycRevamp.png",
      title: t("KYC"),
      key_name:"KYC",
      is_image: true,
      router: AppRoutes.kycInformation,
    },
  ];
};

export const otherFeatures = (t , fun) => {
  return [
  {
    icon: "/images/filemanager.png",
    title: t("File Manager"),
    key_name:"File Manager",
    router: AppRoutes.filemanager
  },
  {
    icon: "/images/chat.png",
    title: t("Survey"),
    key_name:"Survey",
    router: AppRoutes.surveyList
  },
  {
    icon: "/images/cctv.png",
    title: t("My CCTV"),
    key_name:"My CCTV",
    is_not:true,
    router:()=>fun()
  },
  {
    icon: "/images/panic.png",
    title: t("Panic"),
    key_name:"Panic",
    is_not:true,
    router:()=>fun()
  },
  {
    icon: "/images/chat.png",
    title: t("Chat"),
    key_name:"Chat",
    is_not:true,
    router:()=>fun()
  }]
}

export const landLord = [
  {
    icon: <ContactUsIcon />,
    unitName: "Park View",
    address: "Chennai, Tamilnadu",
    id: 1
  },
  {
    icon: <ContactUsIcon />,
    unitName: "Lumpinin",
    address: "Chennai, Tamilnadu",
    id: 2
  },
  {
    icon: <ContactUsIcon />,
    unitName: "Casa Grande",
    address: "Chennai, Tamilnadu",
    id: 3
  },
]
