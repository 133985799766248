import { IconButton, Typography } from "@mui/material";
import { Box, Container, Stack, Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { DatePickerNew, FooterComponent, SelectBox, TextBox, ToggleButton, TopNavBar } from "../../components";
import { errorRoute, generateImageUrl, img_size, Regular } from "../../utils";
import { VehicleTypeList } from "./data";
import { AddVehicleStyle } from "./style"
import { AppRoutes } from "../../router/routes";
import { useDispatch } from "react-redux";
import {
    useLazyGetParkingAreaQuery,
    useLazyGetRequestStatusEnumQuery,
    useLazyGetUnitEnumQuery, useLazyImageMutationQuery, useLazyParkingCreationQuery
} from "../../redux/services";
import { useRef } from "react";
import { openBackdrop, closeBackdrop } from "../../redux/slices/backdrop"
import { UploadImg } from "../../assets/uploadImg";
import { useSnackbar } from "notistack";

export const AddVehicle = ({ t = () => false }) => {
    const { enqueueSnackbar ,closeSnackbar} = useSnackbar()
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)
    const [GetUnitAPI , {error}] = useLazyGetUnitEnumQuery()
    const [ParkingCreation, { isLoading , error:isError}] = useLazyParkingCreationQuery()
    const [parkingEnumAPI, {error:isError1}] = useLazyGetRequestStatusEnumQuery()
    const [ParkingArea, {error:isError2}] = useLazyGetParkingAreaQuery()
    const [imageMutation , {error:isError3}] = useLazyImageMutationQuery()

    const options = [
        {
            "label": "Yes",
            "value": true
        },
        {
            "label": "No",
            "value": false
        }
    ]
    const location = useLocation()
    const navigate = useNavigate()
    const inputFile = useRef(null);
    const dispatch = useDispatch();
    let data = location?.state
    const [state, setState] = React.useState({
        id: "",
        name: "",
        description: "",
        number_plate: "",
        vehicle_image: "",
        vehicle_name: "",
        reference_id: "",
        vehicle_type: "",
        unit: [],
        parkingSpaces: [],
        parkingSlot: "",
        isInsured: false,
        insurance_notes: "",
        insurance_name: "",
        date: new Date(),
        error: {
            name: "",
            description: "",
            number_plate: "",
            reference_id: "",
            vehicle_type: "",
            parkingSpaces: "",
            unit: "",
        }

    })
    const [parkingSpaces , setParkingSpaces]=React.useState([])

    React.useEffect(() => {
        if (data?.isEdit) {
            setState({
                id: data?.Editdata?.id,
                name: data?.Editdata?.name,
                description: "",
                number_plate: data?.Editdata?.number_plate ?? "",
                vehicle_image: data?.Editdata?.vehicle_image ?? "",
                vehicle_name: data?.Editdata?.reference_id ?? "",
                reference_id: data?.Editdata?.reference_id ?? "",
                vehicle_type: data?.Editdata?.vehicle_type ? VehicleTypeList(t)?.find((x)=>x?.value === data?.Editdata?.vehicle_type)?.type : "",
                unit: {
                    value: data?.Editdata?.unit_id,
                    label: data?.Editdata?.unit_name,
                    unit_id: data?.Editdata?.unit_no,
                    property_id:data?.Editdata?.property_id
                },
                parkingSpaces: [],
                parkingSlot: data?.Editdata?.parking_area_id,
                isInsured: data?.Editdata?.is_insured ?? false,
                insurance_notes: data?.Editdata?.insurance_notes ?? "",
                insurance_name: data?.Editdata?.insurance_name ?? "",
                date: data?.Editdata?.valid_till ? new Date(data?.Editdata?.valid_till) : new Date(),
                error: {
                    name: "",
                    description: "",
                    number_plate: "",
                    reference_id: "",
                    vehicle_type: "",
                    parkingSlot: "",
                    unit: "",
                }
            })

             ParkingAreaAPI({
                property_id: data?.Editdata?.property_id,
            }, data?.Editdata?.vehicle_type, "")
        }
        // eslint-disable-next-line
    }, [data?.unit_id])


    const handleClose = () => {
        navigate(-1)
    }

    const validate = () => {
        let isValid = true;
        let error = state?.error
        if (state?.unit?.length === 0) {
            isValid = false
            error.unit = t("Unit is Required")
        }
        if (state?.vehicle_type?.length === 0) {
            isValid = false
            error.vehicle_type = t("Vehicle Type is Required")
        }
        if (state?.number_plate?.length === 0) {
            isValid = false
            error.number_plate = t("Vehicle Number is Required")
        }
        if (state?.parkingSlot?.length === 0) {
            isValid = false
            error.parkingSlot = t("Slot is Required")
        }
        if (state?.vehicle_image?.length === 0) {
            isValid = false
            error.vehicle_image = t("Vehicle Image is Required")
        }
        if(state?.isInsured){
            if (state?.insurance_name?.length === 0) {
                isValid = false
                error.insurance_name = t("Insurance Name is Required")
            }
            if (state?.date?.length === 0) {
                isValid = false
                error.date = t("Valid Till is Required")
            }
            if (state?.insurance_notes?.length === 0) {
                isValid = false
                error.insurance_notes = t("Insurance Notes is Required")
            }
        }
        
        setState({ ...state, error })
        return isValid
    }
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleUpdate = async () => {
        if (validate()) {
            setIsDisableBtn(true)
            const payload = {
                owner_unit_id: state?.unit?.value?.length > 0 ? state?.unit?.value : null,
                vehicle_type: state?.vehicle_type?.length > 0 ? VehicleTypeList(t)?.find((x)=>x?.type === state?.vehicle_type)?.value : null,
                number_plate: state?.number_plate?.length > 0 ? state?.number_plate : null,
                is_active: true,
                parking_area_id: state?.parkingSlot?.length > 0 ? state?.parkingSlot : null,
                vehicle_image: state?.vehicle_image?.length > 0 ? state?.vehicle_image : null,
                parking_type: "Reserved",
                is_insured: state?.isInsured,
                insurance_name: state?.insurance_name?.length > 0 ? state?.insurance_name : null,
                insurance_notes: state?.insurance_notes?.length > 0 ? state?.insurance_notes : null,
                valid_till: state?.date,
                property_id: state?.unit?.property_id ? state?.unit?.property_id : null,
                build_source:"Owner"
            }

            if (data?.isEdit) {
                payload["id"] = data?.Editdata?.id
            }
            await ParkingCreation(payload).then((data)=>{
                setIsDisableBtn(false)
                if (data?.isEdit) {
                    enqueueSnackbar(t("Vehicle updated successfully"), {
                        variant: "success",
                        anchorOrigin: { horizontal: "center", vertical: "top" }
                    });
                } else {
                    enqueueSnackbar(t("Vehicle created successfully"), {
                        variant: "success",
                        anchorOrigin: { horizontal: "center", vertical: "top" }
                    });
                }
                 setTimeout(()=>{
                    closeSnackbar()
                },2000)
                navigate(AppRoutes?.vehicle, { state: state })

            }).catch((err)=>{
                setIsDisableBtn(false)
                    enqueueSnackbar(t("Some Thing Went Wrong"), {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" }
                    });
                    setTimeout(()=>{
                        closeSnackbar()
                    },2000)
            })
     
        } else { 
            enqueueSnackbar(t("Please Fill All Mandatory Fields"), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }
            });
            setTimeout(()=>{
                closeSnackbar()
            },2000)
        }
    }

    const updateState = async (key, value) => {
        let error = state?.error;
        error[key] = "";
        if (key === "vehicle_type") {
            if (state?.unit?.value && value) {
                setState({
                    ...state, [key]: value, error
                })
                await ParkingAreaAPI({
                    property_id: state?.unit?.property_id,
                    parking_area_type:value
                }, key, value)
            } else {
                setState({
                    ...state, [key]: value, error
                })
            }
        } else if (key === "parkingSlot") {
            setState({
                ...state, [key]: value?.value, error
            })
        } else {
            setState({
                ...state, [key]: value, error
            })
        }
    }

    const removeImage = () => {
        setState({ ...state, vehicle_image: "", vehicle_name: "" })

    };
    const ParkingAreaAPI = async (data, key, value) => {
        const ParkingRes = await ParkingArea(data)
        setParkingSpaces(ParkingRes?.data?.data)
    }
    const loadOptions = async (search = "", array, type) => {
        let result,
            offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }
        if (type === "get-owner-unit") {
            result = await GetUnitAPI({
                offset,
                limit: 10,
                search
            })
            return {
                options: [...result?.data?.data?.map((x) => {
                    return {
                        value: x?.unit_id,
                        label: x?.unit_name,
                        unit_id: x?.unit_no,
                        property_id: x?.property_id
                    }
                })],
                hasMore:
                    array?.length + result?.data?.data?.length <
                    result?.count,
            };

        }
    }

    const parkingEnum = async (data) => {
        const unitEnum = await parkingEnumAPI(data)
        const res = unitEnum?.data?.data?.parking_area_type
        res?.filter((x) =>
            VehicleTypeList(t)?.includes(x?.value) && x)

    }

    React.useEffect(() => {
        parkingEnum(["parking_area_type"])

        // eslint-disable-next-line
    }, [])

    const updateImage = async (e) => {
        let error = state?.error;
        error["vehicle_image"] = "";
        dispatch(openBackdrop("...Loading"))
        if (e) {
            const formData = new FormData();
            formData.append("files", e);
            formData.append("companyId", 1);
            const image = await imageMutation(formData)
            setState({
                ...state,
                vehicle_image: image?.data,
                vehicle_name: e?.name
            })
            setState({
                ...state,
                vehicle_image: image?.data,
                vehicle_name: e?.name,
                error
            })
            dispatch(closeBackdrop(""))
        }
    };

    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423 || isError1?.status === 423 || isError2?.status === 423 || isError3?.status === 423) return errorRoute() 
     },[error , isError , isError1 , isError2 , isError3 ])


    return (
        <Container
            maxWidth="sm"
            sx={AddVehicleStyle?.containerSm}>
                 <Box sx={{position:"fixed" , top:0 , left:0 , right:0, zIndex:1}}>
           <Container maxWidth="sm" sx={{padding:"0px !important"}}>
            <TopNavBar isDirect
                title={data?.isEdit ? t("Update Vehicle") : t("Add Vehicle")} isBack />
            </Container>
        </Box>
            <Box sx={AddVehicleStyle?.root}>
                <Box sx={AddVehicleStyle?.form}>
                    <SelectBox
                        isActive={true}
                        required={true}
                        label={t("Unit Reference")}
                        placeholder={t("Select unit")}
                        value={state?.unit}
                        onChange={(value) => {
                            updateState("unit", value)
                            if(value && state?.vehicle_type){
                                ParkingAreaAPI({
                                    property_id: value?.property_id,
                                    parking_area_type:state?.vehicle_type,
                                }, state?.vehicle_type, value)
                            }
                        }}
                        isPaginate={true}
                        isRequired
                        loadOptions={(search, array) =>
                            loadOptions(search, array, "get-owner-unit")}
                    />
                    {state?.error?.unit?.length > 0 && (
                        <Typography
                            variant={"caption"}
                            color={"error"}
                            fontFamily={Regular}>
                            {state?.error?.unit}
                        </Typography>
                    )}
                    <Box sx={AddVehicleStyle?.input}>
                        <Stack direction="row">
                            <Typography noWrap sx={AddVehicleStyle?.Label1}>{t("Select Vehicle")}</Typography>
                            <Typography variant="caption" sx={AddVehicleStyle?.required}>*</Typography>
                        </Stack>
                        <Stack direction="row">
                            {VehicleTypeList(t)?.map((item) => {
                                return (<Grid
                                    container direction={"column"}
                                    sx={AddVehicleStyle?.selectVehicle}
                                    style={{
                                        border: state?.vehicle_type === item.type ?
                                            "1px solid #F15A29" : undefined
                                    }}
                                    onClick={() => updateState("vehicle_type", item.type)}>
                                    {state?.vehicle_type === item.type ? item?.selected_icon :
                                        item?.icon}
                                    <Typography noWrap
                                        sx={AddVehicleStyle?.vehicleTypeText}
                                        style={{
                                            color: state?.vehicle_type === item.type ?
                                                "#F15A29" : "#98A0AC"
                                        }}>
                                        {item?.label}
                                    </Typography>
                                </Grid>)
                            })}
                        </Stack>
                        {state?.error?.vehicle_type?.length > 0 && (
                            <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                                {state?.error?.vehicle_type}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={AddVehicleStyle?.input}>
                        <TextBox
                            type="text"
                            value={state?.number_plate ?? ""}
                            onChange={(e) => updateState("number_plate", e.target.value)}
                            label={t("Vehicle Number ")}
                            placeholder={t("Enter Vehicle Number")}
                            isrequired={true}
                            sx={AddVehicleStyle.textbox}
                            isRequired
                        />
                        {state?.error?.number_plate?.length > 0 && (
                            <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                                {state?.error?.number_plate}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={AddVehicleStyle?.input}>
                        <Stack direction="row">
                            <Typography noWrap sx={AddVehicleStyle?.Label1}>
                                {t("Choose Parking Space")}</Typography>
                            <Typography variant="caption" sx={AddVehicleStyle?.required}>*</Typography>
                        </Stack>
                        {parkingSpaces?.length > 0 ?
                            <Grid container sx={AddVehicleStyle?.parkingSpaces}>
                                {parkingSpaces?.map((item) => {
                                    return (<Grid
                                        sx={AddVehicleStyle?.slot}
                                        style={{
                                            backgroundColor: state?.parkingSlot === item?.value ?
                                                "#F15A29" :
                                                item?.status === "Occupied" ? "#E4E8EE" : "#FFFFFF",
                                            border: state?.parkingSlot === item.value ? "1px solid #F15A29" : "1px solid #E4E8EE",
                                        }}
                                        onClick={() => {
                                            updateState("parkingSlot", item)
                                        }}>
                                        <Typography
                                            sx={AddVehicleStyle?.slotName}
                                            style={{
                                                color: (state?.parkingSlot === item?.value) ?
                                                    "#FFFFFF" : "#98A0AC",
                                            }}>
                                            {item.name}
                                        </Typography>
                                    </Grid>)
                                })}
                            </Grid> :
                            <div style={{ padding: "10px", justifyContent: "center" }}>
                                <Typography sx={AddVehicleStyle?.noParkingSpaces}>
                                    {t("No Parking Space Available")}
                                </Typography>
                            </div>

                        }
                        {state?.error?.parkingSlot?.length > 0 && (
                            <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                                {state?.error?.parkingSlot}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={AddVehicleStyle?.input}>
                        <Stack direction="row">
                            <Typography noWrap sx={AddVehicleStyle?.heading1}>{t("Upload Image")}</Typography>
                            <Typography variant="caption" sx={AddVehicleStyle?.required}>*</Typography>

                        </Stack>
                        {state?.vehicle_image && state?.vehicle_image ?
                            <Grid container sx={AddVehicleStyle?.imgDiv}>
                                <Grid item xs={1} sx={AddVehicleStyle?.height}>
                                    <img src={generateImageUrl(state?.vehicle_image, img_size.small_square)} alt="img"
                                        style={{
                                            objectFit: "cover", width: "100%", height: "100%"
                                        }} />
                                </Grid>

                                <Grid item xs={9} flexGrow={1} alignSelf={"center"}
                                    sx={AddVehicleStyle?.height}>
                                    <Typography noWrap sx={AddVehicleStyle?.imgText}>
                                        {state?.vehicle_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={removeImage}>
                                        <DeleteForeverTwoToneIcon htmlColor="red" />
                                    </IconButton>
                                </Grid>

                            </Grid> :
                            <Box onClick={onButtonClick} sx={AddVehicleStyle.drap2}>
                                <input type='file' accept="image/*" id='file'
                                    ref={inputFile} style={{ display: 'none' }}
                                    value={data?.img}
                                    onChange={(e) => { updateImage(e?.target?.files?.[0]) }} />
                                <Grid container sx={AddVehicleStyle?.imgGrid}>
                                    <IconButton>
                                        <Box sx={AddVehicleStyle?.upload}>
                                            <UploadImg />
                                        </Box>
                                    </IconButton>
                                    <Typography noWrap sx={AddVehicleStyle?.text2}>
                                        {t("Upload Images Here")}
                                    </Typography>
                                </Grid>
                            </Box>
                        }
                          {state?.error?.vehicle_image?.length > 0 && (
                            <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                                {state?.error?.vehicle_image}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={AddVehicleStyle?.input}>
                        <Stack direction="row">
                        <Typography noWrap sx={AddVehicleStyle?.Label2}>{t("Insured")}</Typography>
                            <Typography variant="caption" sx={AddVehicleStyle?.required}>*</Typography>
                        </Stack>
                        <ToggleButton
                            isActive={true}
                            value={state?.isInsured}
                            onChange={(e) => updateState("isInsured", e)}
                            error={data?.error?.type}
                            multiline={true}
                            options={options ?? []}
                            isrequired
                            backgroundColorunparent={'transparent'}
                            borderRadius={"6px"}
                        />
                    </Box>
                    {
                        state?.isInsured === true && <Box>
                            <Box sx={AddVehicleStyle?.input}>
                                <TextBox
                                    type="text"
                                    value={state?.insurance_name ?? ""}
                                    onChange={(e) => updateState("insurance_name", e.target.value)}
                                    label={t("Insurance Name")}
                                    placeholder={t("Enter Insurance Name")}
                                    isRequired
                                    sx={AddVehicleStyle.textbox}
                                />
                                  {state?.error?.insurance_name?.length > 0 && (
                            <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                                {state?.error?.insurance_name}
                            </Typography>
                        )}
                            </Box>
                            <Box sx={AddVehicleStyle?.input}>
                                <DatePickerNew
                                    isRequired={true}
                                    label={t("Valid Till")}
                                    placeholder={t("Date")}
                                    value={state?.date}
                                    onChange={(value) => updateState("date", value)}
                                    sx={{
                                        
                                                border: "1.5px solid #e2e2e2",
                                                borderRadius: "4px",
                                                backgroundColor:"#fff"
                                              
                                    }}
                                />
                                      {state?.error?.date?.length > 0 && (
                            <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                                {state?.error?.date}
                            </Typography>
                        )}
                            </Box>
                            <Box sx={AddVehicleStyle?.input}>
                                <TextBox
                                    type="text"
                                    value={state?.insurance_notes ?? ""}
                                    onChange={(e) => updateState("insurance_notes", e.target.value)}
                                    label={t("Insurance Notes")}
                                    placeholder={t("Enter Insurance Notes")}
                                    isRequired
                                    multiline
                                    sx={AddVehicleStyle.textbox}
                                />
                                            {state?.error?.insurance_notes?.length > 0 && (
                            <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                                {state?.error?.insurance_notes}
                            </Typography>
                        )}
                            </Box>
                        </Box>
                    }
                </Box>
            </Box >
            <Box sx={{ position: "fixed", bottom: "0", width: "100%" , left:0,right:0 }}>
                <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                    <FooterComponent
                        outlinedText={t("Cancel")}
                        containedText={data?.isEdit ? t("Update") : t("Save")}
                        outlinedonClick={() => handleClose()}
                        containedonClick={() => handleUpdate()}
                        btnLoading={isLoading}
                        isDisableBtn={isDisableBtn}
                    />
                </Container>
            </Box>

        </Container >
    )
}