import ArrowRight from "../../assets/arrowRight";
import BlackArrowLeft from "../../assets/blackArrowLeft";
import { RaisedOnStyles } from "./style"
import { Box, Grid, Stack, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';



export const RaisedOnCard = ({
    icon = "",
    title = "",
    subTitle = "",
    exampleTitle = "",
    router = () => false,
    t = () => false,
    chooseAppliance = () => false,
    QrCodeScanner = () => false
}) => {
    const language = localStorage.getItem("i18nextLng")
    return (
        <>
            <Typography sx={RaisedOnStyles.heading}>{t("Maintenance Request")}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={6} mt={1}>
                    <Box sx={RaisedOnStyles.scanAssetBox} style={{ backgroundPosition: language === "ar" ? "10%" : "90%" }} position="relative" onClick={() => QrCodeScanner()}>
                        <Typography sx={RaisedOnStyles.assettext}>{("Scan Asset / Item")}</Typography>
                        <Typography sx={RaisedOnStyles.assettext}>{("QR Code")}</Typography>
                        <Box sx={RaisedOnStyles.fixbtn}>{language === "ar" ? <BlackArrowLeft /> : <ArrowRight />}</Box>
                    </Box>
                </Grid>
                <Grid item xs={6} md={6} sm={6} mt={1}>
                    <Box sx={RaisedOnStyles.searchAssetBox} style={{ backgroundPosition: language === "ar" ? "10%" : "90%" }} position="relative" onClick={() => chooseAppliance()}>
                        <Typography sx={RaisedOnStyles.assettext}>{("Search Asset")}</Typography>
                        <Typography sx={RaisedOnStyles.assettext}>{("Item")}</Typography>
                        <Box sx={RaisedOnStyles.fixbtn}>{language === "ar" ? <BlackArrowLeft /> : <ArrowRight />}</Box>
                    </Box>
                </Grid>
            </Grid>
                {/* </Stack> */}
            <Box mt={2} sx={RaisedOnStyles?.root} onClick={router && router ? router : false}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        {icon && icon ? icon : ""}
                        <Stack sx={{ marginInlineStart: "16px" }}>
                            <Typography noWrap sx={RaisedOnStyles?.title}>{title}</Typography>
                            <Typography noWrap sx={RaisedOnStyles?.subTitle}>{subTitle}</Typography>
                            <Typography noWrap sx={RaisedOnStyles?.exampleText}>{exampleTitle}</Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{ position: "absolute", right: "10px" }}>
                        {language === "ar" ?
                            <ArrowBackIosIcon sx={RaisedOnStyles?.arrowIcon} /> :
                            <ArrowForwardIosIcon sx={RaisedOnStyles?.arrowIcon} />
                        }
                    </Stack>
                </Stack>
            </Box>
        </>
    );
}