import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./routes";
import PrivateRoute from "./privateRouter";

import {
  NotFound,
  Login,
  CreateInvestment,
  Statistic,
  ProfileView,
  ProfileEdit,
  Property,
  AddressEdit,
  Home,
  KycInformation,
  GetSupport,
  GetSupportDetailed,
  VacantUnit,
  Invoices,
  KycAddId,
  Request,
  RequestView,
  MaintanceRequest,
  CompanySelect,
  Contracts,
  ContractDetails,
  Assets,
  GeneralRequest,
  Inspection,
  InspectionDetails,
  Utilities,
  CaseManagement,
  CorrespondenceType,
  AmenitiesBooking,
  Filemanager,
  Setup,
  ParkingSlot,
  Vehicle,
  VehicleView,
  AddVehicle,
  Investment,
  Spending,
  FilemanagerDocs,
  Announcement,
  AnnouncementView,
  Payment,
  AddOnsDetailedService,
  AddOnsService,
  ChooseAppliance,
  PayRequestInvoiceList,
  SurveyList,
  SurveyForm,
  PropertyUnitNew,
  PropertyUnitView,
  PropertyDetails,
  AssetViewPage
} from './../screens';
import UtilitiesList from "../screens/utilities/utiliesList";
import WelcomePage from "../screens/welcomePage";
import Contacts from "../screens/contacts";
import CreateContact from "../screens/createContact";
import RestoreErrorHandling from "../screens/restoreErrorHandling";
import LandlordApproval from "../screens/landlordApproval";
import ContractHistory from "../screens/contractHistory";
import ViewImage from "../screens/viewImage";
import QrCode from "../screens/qrCode";
import ExternalSite from "../screens/externalSite";
import BOMDetails from "../screens/requestView/components/costing"
import ApprovalList from "../screens/landlordApproval/approvalList";
import Documents from "../screens/propertyUnitNew/documents";
import Welcome from "../screens/welcome";

const RouterApp = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoutes.home} element={<PrivateRoute element={Home} />} />
        <Route path={AppRoutes.vacantunit} element={<PrivateRoute element={VacantUnit} />} />
        <Route path={AppRoutes.caseManagement} element={<PrivateRoute element={CaseManagement} />} />
        <Route path={AppRoutes.correspondence} element={<PrivateRoute element={CorrespondenceType} />} />
        <Route path={AppRoutes.invoices} element={<PrivateRoute element={Invoices} />} />
        <Route path={AppRoutes.properties} element={<PrivateRoute element={Property} />} />
        <Route path={AppRoutes.statistics} element={<PrivateRoute element={Statistic} />} />
        <Route path={AppRoutes.createInvestment} element={<PrivateRoute element={CreateInvestment} />} />
        <Route path={AppRoutes.createSpending} element={<PrivateRoute element={CreateInvestment} type={"spending"}/>} />
        <Route path={AppRoutes.profileView} element={<PrivateRoute element={ProfileView} />} />
        <Route path={AppRoutes.profileEdit} element={<PrivateRoute element={ProfileEdit} />} />
        <Route path={AppRoutes.addressEdit} element={<PrivateRoute element={AddressEdit} />} />
        <Route path={AppRoutes.kycInformation} element={<PrivateRoute element={KycInformation} />} />
        <Route path={AppRoutes.kycAddIdProof} element={<PrivateRoute element={KycAddId} />} />
        <Route path={AppRoutes.maintanceRequest} element={<PrivateRoute element={MaintanceRequest} />} />
        <Route path={AppRoutes.generalRequest} element={<PrivateRoute element={GeneralRequest} />} />
        <Route path={AppRoutes.amenities} element={<PrivateRoute element={AmenitiesBooking} />} />
        <Route path={AppRoutes.getSupport} element={<PrivateRoute element={GetSupport} />} />
        <Route path={AppRoutes.getSupportDetailed} element={<PrivateRoute element={GetSupportDetailed} />} />
        <Route path={AppRoutes.request} element={<PrivateRoute element={Request} />} />
        <Route path={AppRoutes.requestView} element={<PrivateRoute element={RequestView} />} />
        <Route path={AppRoutes.pay_request_invoice_list} element={<PrivateRoute element={PayRequestInvoiceList} />} />
        <Route path={AppRoutes.inspection} element={<PrivateRoute element={Inspection} />} />
        <Route path={AppRoutes.inspectiondetails} element={<PrivateRoute element={InspectionDetails} />} />
        <Route path={AppRoutes.assets} element={<PrivateRoute element={Assets} />} />
        <Route path={AppRoutes.contracts} element={<PrivateRoute element={Contracts} />} />
        <Route path={AppRoutes.contractDetails} element={<PrivateRoute element={ContractDetails} />} />
        <Route path={AppRoutes.utilities} element={<PrivateRoute element={Utilities} />} />
        <Route path={AppRoutes.utilitiesList} element={<PrivateRoute element={UtilitiesList} />} />
        <Route path={AppRoutes.filemanager} element={<PrivateRoute element={Filemanager} />} />
        <Route path={AppRoutes.fileManagerDocs} element={<PrivateRoute element={FilemanagerDocs} />} />
        <Route path={AppRoutes.parkingSlot} element={<PrivateRoute element={ParkingSlot} />} />
        <Route path={AppRoutes.vehicle} element={<PrivateRoute element={Vehicle} />} />
        <Route path={AppRoutes.vehicleView} element={<PrivateRoute element={VehicleView} />} />
        <Route path={AppRoutes.addVehicle} element={<PrivateRoute element={AddVehicle} />} />
        <Route path={AppRoutes.spending} element={<PrivateRoute element={Spending} />} />
        <Route path={AppRoutes.investment} element={<PrivateRoute element={Investment} />} />
        <Route path={AppRoutes.contacts} element={<PrivateRoute element={Contacts} />} />
        <Route path={AppRoutes.createContacts} element={<PrivateRoute element={CreateContact} />} />
        <Route path={AppRoutes.announcement} element={<PrivateRoute element={Announcement} />} />
        <Route path={AppRoutes.announcementView} element={<PrivateRoute element={AnnouncementView} />} />
        <Route path={AppRoutes.payment} element={<PrivateRoute element={Payment} />} />
        <Route path={AppRoutes.addons} element={<PrivateRoute element={AddOnsService} />} />
        <Route path={AppRoutes.addonsDetailed} element={<PrivateRoute element={AddOnsDetailedService} />} />
        <Route path={AppRoutes.landlordApprovalView} element={<PrivateRoute element={LandlordApproval} />} />
        <Route path={AppRoutes.landLordApprovalList} element={<PrivateRoute element={ApprovalList} />} />
        <Route path={AppRoutes.chooseAppliance} element={<PrivateRoute element={ChooseAppliance} />} />
        <Route path={AppRoutes.contractHistory} element={<PrivateRoute element={ContractHistory} />} />
        <Route path={AppRoutes.viewImage} element={<PrivateRoute element={ViewImage} />} />
        <Route path={AppRoutes.qrCode} element={<PrivateRoute element={QrCode} />} />
        <Route path={AppRoutes.BOMDetails} element={<PrivateRoute element={BOMDetails} />} />
        <Route path={AppRoutes.explore} element={<PrivateRoute element={ExternalSite} />} />
        <Route path={AppRoutes.surveyList} element={<PrivateRoute element={SurveyList} />} />
        <Route path={AppRoutes.surveyForm} element={<PrivateRoute element={SurveyForm} />} />
        <Route path={AppRoutes.propertyUnitNew} element={<PrivateRoute element={PropertyUnitNew} />} />
        <Route path={AppRoutes.propertyUnitView} element={<PrivateRoute element={PropertyUnitView} />} />
        <Route path={AppRoutes.propertyDetails} element={<PrivateRoute element={PropertyDetails} />} />
        <Route path={AppRoutes.assetViewPage} element={<PrivateRoute element={AssetViewPage} />} />
        <Route path={AppRoutes.agreementDocument} element={<PrivateRoute element={Documents} />} />

        <Route path={AppRoutes.companySelect} element={<CompanySelect />} />
        <Route path={AppRoutes.welcomepage} element={<WelcomePage />} />
        <Route path={AppRoutes.setup} element={<Setup />} />
        <Route path={AppRoutes.login} element={<Login />} />
        <Route path={AppRoutes.welcome} element={<Welcome />} />
        <Route path={AppRoutes.restoreErrorHandling} element={<RestoreErrorHandling />} />
        <Route path="*" element={<NotFound />} />
      </Routes >
    </BrowserRouter >
  );
}

export default RouterApp;











