import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";
import { BackendRoutes } from "../../../router/routes";
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });


export const requestAPI = createApi({
    reducerPath: "requestAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Request"],
    endpoints: (builder) => ({
        // Get All Request API
        GetAllRequest: builder.query({
            query: (payload) => ({ url: '/request', method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        GetCosting: builder.query({
            query: (payload) => ({ url: '/request_costing/get_costing', method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        GetJobDetails: builder.query({
            query: (payload) => ({ url: '/request/get_maintenance_job_visits', method: "POST", body: payload}),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        getRequestView: builder.query({
            query: (payload) => ({ url: `/request-view`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        GetRequestUnit: builder.query({
            query: ({ offset = 0, limit = 10, search }) => ({
                url: `owner/get_all_owner_units`,
                method: "POST",
                body: {
                    offset,
                    limit,
                    search
                }
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        MaintainanceCategoryMaster: builder.query({
            query: ({ offset = 0, limit = 10, search }) => ({
                url: `maintenance-master/category_master`,
                method: "POST",
                body: {
                    offset,
                    limit,
                    search
                }
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),

        MaintainanceSubCategoryMaster: builder.query({
            query: (payload) => ({
                url: `maintenance-master/sub_category_master`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        userProfile: builder.query({
            query: (payload) => ({
                url: `user-profile`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        MaintainanceRequestCreation: builder.query({
            query: (payload) => ({
                url: `request/create`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        GeneralCategoryMaster: builder.query({
            query: ({ offset = 0, limit = 10, search }) => ({
                url: `general-master/category_master`,
                method: "POST",
                body: {
                    offset,
                    limit,
                    search
                }
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        GeneralSubCategoryMaster: builder.query({
            query: (payload) => ({
                url: `general-master/sub_category_master`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        cancelRequest: builder.query({
            query: (payload) => ({
                url: `request/cancelation`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        getServiceLevelAgreement: builder.query({
            query: (payload) => ({ url: `/request/service_level_agreement`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        acceptCosting: builder.query({
            query: (payload) => ({ url: `/request_costing/visting_charge/update_status`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        costingApproval: builder.query({
            query: (payload) => ({ url: `/request_costing/update_costing_request`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        updateActualExpense: builder.query({
            query: (payload) => ({ url: `/request_costing/update_actual_expense`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        updateReschedule: builder.query({
            query: (payload) => ({ url: `/request/maintenance_job_visit_approval`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        getAllProducts: builder.query({
            query: (payload) => ({ url: `/inspection_item_mapping/getAll`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        getAllLocations: builder.query({
            query: (payload) => ({ url: `/inspection_item_location_master`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        scanQrCode: builder.query({
            query: (payload) => ({ url: `/request/scan_item`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        getRequestInvoiceList: builder.query({
            query: (payload) => ({ url: BackendRoutes?.invoice_maintenance_invoice, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
        getSlotMaster: builder.query({
            query: (payload) => ({ url: `/time_slot_master/get`, method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),
    }),
});


// Export hooks for usage in functional components
export const {
    useLazyGetAllRequestQuery,
    useLazyGetRequestViewQuery,
    useLazyGetRequestUnitQuery,
    useLazyMaintainanceCategoryMasterQuery,
    useLazyMaintainanceSubCategoryMasterQuery,
    useLazyUserProfileQuery,
    useLazyMaintainanceRequestCreationQuery,
    useLazyGeneralCategoryMasterQuery,
    useLazyGeneralSubCategoryMasterQuery,
    useLazyCancelRequestQuery,
    useLazyGetServiceLevelAgreementQuery,
    useLazyAcceptCostingQuery,
    useLazyUpdateActualExpenseQuery,
    useLazyGetAllProductsQuery,
    useLazyGetAllLocationsQuery,
    useLazyScanQrCodeQuery,
    useLazyGetCostingQuery,
    useLazyCostingApprovalQuery,
    useLazyGetJobDetailsQuery,
    useLazyUpdateRescheduleQuery,
    useLazyGetRequestInvoiceListQuery,
    useLazyGetSlotMasterQuery
    
} = requestAPI;
