import { ChartBox, DialogDrawer, InvestmentActivityCard, InvestmentActivityModal, LineCharts, SelectBox }
    from "../../components"
import { Typography, Box, Fab, Container } from "@mui/material";
import { Spending_style } from "./style";
import React, { useEffect, useState } from 'react'
import { month, currentYear, getYear, currentMonth, errorRoute, currencyFormatted } from '../../utils'
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useLazySpendingGraphQuery, useLazySpendingListingQuery, useLazyStatisticQuery } from "../../redux/services";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import { ActivityCardsimmer } from "./component/activityCardsimmer";
import useWindowDimensions from "../../useDimension";
const initial = {
    yearSelect: {
        value: currentYear,
        label: currentYear
    },
    monthSelect: {
        value: currentMonth,
        label: currentMonth
    },
    categorySelect: {
        value: null,
        label: "All"
    }
}


export const Investment = ({
    t = () => false,
    bottomSx
}) => {
    const navigate = useNavigate();
    const [drawer, setDrawer] = React.useState(false);
    const [GetSpending , {error}] = useLazySpendingListingQuery();
    const [GetGraph  , {error:isError}] = useLazySpendingGraphQuery()
    const [spendingListing, setSpendingListing] = useState({
        id: "",
        listingDrawer: [],
        listingGraph: [],
        listingType: [],
        boolGraph: true,
        listingDatas: [],
        bool: true,
        modelData: {},
    });
    const [getGraph, setGraph] = useState({
        boolGraph: true,
        listingGraph: [],
    })
    const [offSet, setOffSet] = useState(0);
    const [toggle, setToggle] = useState("1")
    const [Statistic] = useLazyStatisticQuery()
    const [loading1, setLoading1] = React.useState(null);
    const [select, setSelect] = useState({ ...initial, bool: true })
    const size = useWindowDimensions();


    const handleDrawer = async (type, value) => {
        setSpendingListing({
            ...spendingListing,
            id: value?.id,
            inpercent: value?.inpercent
        })

        if (type === "open") {
            setDrawer(true);
        } else if (type === "close") {
            setDrawer(false);
        }
    };
    // drawer close
    const handleDrawerClose = () => {
        setDrawer(false);
    }

    // create Investment Route
    const createIcon = () => {
        navigate(AppRoutes.createInvestment)
    }

 
    const updateState = (k, v) => {
        setSelect({ ...select, [k]: v });
    };

    // offset data	
    const fetchMoreData = () => {
        getGraphList({
            offset: offSet + 10,
            limit: 10,
            type: "Investments",
            category_id: select?.categorySelect?.value,
            month: toggle === "1" ? undefined : month.indexOf(select?.monthSelect?.value) + 1,
            year: select?.yearSelect.value, 
        }, true)
        setOffSet(offSet + 10);
    }
    const loadOptions = async (search = "", array, type) => {
        setLoading1(type);
        let result,
            offset = 0;
        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }
        switch (type) {
            case "categorySelect":
                result = await Statistic({
                    offset,
                    limit: 10, search
                })
                setLoading1(null);
                return {
                    options: offset === 0 ? [{ value: null, label: "All" }, ...result?.data?.data] : [...result?.data?.data],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.data?.count,
                };
            default:
                return { options: [] };
        }
    };
    const handleChange = (e) => {
        updateState("categorySelect", e)
            initialData({
                month: toggle === "1" ? undefined : month.indexOf(select?.monthSelect?.value) + 1,
                 year: select?.yearSelect.value, 
                 type: "Investments", 
                 category: e?.value
            })
    }
    const toggleChange = (e) => {
        setToggle(e)
        if (e === "2") {
            initialData({
                month: month.indexOf(select?.monthSelect?.value) + 1, year: select?.yearSelect.value, type: "Investments", category: select?.categorySelect?.value
            })
        }
        else {
            initialData({
                year: select?.yearSelect?.value, type: "Investments", category: select?.categorySelect?.value
            })
        }
    }
    // const handleChangePeriod = async (key, val) => {
    //     initialData({
    //         month: toggle === "1" ? undefined : month.indexOf(val?.value) + 1,
    //         year: select?.yearSelect.value,
    //         type: "Investments",
    //         category: select?.categorySelect?.value
    //     })
    //     setSelect({
    //         ...select, [key]: {
    //             value: val?.value,
    //         }
    //     })
    // }
    const handleChangePeriod = async (m, y) => {
        setSelect({
          ...select,
          yearSelect: y,
          monthSelect: m
        })
    
        initialData({
          month: toggle === "1" ? undefined : month.indexOf(m?.value) + 1,
          year: y.value,
          type: "Investments",
          category: select?.categorySelect?.value
        })
      }


    // const initialData = async () => {
    //     handleDrawer()
    //     await getStatistic()

    //     await getSpendingList(
    //         {
    //             offset: 0,
    //             limit: 10,
    //             type: "Investments",
    //             category_id: null
    //         })
    // }


    const getSpendingGraph = async (payload) => {
        const data = await GetGraph(payload)
        setGraph({
            listingGraph: data?.data?.data?.filter((val)=> val?.month !== 12)?.map((x) => {
                return {
                    name: payload?.month ? x?.day : month[x?.month - 1],
                    amount: x?.amount ?? 0,
                    current_value:  x?.current_value ?? 0,
                    category:x?.category,
                    sub_category:x?.sub_category
                }
            }),
            boolGraph: false
        })
    }

    const getGraphList = async(payload , isFilter)=>{
        const data = await GetSpending(payload)
        let temp_data = data?.data?.data?.data?.map(i => {
            const percents = ((i?.current_value - i?.amount) / i?.amount) * 100
            const inpercent = parseFloat(percents).toFixed(2);
            return {
                ...i, inpercent
            }
        })
        setSpendingListing({
            ...spendingListing,
            listingDatas: isFilter ? spendingListing?.listingDatas.concat(temp_data) : temp_data,
            bool: false
        })
    }

    const initialData = async (payload) => {
        await getSpendingGraph({
          year: payload?.year,
          month:payload?.month,
          type: "Investments",
          category: payload?.category ?? undefined
        })
        await getGraphList({
          offset: payload?.offset ?? 0,
          limit: payload?.limit ??  10,
          type: "Investments",
          category_id: payload?.category ?? undefined,
          month:payload?.month ?? undefined,
          year: payload?.year ?? undefined,
        })
    
      }


    useEffect(() => {
        initialData({
          year: select?.yearSelect?.value,
          type: "Spendings",
          category: select?.categorySelect?.value ?? undefined
        })
        // eslint-disable-next-line
      }, [])

    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423) return errorRoute() 
     },[error , isError])
    return (
        <>
            <Box sx={Spending_style?.root}>
                <Box >
                    <ChartBox
                        onChange={toggleChange}
                        handleChangePeriod={handleChangePeriod}
                        title={t("Investments")}
                        hidecomponent={<LineCharts data={getGraph?.listingGraph ?? []} category={select?.categorySelect?.value}/>}
                        dropcomponent={
                            <SelectBox
                                onChange={handleChange}
                                loading={loading1 === "categorySelect"}
                                isPaginate={true}
                                controls={{
                                    borderRadius: "8px !important",
                                    height: "32px !important"
                                }}
                                radius={"8px !important"}
                                selectHeight={"32px !important"}
                                loadOptions={(search, array, handleLoading) =>
                                    loadOptions(search, array, "categorySelect")}
                                value={select?.categorySelect}
                            />}

                        yearSelect={(e) => {
                            initialData({ year: e?.value, type: "Investments", category: select?.categorySelect?.value ,  month: toggle === "1" ? undefined : month.indexOf(select?.monthSelect?.value) + 1,
                        })
                            updateState("yearSelect", e)
                        }}
                        monthValue={select?.monthSelect?.value}
                        yearValue={select?.yearSelect?.value}
                        togglebtn={true}
                        symbol={"$"}
                        chart_parent={{ padding: "19px 18px 10px 12px !important" }}
                        options={[
                            { value: "1", label: t("Yearly") },
                            { value: "2", label: t("Monthly") },
                        ]}
                        year={getYear()?.map((x) => {
                            return {
                                value: x,
                                label: x
                            }
                        }) ?? []}
                        month={month?.map((x) => {
                            return {
                                value: x,
                                label: x
                            }
                        }) ?? []}
                        values={{
                            month:select?.monthSelect,
                            year:select?.yearSelect
                          }}
                        value={toggle}
                        isFull
                        sx={{ padding: '10px' }}
                        toggles={toggle === "2" ? true : false}
                        loading1={loading1}
                        hidecomponentFlex={{
                            justifyContent: getGraph?.boolGraph ? "center" : "start",
                        }}
                        loader={getGraph?.boolGraph}
                    />
                </Box>
                {
                    spendingListing?.listingDatas?.length > 0 ?
                        <Box
                            sx={Spending_style?.headingSec}
                        >
                            <Typography sx={Spending_style?.heading}>{t("Activity")}</Typography>
                        </Box> : ""
                }

                <Box elevation={0}>
                    {spendingListing?.bool ?
                        <Box p={2}>
                            <ActivityCardsimmer />
                        </Box> :
                        <Box sx={Spending_style?.paper}>


                            <InfiniteScroll
                                dataLength={spendingListing?.listingDatas?.length ?? 0}
                                next={fetchMoreData}
                                hasMore={true}
                                back
                                height={size?.height - 291}
                                sx={Spending_style?.infiniteScroll}

                            >
                                {spendingListing?.listingDatas?.map((val, index) => (
                                    <Box
                                        key={index}
                                        sx={Spending_style?.investmentCard}>
                                        <InvestmentActivityCard
                                            t={t}
                                            price={currencyFormatted(val?.amount)}
                                            activity={val?.category_name}
                                            business={val?.sub_category_name}
                                            date={moment(val?.created_at).format(
                                                "DD-MMM-YY"
                                            )}
                                            image={val?.image ? val?.image :
                                                <img width="100%" alt="img"
                                                    src="/images/investmentAvatarNew.svg"></img>}
                                            gainLoss={val?.percentage}
                                            Inflationcolor={val?.Inflationcolor}
                                            InflationIcon={val?.InflationIcon}
                                            inflationpercentage={val?.percentage}
                                            currValue={currencyFormatted(val?.current_value)}
                                            route={() => handleDrawer("open", val)}
                                            hidden={spendingListing?.listingDatas?.length === index + 1 ? false : true}
                                            rightSec
                                            isBadge
                                        />
                                    </Box>
                                ))}
                            </InfiniteScroll>

                        </Box>}

                </Box>

            </Box>
            <Box sx={Spending_style.AddIcon}>
                <Container maxWidth="sm" sx={Spending_style.AddIconContainer}>
                    <Fab sx={{ ...Spending_style?.AddIconChild, ...bottomSx }} onClick={createIcon}>
                        <AddIcon />
                    </Fab>
                </Container>
            </Box>
            <DialogDrawer
                maxWidth="sm"
                open={drawer}
                onClick={() => handleDrawer("close")}
                height_style={
                    { height: "340px !important", padding: '0px !important' }
                }
                header="Investment Details"
                component={
                    <>
                        <InvestmentActivityModal
                            gainLoss={spendingListing?.inpercent}
                            id={spendingListing?.id}
                            handleClose={() => { handleDrawerClose() }}
                        />

                    </>}

                onClose={() => {
                    setDrawer(false)
                }}
            />
        </>

    )
}