import React from 'react'
import { Box, Typography, Stack, Divider, Grid, Avatar } from "@mui/material"
import { PropertyUnitNewStyle } from './style'
import ParkingSlotIcon from './utils/parkingSlot';
import FloorIcon from './utils/floorIcon';
import ElevatorIcon from './utils/elevatorIcon';
import AmenityIcon from './utils/amenityIcon';
import BathroomIcon from './utils/bathroomIcon';
import BedRoomIcon from './utils/bedRoomIcon';
import BalconyIcon from './utils/balcanyIcon';
import OrientationIcon from './utils/orientationIcon';
import MarketPriceIcon from './utils/marketPriceIcon';
import GuestRoomIcon from './utils/guestRoomIcon';
import LivingRoomIcon from './utils/livingRoomIcon';
import MasterBedRoomIcon from './utils/masterBedroomIcon';
import ServantRoomIcon from './utils/servantRoomIcon';
// import DriverRoomIcon from './utils/driverRoomIcon';
import ChildrenRoomIcon from './utils/childrenRoomIcon';
import KitchenIcon from './utils/kitchenIcon';
import CarpetIcon from './utils/carpetIcon';
import BalconyIconNew from './utils/balconyIcon';
import TerraceIcon from './utils/terraceIcon';
import PropUnitIcon from './utils/propunitIcon';
import ScrollContainer from 'react-indiana-drag-scroll';
import PropBlockIcon from './utils/propBlockIcon';
import { stringAvatar } from '../../utils';
const language = localStorage.getItem("i18nextLng")

export const AllAmenities = ({
    type = "",
    data = "",
    t = () => false
}) => {
    const classes = PropertyUnitNewStyle

    const otherDetails = [
        {
            icon: <PropUnitIcon />,
            title: t("Unit"),
            value: data?.unit_count ?? 0
        },
        {
            icon: <ParkingSlotIcon />,
            title: t("Parking Slots"),
            value: data?.parking_slot_count ?? 0
        },
        {
            icon: <FloorIcon />,
            title: t(data?.custom_floor_label),
            value: data?.floor_count ?? 0
        },
        {
            icon: <ElevatorIcon />,
            title: t("Elevators"),
            value: data?.elevators ?? 0
        }
    ]

    const unitOtherDetails = [
        {
            icon: <BathroomIcon />,
            title: t("Bathroom"),
            value: data?.total_baths ?? 0
        },
        {
            icon: <BedRoomIcon />,
            title: t("Bedroom"),
            value: data?.total_bed_rooms ?? 0
        },
        {
            icon: <BalconyIcon />,
            title: t("Balcony"),
            value: data?.balconies ?? 0
        },
        {
            icon: <OrientationIcon />,
            title: t("Orientation"),
            value: data?.orientation ?? "-"
        }
    ]


    const sizeOverView = [
        {
            icon: <MasterBedRoomIcon />,
            title: `${data?.master_bedroom_size ?? 0} ${data?.uom?.name}`,
            subtitle: t("Master Bedroom")
        },
        {
            icon: <LivingRoomIcon />,
            title: `${data?.living_room_size ?? 0} ${data?.uom?.name}`,
            subtitle: t("Living Room")
        },
        {
            icon: <GuestRoomIcon />,
            title: `${data?.guest_room_size ?? 0} ${data?.uom?.name}`,
            subtitle: t("Guest Room")
        },
        {
            icon: <ServantRoomIcon />,
            title: `${data?.servant_room_size ?? 0} ${data?.uom?.name}`,
            subtitle: t("Servant Room")
        },
        // {
        //     icon: <DriverRoomIcon />,
        //     title: `${} ${data?.uom?.name}`,
        //     subtitle: "Driver Room"
        // },
        {
            icon: <ChildrenRoomIcon />,
            title: `${data?.children_room_size ?? 0} ${data?.uom?.name}`,
            subtitle: t("Children Room")
        },
        {
            icon: <KitchenIcon />,
            title: `${data?.kitchen_size ?? 0} ${data?.uom?.name}`,
            subtitle: t("Kitchen")
        },
        {
            icon: <CarpetIcon />,
            title: `${data?.carpet_area ?? 0} ${data?.uom?.name}`,
            subtitle: t("Carpet")
        },

        {
            icon: <BalconyIconNew />,
            title: `${data?.balcony_area ?? 0} ${data?.uom?.name}`,
            subtitle: t("Balcony")
        },
        {
            icon: <TerraceIcon />,
            title: `${data?.terrace_area ?? 0} ${data?.uom?.name}`,
            subtitle: t("Terrace")
        },
        {
            icon: <CarpetIcon />,
            title: `${data?.total_area ?? 0} ${data?.uom?.name}`,
            subtitle: t("Super Build-up Area")
        },
    ]


    return (
        <Box p={2}>
            <Box>
                {/* {type === "property" && <Typography sx={classes.contactText}>Other details</Typography>} */}

                <Box sx={{ ...classes.propertyBox, position: "relative", zIndex: 999, backgroundColor: "#fff" }}>
                    <Typography sx={classes.contactText}>{t("Other details")}</Typography>
                    {
                        (type === "property" && data?.block?.length > 0) &&
                        <Box>
                            <Stack direction={"row"} spacing={1} alignItems={"center"} mt={"12px"}>
                                <PropBlockIcon />
                                <Typography sx={classes.valueText}>{data?.block?.length} <span style={classes.secText}>{t(data?.custom_block_label) ?? ""}</span></Typography>
                            </Stack>

                            <Box mt={1}>
                                <ScrollContainer className="scroll-container">
                                    <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
                                        {
                                            data?.block?.map((e) => {
                                                return (
                                                    <Stack sx={{ ...classes.borderBox, width: "130px" }} spacing={"6px"}>
                                                        <Typography sx={{ ...classes.valueText, ...classes.valueTextDotted }}>{e?.name}</Typography>
                                                        <Typography sx={classes.secText}>{e?.unit?.[0]?.count} {t("Units")}</Typography>
                                                        <Typography sx={classes.secText}>{e?.floor?.[0]?.count} {t(data?.custom_floor_label)}</Typography>
                                                    </Stack>
                                                )
                                            })
                                        }
                                    </Stack>
                                </ScrollContainer>
                            </Box>

                            <Box py={2}><Divider></Divider></Box>
                        </Box>
                    }
                    <Grid container>
                        {
                            (type === "property" ? otherDetails : unitOtherDetails)?.map((e, i) => {
                                return (
                                    <Grid item xs={6} sx={i % 2 === 0 ? language === "ar" ? classes.leftBorder : classes.rightBorder : ""}>
                                        <Stack direction={"row"} alignItems={"center"} columnGap={1} p={"12px"}>
                                            {e?.icon}
                                            <Stack direction={"row"} alignItems={"center"} columnGap={"4px"}>
                                                <Typography sx={classes.valueText}>{e?.value}</Typography>
                                                <Typography sx={classes.secText}>{e?.title}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
                {
                    type === "unit" &&
                    <Box sx={classes.priceBox} p={"12px 16px 0px"}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={"16px 0px 8px 0px"}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <MarketPriceIcon />
                                <Typography sx={classes.marketPriceText}>{t("Market Price")}</Typography>
                            </Stack>
                            <Typography sx={classes.marketPriceTextBold}>{data?.market_price ?? 0} {data?.property?.company?.currency?.symbol}</Typography>
                        </Stack>
                    </Box>
                }

                {
                    (type === "unit" && data?.registration_office?.name?.length > 0) &&
                    <Box sx={classes.locationBox} mt={2}>
                        <Typography sx={{ ...classes.title, fontSize: "0.75rem", textTransform: "uppercase" }}>{t("Registration Location")}</Typography>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack spacing={"4px"} mt={"12px"}>
                                <Typography sx={{...classes.propertyTitle, ...classes.regTextWidth}}>{data?.registration_office?.name}</Typography>
                                <Typography sx={{...classes.secondaryText, ...classes.regTextWidth}}>
                                    {data?.registration_office?.address?.street_1 ?? ""}{data?.registration_office?.address?.city && ", "}{data?.registration_office?.address?.city ?? ""}{data?.registration_office?.address?.state && ", "} {data?.registration_office?.address?.state ?? ""}{data?.registration_office?.address?.zipcode && " - "}{data?.registration_office?.address?.zipcode}
                                </Typography>
                            </Stack>

                            <Avatar src={data?.registration_office?.image_url} {...stringAvatar(data?.registration_office?.name)} sx={classes.contactAvatar} />
                        </Stack>
                    </Box>
                }

            </Box>

            <Typography sx={classes.contactText} my={2}>{type === "unit" ? t("Size Overview") : `${data?.facilities?.length} ${t("Amenities Details")}`}</Typography>

            {
                type === "unit" && <Grid container>
                    {
                        sizeOverView?.map((e, i) => {
                            return (
                                <Grid item xs={12}>
                                    <Stack direction={"row"} columnGap={"12px"}>
                                        {e?.icon}
                                        <Stack spacing={"4px"}>
                                            <Typography sx={classes.propertyTitle}>{e?.title}</Typography>
                                            <Typography sx={classes.secondaryText}>{e?.subtitle}</Typography>
                                        </Stack>
                                    </Stack>
                                    {sizeOverView?.length - 1 !== i && <Box my={"12px"}><Divider></Divider></Box>}
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }

            {
                type === "property" &&
                <Grid container spacing={3}>
                    {
                        data?.facilities?.map((e) => {
                            return (
                                <Grid item xs={6}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"12px"}>
                                        <AmenityIcon />
                                        <Typography sx={{ ...classes.propertyTitle, ...classes.amenityTitleWidth }}>{e?.description}</Typography>
                                    </Stack>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
        </Box >
    )
}
