import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NewAnnouncementCard } from "./components/newAnnouncementCard";


export const CarouselSliders = ({ title = "", list = [], isCommunity = false, isAll = false }) => {

  var settings = {
    dots: true,
    infinite: false,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // autoplay: autoScroll,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false
  };

  const navigate = useNavigate()

  return (
    <Box>
      <Slider {...settings}>
        {list?.map((_, index) =>
        (

          <NewAnnouncementCard
            data={_}
            isCommunity={isCommunity}
            isAll={isAll}
            viewNotification={(data)=> navigate(`${data?.redirect_url}`, { state: data?.id })}
            hideDot
          // logo={_?.description?.length > 0 ? _?.description[0]?.images : '/images/announsmentnot.svg'}
          // category={_?.category ? _?.category : "-"}
          // title={_?.title ? _?.title : "-"}
          // postedOn={_?.triggered_at ? `Posted at ${moment(new Date(_?.triggered_at)).fromNow()}` : "-"}
          // onClickSlider={() => onClickSlider(_)}
          />

        ))
        }
      </Slider>
    </Box>
  );
};
