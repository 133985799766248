import { Box, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from "moment-timezone";
import 'quill/dist/quill.bubble.css';
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import { DialogDrawer, DocumentViewer, FileViewers, Slider, TopNavBar } from "../../components";
import { useLazyGetAnnouncementViewQuery } from "../../redux/services";
import { errorRoute, generateImageUrl, img_size, LocalStorageKeys } from "../../utils";
import { AnnouncementViewStyle, useStyles } from "./style";

export const AnnouncementView = ({
    t = () => false,
}) => {
    const location = useLocation();
    const classes = useStyles()
    const navigate = useNavigate()
    const match = useMediaQuery('(min-width:602px)')
    const userProfileId = localStorage.getItem(LocalStorageKeys?.userProfileId);
    const [datas, setDatas] = useState({
        announcementView: {},
        bool: true,

    });

    const [GetAnnouncementView, { error }] = useLazyGetAnnouncementViewQuery();
    const [pdfViewer, setPdfViewer] = React.useState({
        bool: false,
        data: "",
    });

    const [isViewerOpen, setIsViewerOpen] = React.useState({
        bool: false,
        data: 0
    });

    const openImageViewer = (url) => {
        setPdfViewer({
            bool: true,
            data: url,
        });
    };

    const closeImageViewer = () => {
        setPdfViewer({
            bool: false,
            data: "",
        });
    };

    const modules = {
        toolbar: false,
    };

    const getAnnouncementView = async (payload) => {
        const data = await GetAnnouncementView(payload)
        setDatas({
            ...datas,
            announcementView: data?.data?.data,
            bool: false
        })
    }

    const updateState = (index, close) => {
        if (close) {
            setIsViewerOpen({ ...isViewerOpen, bool: !isViewerOpen?.bool })
        }
        else {
            setIsViewerOpen({ data: index, bool: !isViewerOpen?.bool })
        }

    }

    useEffect(() => {
        getAnnouncementView({ id: location?.state, user_profile_id: userProfileId })
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (error?.status === 423) return errorRoute()
    }, [error])

    const handleBack = () => {
        navigate(-1)
    }
    return (
        <Container
            sx={AnnouncementViewStyle?.container}
            maxWidth="sm"
            style={{ padding: "0px !important" }}
        >
            <Box sx={AnnouncementViewStyle?.TopNavBar}>
                <Container sx={AnnouncementViewStyle?.container} style={{ padding: "0px !important" }}
                    maxWidth="sm">
                    <TopNavBar title={t("Announcement")} isDirect route={handleBack} t={t} />
                </Container>
            </Box>

            <Box sx={AnnouncementViewStyle?.root}>
                <Box
                    sx={AnnouncementViewStyle?.content}
                >
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        flexWrap={"wrap"}
                        marginBottom={"10px"}
                    >
                        <Typography sx={AnnouncementViewStyle?.health}>
                            {datas?.announcementView?.notification_details?.category}
                        </Typography>
                        <Typography sx={AnnouncementViewStyle?.postedLine}>
                            |
                        </Typography>

                        <Typography sx={AnnouncementViewStyle.posted}>
                            {t("Posted at")} {moment(new Date(datas?.announcementView?.notification_details?.triggered_at)).fromNow()} {datas?.announcementView?.created_user?.first_name}
                        </Typography>
                    </Stack>
                    {datas?.announcementView?.notification_details?.title && (
                        <Typography sx={AnnouncementViewStyle.title}>
                            {datas?.announcementView?.notification_details?.title}

                        </Typography>
                    )
                    }
                    {datas?.announcementView?.notification_details?.description?.map((x, index) => {
                        return (
                            <Box mt={1}>
                                {x?.images !== "" ? (
                                    <img
                                        src={generateImageUrl(x?.images ?? "", img_size.small_square)}
                                        alt=""
                                        style={{
                                            width: "100%",
                                            height: "211px",
                                            borderRadius: "4px",
                                            marginBottom: "10px",
                                            marginTop: "10px",
                                        }}
                                        onClick={() => updateState(index, false)}
                                    />
                                ) :
                                    <img src={"/images/announcementPlaceholder.png"} alt="placeholder" width="100%" height={match ? "285px" : "auto"} />}


                                {x?.Description && (
                                    // <Typography sx={AnnouncementViewStyle.dis}>
                                    // <div dangerouslySetInnerHTML={{__html:datas?.Description ?? ""}} />
                                    <ReactQuill
                                        readOnly
                                        theme="bubble"
                                        value={x?.Description ?? ""}
                                        modules={modules}
                                        className={classes.reactQuil}
                                    />
                                    // </Typography>
                                )}
                                {x?.attachment && (
                                    <Box>
                                        <Box height={'16px'} />
                                        <Grid container spacing={1}>
                                            {datas?.attachment?.map((val) => {
                                                return (
                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                        <Box
                                                            sx={AnnouncementViewStyle.imageCard}
                                                            onClick={() => openImageViewer(val)}
                                                        >
                                                            <FileViewers fileType={"pdf"} filePath={val?.url} />
                                                        </Box>
                                                        <DialogDrawer
                                                            height={"400px"}
                                                            open={pdfViewer?.bool ?? false}
                                                            header={pdfViewer?.data?.name ?? ""}
                                                            component={
                                                                pdfViewer?.data?.url?.length > 0 ? (
                                                                    <DocumentViewer url={x?.url} />
                                                                ) : (
                                                                    t("No Date Found")
                                                                )
                                                            }
                                                            onClose={() => closeImageViewer()}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                </Box>

                {/* last image */}
                <DialogDrawer
                    height={"100px !important"}
                    open={isViewerOpen?.bool ?? false}
                    isnotTitle
                    height_style={{
                        height: "280px !important"
                    }}
                    component={
                        <Slider
                            selected={isViewerOpen?.data}
                            assets={
                                // eslint-disable-next-line
                                datas?.announcementView?.notification_details?.description?.map((x) => {
                                    if (x?.images) {
                                        return {
                                            meta_data: {
                                                file_type: "image",
                                            },
                                            url: x?.images ?? "",
                                        }
                                    }
                                }).filter(Boolean)
                            }
                        />
                    }
                    onClose={() => updateState("", true)}
                />

            </Box>
        </Container>

    );
};
