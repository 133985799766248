import React from "react";
import { withTranslation } from "react-i18next";
import { Login } from "./login";
import { LocalStorageKeys } from "../../utils";
import { AppRoutes } from "../../router/routes";
class LoginParent extends React.Component {
  componentDidMount() {
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      this.props.history.push(AppRoutes.home);
    }
  }

  render() {
    return <Login {...this?.props} />;
  }
}

export default withTranslation("login")(LoginParent);
