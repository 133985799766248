import { CorrespondenceType_style } from "./style"
import { Container, Grid, Typography } from "@mui/material"
import { FooterComponent, MessageCard, TopNavBar, TextBox, DialogDrawer, DocumentViewer, LoderSimmer } from "../../components"
import { Box } from "@mui/system"
import React, { useLayoutEffect, useState } from 'react'
import { useLazyCasemanageQuery, useLazyMarkAsReadQuery, useLazyMessageSubmitQuery, useLazyViewPdfQuery } from "../../redux/services"
import moment from "moment-timezone"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "../../router/routes"
import { errorRoute } from "../../utils"
import useWindowDimensions from "../../useDimension"
import { AssetCard } from "./components/assetCard"
import { Slider } from "./components/slider"
export const CorrespondenceType = ({ t = () => false }) => {
    const location = useLocation()
    const size = useWindowDimensions()
    const navigate = useNavigate()
    const [drawer, setDrawer] = React.useState(false);
    const [casemanage, { error }] = useLazyCasemanageQuery()
    const [markAsRead, { error: isError }] = useLazyMarkAsReadQuery()
    const [messageSubmit] = useLazyMessageSubmitQuery()
    const [viewPdf] = useLazyViewPdfQuery()
    const [template, setTemplate] = React.useState("")
    const [loading, setLoading] = React.useState(true)
    const [selectedAsset, setSelectedAsset] = React.useState({
        asset:0,
        bool:false
      })
    const [correspond, setCorrespond] = useState({
        listingDocument: [],
        listingMark: [],
        listingSubmit: [],
        reply: "",
        bool: true,
        error: {
            reply: "",
            listingDocument: "",
            listingMark: "",
            listingSubmit: "",

        }
    })
    const updateState = (k, v) => {
        let error = correspond?.error
        error[k] = "";
        setCorrespond({ ...correspond, [k]: v, error });
    };
    const handleSubmit = (v) => {
        if (validate()) {

            CorrespondenceSubmit({
                correspondence_id: location?.state?.correspondence_id,
                comment: location?.state?.comment
            })
            setDrawer(false);
        }
        else {
            return false
        }
    }
    const handleClick = () => {
        CorrespondenceMarkasRead({
            correspondence_id: location?.state?.correspondence_id,
            status: 2
        })
        navigate(AppRoutes.caseManagement)
    }

    const handleDrawerOpen = () => {
        setDrawer(!drawer);
    };
    const CorrespondenceManegeDatas = async (payload) => {
        const data = await casemanage(payload)
        setCorrespond({
            ...correspond,
            listingDatas: data?.data?.data?.list,
            bool: false
        })
    }
    const CorrespondenceViewPdf = async () => {
        let payload = {
            correspondence_id: location?.state?.correspondence_id,
            is_template: true
        }
        const res = await viewPdf(payload)
        setLoading(false)
        setTemplate(res.data.data)
    }
    const CorrespondenceMarkasRead = async (payload) => {
        const data = await markAsRead(payload)
        setCorrespond({
            ...correspond,
            listingMark: data?.data?.data?.list,
            // bool: false
        })
    }
    const CorrespondenceSubmit = async (payload) => {
        const data = await messageSubmit(payload)
        setCorrespond({
            ...correspond,
            listingSubmit: data?.data?.data?.list,
            bool: false
        })
    }
    const validate = () => {

        let isValid = true;
        let error = correspond.error
        if (correspond?.reply.length === 0) {
            isValid = false
            error.category = t("Message is required")
        }
        setCorrespond({ ...correspond, error })
        return isValid
    }
    useLayoutEffect(() => {
        if (location?.state) {
            CorrespondenceManegeDatas({ correspondence_id: location?.state?.correspondence_id })
        }
        // eslint-disable-next-line
    }, [])


    React.useEffect(() => {
        CorrespondenceViewPdf()
        if (error?.status === 423 || isError?.status === 423) return errorRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, isError])

    const onClickAsset = (val) => {
        setSelectedAsset({
          bool: !selectedAsset?.bool,
          data: val,
        });
      };
    return (
        <Container sx={CorrespondenceType_style?.container} maxWidth="sm">
            <Box sx={CorrespondenceType_style?.Topnav}>
                <TopNavBar title={location?.state?.type} isDirect isBack t={t} position={'absolute'} />
            </Box>
            {loading ?
                <Box p={2}>
                    <LoderSimmer card count={1} size={500} />
                    <LoderSimmer card count={2} size={100} />
                </Box>
                : correspond?.listingDatas?.map((val, index) => (
                    <Box sx={CorrespondenceType_style?.root} style={{ height: size.height - 134, overflow: "overlay" }}>


                        <div
                        // className={classes.heightOverflow}
                        // style={{ height: size.height - 134, overflow: "overlay" }}
                        >
                            {template ? (
                                <DocumentViewer url={
                                    `data:image/png;base64,${template}`
                                }
                                // {data?.file_url}
                                />
                            ) : (
                                <Typography
                                    mt={3}
                                    component={"h6"}
                                    align="center"
                                //   className={classes.rplyText}
                                >
                                    {t("Document not Found")}
                                </Typography>
                            )
                            }
                        </div>

                        {correspond?.bool ?
                            <LoderSimmer card count={1} size={'100'} />
                            :
                            <>
                                {val?.is_replied === true ?
                                    <>
                                        <Box sx={{ padding: '6px' }} />

                                        <MessageCard date={moment(val?.replied_at).format(
                                            "DD-MMM-YY"
                                        )} message={val?.comment} t={t} />
                                    </>
                                    : ""}
                            </>
                        }
                         {
                  val?.assets?.length > 0 &&

                  <Grid container spacing={1}>
                    <Grid item xs={12} >
                      <Typography>{t("Attachment")}</Typography>
                    </Grid>
                    {
                      val?.assets?.map((val,index) => {
                        return (
                          <Grid item xs={6} md={4} lg={3}>
                            <AssetCard
                              onClick={()=>onClickAsset(index)}
                              x={val}
                            />
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                }
                    </Box>
                ))}
            {

            }
            {location?.state?.is_replied === false ?
                <Box sx={CorrespondenceType_style?.footer}>
                    <FooterComponent
                        outlinedText={t("Reply To This")}
                        containedText={t("Mark As Read")}
                        containedonClick={handleClick}
                        outlinedonClick={handleDrawerOpen}
                        position={'absolute'}
                    />
                </Box>
                :
                ""
            }


            <DialogDrawer
                maxWidth="sm"
                open={drawer}
                onClose={handleDrawerOpen}
                height_style={
                    { height: "300px !important" }
                }

                header={t("Reply")}
                component={
                    <Box>
                        {/* {correspond?.listingDatas?.map((val, index) => { */}
                        <TextBox
                            placeholder={t("Reply here...")}
                            multiline={true}
                            value={correspond?.reply}
                            onChange={(e) => updateState("reply", e?.target?.value)}
                            error={correspond?.error?.reply}
                        />



                        <Box sx={{ padding: '8px' }} />

                    </Box>
                }
                footer={
                    <>
                        <FooterComponent
                            containedText={t("Submit")}
                            containedonClick={handleSubmit}
                            single={true}
                        />
                    </>

                }
            />

<DialogDrawer
      maxWidth="sm"
      open={selectedAsset?.bool}
      onClose={() =>onClickAsset(0)}
      header={t("Attachment")}

        component={
          <Box>
          <Slider
          height="550px"
            onClick={onClickAsset}
            assets={correspond?.listingDatas?.[0]?.assets?.map((val)=>{
              return{
                type:val?.file_meta?.type,
                src:val?.url,
                name:val?.file_meta?.name
              }
            }) ?? []}
            backgroundColor={"#000"}
            pageNumber
            padding={"0px"}
            isDownload
          />
          </Box>
        }

      />
        </Container>
    )
}