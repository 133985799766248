import React, { useState } from 'react';
import { Box } from "@mui/material";
import { PropertyCard, DialogDrawer, UseDebounce, NoData, SearchBarFilter } from "../../components";
import { Property_Style } from "./style";
import {  TransparentBuilding } from "../../assets";
import { AppRoutes } from "../../router/routes";
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyGetAllProjectsQuery, useLazyGetAllUnitCategoryQuery } from "../../redux/services/propertyListing";
import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Filter from '../../components/filter';
import { useLazyGetRequestStatusEnumQuery } from '../../redux/services/enum';
import { Bold, errorRoute } from '../../utils';
import { useSnackbar } from 'notistack';
import { ProprtySimmmer } from './componentSimmer/propertySimmer';
import useWindowDimensions from '../../useDimension';


export const Property = ({
  t = () => false
}) => {
  const debounce = UseDebounce();
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const location = useLocation();
  const [GetUnits, { isLoading , error }] = useLazyGetAllProjectsQuery();
  const [getRequestStatusEnum , { error:isError}] = useLazyGetRequestStatusEnumQuery();
  const [GetAllUnitCategory , { error:isError1}] = useLazyGetAllUnitCategoryQuery();
  const [datas, setdatas] = useState({ value: [], bool: true });
  const [searchField, setSearchField] = useState("");
  const [offSet, setOffSet] = useState(0);
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState({
    unit_category: [],
    unit_status_type: [],
  });
  const [enumValue, setEnumValue] = useState({
    unit_category: [],
    unit_status_type: [],
  });

  // listing payload
  const payloadData = {
    offset: 0,
    limit: 10,
    search: ""
  }
  // search Filter
  const onSearchChange = (e) => {
    setSearchField(e?.target?.value)
    debounce(
      async () => {
        const value = await GetUnits({ offset: 0, limit: 10, search: e?.target?.value ?? "", status: selectedFilter?.unit_status_type, unit_category: selectedFilter?.unit_category })
        setdatas({
          value: value?.data?.data,
          bool: false
        })
      },
      800
    );

  }
  // offsetData fetching
  const fetchMoreData = () => {
    const payload = {
      offset: offSet + 10,
      limit: 10,
      status: selectedFilter?.unit_status_type, 
      unit_category: selectedFilter?.unit_category,
      search: searchField ?? "",
    }
     getUnits(payload , true)
    setOffSet(offSet + 10);
  };
  // filterDrawer open
  const filterdraweropen = async () => {
    setFilterDrawer(true);
  };
  // filterDrawer close
  const filterdrawerclose = () => {
    setFilterDrawer(false);
  };
  // filter JSON Data
  const filterData = [
    {
      id: "1",
      title: t("Unit Category"),
      key: "unit_category",
      filterType: "CHECKBOX",
      values: enumValue?.unit_category,
    },

    {
      id: "2",
      title: t("Status"),
      key: "unit_status_type",
      filterType: "CHECKBOX",
      values: enumValue?.unit_status_type,
    },
  ];

  const reqTypeClient = async (data) => {
    let property_unit = await getRequestStatusEnum(["unit_status_type"])

    const unitCategory = await GetAllUnitCategory()

    const tempStatus = unitCategory?.data?.data?.map((value) => {
      return {
        label: value?.name,
        value: value?.id
      }
    })

    const tempStatus1 = tempStatus?.length > 0 ? tempStatus?.map((value) => {
      return value?.value
    }) : []


    const tempFilterUnit = property_unit?.data?.data?.unit_status_type?.concat([{label:"Purchased",value:"Purchased"}])?.filter((x) => ["Vacant", "Occupied" , "Purchased"].includes(x?.label) && x)?.map((value) => {
      return value?.value
    })

    await getUnits({ offSet: 0, limit: 10, status: location?.state ? [location?.state] : tempFilterUnit, unit_category: tempStatus1 })

    setSelectedFilter({
      ...selectedFilter,
      unit_status_type: location?.state ? [location?.state] : tempFilterUnit,
      unit_category: tempStatus1

    })
    setEnumValue({
      ...enumValue,
      unit_status_type: property_unit?.data?.data?.unit_status_type?.concat([{value:"Purchased",label:"Purchased"}])?.filter((x) => ["Vacant", "Occupied","Purchased"].includes(x?.label) && x),
      unit_category: tempStatus
    })
  }
  useEffect(() => {
    reqTypeClient(payloadData)
    // eslint-disable-next-line
  }, [])
  const applyFilter = async (data) => {
    setFilterDrawer(false);

    const payload = {
      offset: 0,
      limit: 10,
      status: data?.unit_status_type,
      unit_category: data?.unit_category,
      search: searchField ?? "",
    }
    await getUnits(payload)
    setSelectedFilter({
      ...selectedFilter,
      unit_category: data?.unit_category,
      unit_status_type: data?.unit_status_type,
    })
    setOffSet(0)
  }
  const getUnits = async (data , filter) => {
    let data_value;
    if((data?.status?.includes("Vacant") && data?.status?.includes("Purchased")) && !data?.status?.includes("Occupied")){
      data_value={
        ...data,
        status:["Vacant","Occupied"],
        status1:data?.status
      }
    }
    else{
      data_value={
        ...data,
        status1:data?.status
      }
    }

    const value = await GetUnits({...data_value , status:data_value?.status?.length === 3 ? undefined : data_value?.status})

    
    if(((!data_value?.status1?.includes("Purchased") && !data_value?.status1?.includes("Vacant"))) ||  !data_value?.status1?.includes("Purchased")){
      return setdatas({
        value: filter ? datas?.value.concat(value?.data?.data?.filter((x)=>x?.is_purchased !== true)):value?.data?.data?.filter((x)=>x?.is_purchased !== true),
        bool: false
      })

    }
    setdatas({
      value: filter ? datas?.value.concat(value?.data?.data) :value?.data?.data,
      bool: false
    })
    if (!value?.isSuccess) {
      enqueueSnackbar(t(`Some Thing Went Wrong`), {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
        className: { fontFamily: Bold }

      });
    }
  }

  const size = useWindowDimensions();


  React.useEffect(()=>{
    if(error?.status === 423|| isError?.status === 423 || isError1?.status === 423) return errorRoute() 
 },[error , isError , isError1])
  return (
    <>
      <Box sx={{ padding: "24px 16px 0px 16px" }}>
        <SearchBarFilter  placeholder={t('Search Unit Number')}
                        filterIcon
                        value={searchField ?? ""}
                        handleChange={(e) => onSearchChange(e)}
                        handleFilterOpen={filterdraweropen}/>
        <Box height={'8px'} />
        {datas?.bool ?
          <Box>
            <ProprtySimmmer />
          </Box>
          :
            datas?.value?.length > 0 ?
              <InfiniteScroll
                dataLength={datas?.value?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 215}

              >
                {datas?.value?.map((x, index) => {
                  return (
                    <Box key={index}>
                      <Box onClick={() =>
                        navigate(AppRoutes.propertyUnitNew, { state: { id: x?.id, status: x?.status, property_name: x?.property_name, logo: x?.logo, area: x?.area , is_purchased:x?.is_purchased , total_area:x?.total_area , uom_id:x?.uom_id, unit_name: x?.unit_name} })}
                        sx={Property_Style()?.propertyCardBox}>
                        <PropertyCard
                          image={<TransparentBuilding />}
                          plotname={x?.property_name}
                          address={`${x?.area} ${x?.city ? "," : ""} ${x?.city}`}
                          title={x?.unit_name}
                          id={x?.unit_no}
                          tagline={x?.is_purchased ? "Purchased" : x?.status}
                          logo={x?.logo ? x?.logo : "/images/propertyAvatar.svg"}
                          hidden={datas?.value?.length === index + 1 ? false : true}
                        />
                      </Box>
                    </Box>
                  );

                })
                }
              </InfiniteScroll>
              : <NoData
                style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
            
        }
        {/* filter Drawer */}
        <DialogDrawer
          maxWidth="sm"
          open={filterDrawer}
          header={t("Filter By")}
          padding="0px"
          component={
            <Box>
              <Filter
                t={t}
                filterDataJson={filterData}
                selectedList={filterData[0]}
                onApply={applyFilter}
                filters={{
                  unit_category: selectedFilter?.unit_category,
                  unit_status_type: selectedFilter?.unit_status_type,
                }}
                defaultData={selectedFilter}
                onClose={() => filterdrawerclose()}
                btnLoading={isLoading}
              />
            </Box>
          }
          onClose={() => filterdrawerclose()}
        />
      </Box>
    </>
  );
};
