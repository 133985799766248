import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CallerIcon from "../../assets/callerIcon";
import CloseIcon from "../../assets/closeIcon";
import LocationIcon from "../../assets/locationIcon";
import MailIcon from "../../assets/mailIcon";
import SignOut from "../../assets/signOut";
// import AboutCompany from "../../assets/aboutCompany";
import { CurrencySwitcher, DialogDrawer, FooterComponent, ProfileDetailsCard, ProfileListCard, ProfilePicture, TopNavBar } from "../../components";
import { AppRoutes } from "../../router/routes";
import { ContactUs } from "./contactUs";
import { ProfileViewStyle } from './style'
import { Bold, RetainLocalStorage, data, DrawerData, errorRoute, LocalStorageKeys, SemiBold } from '../../utils'
import { useLazyGetPolicyQuery, useLazyCurrencyQuery, useLazyGetUsersProfileQuery, useLazyLogoutQuery } from "../../redux/services";
import { useSnackbar } from "notistack";
import { ProfileCardsimmer } from "./componentsSimmer/profileViewCardSimmer";
import { ProfilePicturesimmer } from "./componentsSimmer/profilePictureSimmer";
import { ProfileNamesimmer } from "./componentsSimmer/profileNameSimmer";
import { config } from "../../config";
import { TermsCondition } from "./assets/condition";
import { TermOfService } from "./assets/service";
import { PrivacyPolicy } from "./assets/privacy";
import { CookiePolicy } from "./assets/cookie";
import { ChevronRight } from "@mui/icons-material";

export const ProfileView = ({
    t = () => false,
    ...props
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const [GetUsersProfile , {error}] = useLazyGetUsersProfileQuery();
    const [LogoutAPI , {error:isError}] = useLazyLogoutQuery();
    const [GetPolicy, {error:isError1}] = useLazyGetPolicyQuery();

    const [is_terms_policies_open, set_is_terms_policies_open] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [logOutDrawer, setLogoutDrawer] = React.useState(false);
    const [currencyDrawer, setCurrencyDrawer] = React.useState(false);
    const [draw, setDraw] = React.useState(false);
    const [offSet, setOffSet] = useState(0);
    const [profile, setProfile] = useState({
        profileListing: {},
        bool: true,
        mobile_no: "",
        alternative_mobile_no: "",
        address: ""
    })
    // const currency = localStorage.getItem(LocalStorageKeys?.currency) ? JSON.parse(localStorage.getItem(LocalStorageKeys?.currency))?.symbol : ''
const [currecy_data , setCurrencyData] = React.useState([])
    const [Currency] = useLazyCurrencyQuery()
    const handleDrawerClose = () => {
        setDrawer(false);
    };

    const handleDrawerOpen = () => {
        setDrawer(!drawer);
    };

    // const urlRoute = (url) =>{

    // }

    // log out drawerClose
    const handlelogoutDrawerClose = () => {
        setLogoutDrawer(false);
    };
    // log out draweropen
    const handlelogoutDrawer = () => {
        setLogoutDrawer(!logOutDrawer);
    };
    // currency drawerClose
    // const handlecurrencyDrawerclose = () => {
    //     setCurrencyDrawer(false);
    // };
    // currency draweropen

    // eslint-disable-next-line
    const handlecurrencyDrawer = async () => {
        setDraw(!draw)
        setOffSet(0)
        let currencyfunc = await Currency({
            offset:0,
            limit:10
        })
        setCurrencyData(currencyfunc?.data?.data?.data)
        setCurrencyDrawer({
            data: currencyfunc?.data?.data?.data,
            bool: !currencyDrawer?.bool,
        });

    }



    const updateCurrency = (data) => {
        localStorage.setItem(LocalStorageKeys?.currency, JSON.stringify(data))
        setCurrencyDrawer({
            ...currencyDrawer,
            bool: !currencyDrawer?.bool
        })

    }
    // Get Profile List
    const getProfileList = async (payload) => {
        const data = await GetUsersProfile(payload)
        setProfile({
            profileListing: data?.data?.data[0],

            mobile_no: `${data?.data?.data[0]?.mobile_no_country_code && data?.data?.data[0]?.mobile_no ? `${data?.data?.data[0]?.mobile_no_country_code} ${data?.data?.data[0]?.mobile_no}` : ""}`,

            alternative_mobile_no: `${data?.data?.data[0]?.alternative_mobile_no_country_code && data?.data?.data[0]?.alternative_mobile_no ? `${data?.data?.data[0]?.alternative_mobile_no_country_code} ${data?.data?.data[0]?.alternative_mobile_no}` : ""}`,

            address: `${data?.data?.data[0]?.street_1 ?? ""} ${data?.data?.data[0]?.street_2 ? "," : ""} ${data?.data?.data[0]?.street_2 ? data?.data?.data[0]?.street_2 : ""} ${data?.data?.data[0]?.street_3 ? "," : ""} ${data?.data?.data[0]?.street_3 ? data?.data?.data[0]?.street_3 : ""} ${data?.data?.data[0]?.district ? "," : ""} ${data?.data?.data[0]?.district ? data?.data?.data[0]?.district : ""} ${data?.data?.data[0]?.state ? "," : ""} ${data?.data?.data[0]?.state ? data?.data?.data[0]?.state : ""} ${data?.data?.data[0]?.country ? "," : ""} ${data?.data?.data[0]?.country ? data?.data?.data[0]?.country : ""} ${data?.data?.data[0]?.zipcode ? "," : ""} ${data?.data?.data[0]?.zipcode ? data?.data?.data[0]?.zipcode : ""}`,

            bool: false
        })
        if (!data?.isSuccess) {
            enqueueSnackbar(`Some Thing Went Wrong`, {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: Bold }

            });
        }
    }

    const Logout = async (payload) => {
        await LogoutAPI(payload)
    }

    const handleLogoutFn = async () => {
        if(localStorage.getItem(LocalStorageKeys.deviceToken)){
            await Logout({
                app_build: config?.xbuildCode,
                token: localStorage.getItem(LocalStorageKeys.deviceToken)
            })
        }
        navigate(AppRoutes.welcome)
        RetainLocalStorage();
    }
    const [aboutCompany , setAboutCompany]= React.useState(null)
    const [terms_policies_viewer_state, set_terms_policies_viewer_state] = React.useState({
      title: "",
      content: "",
    });
    const [is_terms_policies_viewer_open, set_is_terms_policies_viewer_open] = React.useState(false);
  
    const openLink = (url) => {
      window.open(url, "_blank", "noreferrer")
    };
    const handleTermsPolicies = () => {
        set_is_terms_policies_open(!is_terms_policies_open);
      };
  
    const getTermsPolicies = async () => {
        const data = await GetPolicy()
        if(data?.isSuccess){
            let _ = data?.data?.data;
            setAboutCompany({
              title: _?.company_name ? `${t("About")} (${_?.company_name})` : `${t("about")}`,
              website: _?.company_website ? `http://${_?.company_website}` : null,
              about_us_url: _?.about_us_url ? _?.about_us_url : null,
              ..._,
              sub_menus: [
                 {
                  icon: (<TermsCondition />),
                  title: t("terms & conditions"),
                  sub_title: t("Propgoto"),
                  content: _?.terms_conditions,
                  type:"terms_condition"
                },
                 {
                  icon: (<TermOfService />),
                  title: t("term of service"),
                  sub_title: "Property Automate",
                  content: _?.term_of_service,
                  type:"term_of_service"
                },
                 {
                  icon: (<PrivacyPolicy />),
                  title: t("privacy policy"),
                  sub_title: "Property Automate",
                  content: _?.privacy_policy,
                  type:"privacy_policy"
                },
                 {
                  icon: (<CookiePolicy />),
                  title: t("cookie policy"),
                  sub_title: "Property Automate",
                  content: _?.cookie_policy,
                  type:"cookie_policy"
                },
              ]
            })
        }
       
    };

    const fetchMoreData = async()=>{
        let currencyfunc = await Currency({
           offset:offSet + 10,
           limit:10 
        })
        setCurrencyData([...currecy_data , ...currencyfunc?.data?.data?.data])
        setCurrencyDrawer({
            data: [...currencyDrawer?.data , ...currencyfunc?.data?.data?.data],
            ...currencyDrawer,
        });
        setOffSet(offSet + 10);
    }

    const searchFunction = async(v)=>{
        let currencyfunc = await Currency({
           offset:0,
           limit:10 ,
           search:v
        })
        setCurrencyData(currencyfunc?.data?.data?.data)
        setCurrencyDrawer({
            data: currencyfunc?.data?.data?.data,
            ...currencyDrawer,
        });
    }

    React.useEffect(() => {
        getTermsPolicies()
        // handlecurrencyDrawer()
        getProfileList()
        // eslint-disable-next-line
    }, [])


    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423 || isError1?.status === 423) return errorRoute() 
     },[error , isError , isError1])
    return (
        <Container
        sx={{padding:"0px !important"}}
            maxWidth="sm"
        >
<Box sx={{
        paddingTop: '3.5rem', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '4rem', // Media query style for `md` breakpoint
        },
      }}>
<Box sx={{position:"fixed" , top:0 , left:0 , right:0 ,zIndex:999}}>
            <Container maxWidth="sm" sx={{padding:"0px !important"}}>
            <TopNavBar title={t("Profile")} isDirect route={() => navigate(AppRoutes.home)} t={t} />
            </Container>
         </Box>


            <Grid sx={ProfileViewStyle?.root}>
                {/* Top Section */}
                <Box sx={ProfileViewStyle?.topSection}>
                    <Grid container alignItems={"center"} justifyContent="space-between">
                        {/* profilePicture_Card */}

                        {profile?.profileListing &&
                            <>
                                {profile?.bool ?
                                    <ProfilePicturesimmer />
                                    :
                                    <Grid item xs={5} sm={4}>
                                        <ProfilePicture
                                            t={t}
                                            onClick={() => navigate(AppRoutes.profileEdit, { state: profile?.profileListing })}
                                            profilePicture={profile?.profileListing?.image_url ? profile?.profileListing?.image_url : "/images/profileAvatar.svg"}
                                        />
                                    </Grid>
                                }
                            </>
                        }
                        {/* profileDetails_List */}
                        {profile?.profileListing &&
                            <>
                                {profile?.bool ? <ProfileNamesimmer /> :
                                    <Grid item xs={6} sm={7}>
                                        <Stack>
                                            <Typography sx={ProfileViewStyle?.title} noWrap className="owner_name">
                                                {profile?.profileListing?.first_name ? profile?.profileListing?.first_name : "-"}</Typography>

                                            <Stack direction={"row"} >
                                                <Typography noWrap sx={ProfileViewStyle?.ownerTag}>{t("Owner")}</Typography>
                                                {
                                                    profile?.profileListing?.client_role_no &&    <Typography noWrap sx={ProfileViewStyle?.ownerIdTag}>{profile?.profileListing?.client_role_no ? profile?.profileListing?.client_role_no : "-"}</Typography>

                                                }
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                }
                            </>
                        }

                    </Grid>
                </Box>

                {/* Second Section */}
                <Box sx={ProfileViewStyle?.secondSection}>
                    {/* DetailsCard */}
                    <Grid container spacing={2}>
                        {/* Contact Number */}

                        <Grid item xs={6}>
                            {profile?.profileListing &&
                                <>
                                    {profile?.bool ?
                                        <ProfileCardsimmer />
                                        :
                                        <ProfileDetailsCard
                                            title={profile?.mobile_no}
                                            subTitle={profile?.alternative_mobile_no}
                                            icons={<CallerIcon />}
                                            imageUrl="/images/PhoneIcons.svg"

                                        />
                                    }
                                </>
                            }
                        </Grid>

                        {/* Mail Contact */}
                        <Grid item xs={6}>
                            {profile?.profileListing &&
                                <>
                                    {profile?.bool ?
                                        <ProfileCardsimmer />
                                        :
                                        <ProfileDetailsCard
                                            title={profile?.profileListing?.email_id ? profile?.profileListing?.email_id : "-"}
                                            icons={<MailIcon />}
                                            imageUrl="/images/maillBackgroundIcon.svg"
                                        />
                                    }
                                </>
                            }
                        </Grid>
                        {/* Address Contact */}
                        <Grid item xs={12}>
                            {profile?.address &&
                                <>
                                    {profile?.bool ?
                                        <ProfileCardsimmer />
                                        :
                                        <ProfileDetailsCard
                                            title={profile?.address}
                                            // divider={true}
                                            icons={<LocationIcon />}
                                            imageUrl="/images/profile_location.svg"
                                        />
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                    {/* cardList */}
                    <Box sx={ProfileViewStyle?.cardList} >
                        {data(t, handleDrawerOpen , {
                            company:aboutCompany?.title,
                            onClick:()=>!!aboutCompany?.website ? openLink(aboutCompany?.website):false,
                            onClick1:()=>handleTermsPolicies(),
                            onClick2:()=>!!aboutCompany?.about_us_url ? openLink(aboutCompany?.about_us_url):false,

                        })?.map((item, index) => {
                            return (
                                <Box key={index}>
                                    <ProfileListCard
                                        title={item?.title}
                                        icons={item?.icons}
                                        onClick={item?.onClick}
                                        box_styles={{ borderRadius: "0px !important", backgroundColor: "none !important" }}
                                        type={item?.type}
                                    />
                                </Box>
                            )
                        })}

                    </Box>

                    {/* currency */}
                    {/* <Box>
                        <ProfileListCard
                            title={t("Currency")}
                            type={true}
                            icons={<AboutCompany />}
                            onClick={() => handlecurrencyDrawer()}
                            currency={currency ? `(${currency})` : ""}
                        />
                    </Box> */}
                    <Box sx={{ paddingBottom: '14px' }} />
                    {/* sign_out_card */}
                    <Box >
                        <ProfileListCard
                            title={t("Logout")}
                            icons={<SignOut />}
                            onClick={() => handlelogoutDrawer()}
                            type={true}
                        />
                    </Box>

                </Box>

                {/* contact us drawer */}
                <DialogDrawer
                    maxWidth="sm"
                    open={drawer}
                    isnotTitle
                    height_style={
                        { height: "425px !important" }
                    }
                    component={
                        <Box>
                            {/* Drawer Head */}
                            <Box sx={ProfileViewStyle?.drawerHeaderBox}>
                                <Box sx={ProfileViewStyle?.drawerHeader}>
                                    <Box sx={ProfileViewStyle?.drawerHeadTextBox}>
                                        <Typography sx={ProfileViewStyle?.drawerTitle}>{t("We are here to help you")}</Typography>
                                        <Typography sx={ProfileViewStyle?.itle}>{t("Reach us for any queries")}</Typography>
                                    </Box>
                                    <Box sx={ProfileViewStyle?.drawerHeadCloseIcon}>
                                        <CloseIcon onClick={handleDrawerClose} />
                                    </Box>

                                </Box>
                                {/* Drawer Content */}
                                <Box>
                                    {DrawerData(t)?.map((item, index) => {
                                        return (
                                            <Box key={index}>
                                                <ContactUs
                                                    icon={item?.icons}
                                                    title={item?.title}
                                                    subTitle={item?.subTitle}
                                                    btnText={item?.btnText}
                                                    onclick={item?.onclick}
                                                    number={item?.number}
                                                    call={item?.call}
                                                    onClick={item?.onClick}
                                                />
                                            </Box>

                                        )
                                    })}

                                </Box>
                            </Box>



                        </Box>
                    }
                    onClose={() => setDrawer(!drawer)}
                />
                {/* currency drawer */}
                <DialogDrawer
                    maxWidth="sm"
                    open={draw}
                    header="Currency"
                    padding={"0px 16px 16px 16px"}
                    component={
                        <CurrencySwitcher
                            selectedCurrency=
                            {JSON.parse(localStorage.getItem(LocalStorageKeys?.currency))}
                            updateCurrency={updateCurrency}
                            t={t}
                            Currency={currecy_data}
                            fetchMoreData={fetchMoreData}
                            searchFunction={searchFunction}
                        />
                    }
                    // onClose={() => setLogoutDrawer(!logOutDrawer)}
                    onClose={() => setDraw(false)}

                />
                {/* log out drawer */}
                <DialogDrawer
                    maxWidth="sm"
                    open={logOutDrawer}
                    header={t("Confirmation")}
                    height_style={
                        { height: "50px !important" }
                    }
                    component={
                        <Box>
                            <Typography sx={ProfileViewStyle?.logOutConfirmation}>
                                {t("Are you sure to logout?")}
                            </Typography>
                        </Box>
                    }
                    footer={
                        <>
                            <FooterComponent
                                border={false}
                                outlinedText={t("Cancel")}
                                containedText={t("Logout")}
                                containedonClick={handleLogoutFn}
                                outlinedonClick={() => handlelogoutDrawerClose()}

                            />
                        </>
                    }
                    // onClose={() => setLogoutDrawer(!logOutDrawer)}
                    onClose={() => handlelogoutDrawerClose()}
                />

<DialogDrawer
                height={"400px"}
                open={is_terms_policies_open}
                onClose={() => set_is_terms_policies_open(false)}
                header={t("terms & policies")}
                padding={"16px"}
                header_text_transform={"capitalize"}
                component={
                  <Stack direction={"column"} spacing={"12px"}>
                    {aboutCompany?.sub_menus?.map((key) => {
                      return (
                        key?.content &&
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                          sx={{
                            cursor: "pointer",
                            border: "1px solid #E4E8EE",
                            borderRadius: "8px",
                            padding: "16px",
                          }}
                          onClick={()=>{
                            set_terms_policies_viewer_state({
                              title: key?.title,
                              content: key?.content,
                            });
                            set_is_terms_policies_viewer_open(true);
                            set_is_terms_policies_open(false);
                          }}>
                          <Stack spacing={"12px"} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            {key?.icon}
                            <Stack direction={"column"} spacing={"5px"}>
                              <Typography
                                sx={{
                                  fontFamily: Bold,
                                  fontSize: "0.875rem",
                                  color: "#091B29",
                                  textTransform: "capitalize",
                                }}>
                                {key?.title}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: SemiBold,
                                  fontSize: "0.75rem",
                                  color: "#4E5A6B",
                                  textTransform: "capitalize",
                                }}>
                                {key?.sub_title}
                              </Typography>
                            </Stack>
                          </Stack>
                          <ChevronRight htmlColor="#98A0AC" />
                        </Stack>
                      )
                    })}
                  </Stack>
                } />


<DialogDrawer
                height={"100%"}
                open={is_terms_policies_viewer_open}
                onClose={() => {
                  set_is_terms_policies_open(true);
                  set_is_terms_policies_viewer_open(false);
                }}
                header={t(terms_policies_viewer_state?.title)}
                padding={"16px"}
                header_text_transform={"capitalize"}
                maxWidth={"sm"}
                component={
                  <div dangerouslySetInnerHTML={{ __html: terms_policies_viewer_state?.content }} />
                } />
            </Grid></Box>        
        </Container>

    );
};
