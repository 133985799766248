import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { NewTabsComponent, NoData, TopNavBar } from "../../components";
import { NewAnnouncementCard } from "../../components/announcement/components/newAnnouncementCard";
import { config } from "../../config";
import { useLazyGetAnnouncementListingQuery } from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { errorRoute, LocalStorageKeys, NetworkCall, NetWorkCallMethods } from "../../utils";
import { ActivityCardsimmer } from "../statistics/component/activityCardsimmer";
import { AnnouncementStyle } from "./style";

export const Announcement = ({
    t = () => false,
}) => {
    const navigate = useNavigate();
    const { state } = useLocation()
    const [selectedTab, setSelectedTab] = useState({
        index: 0,
        value: ["Announcement", "Push-Notification"],
    });
    const accessType = JSON.parse(localStorage.getItem(LocalStorageKeys?.accessType));
    const [GetAnnouncementListing, { error }] = useLazyGetAnnouncementListingQuery();
    const [offSet, setOffSet] = useState(0);
    const [datas, setDatas] = useState({
        announcementListing: [],
        bool: true,
    })


    const getAnnouncementList = async (payload, isFilter) => {
        const data = await GetAnnouncementListing(payload)
        setDatas({
            ...datas,
            announcementListing: isFilter ?
                datas?.announcementListing.concat(data?.data?.data?.response) :
                data?.data?.data?.response,
            bool: false
        })
    }

    const fetchMoreData = () => {
        getAnnouncementList({
            offset: offSet + 10,
            limit: 10,
            type: selectedTab?.value,
            company_id: accessType?.map((x, index) => {
                return (
                    x?.company_id
                )
            }),
            to_builds: [config.xbuildCode],
            is_draft: false
        }, true)
        setOffSet(offSet + 10);
    }
    const handleBack = () => {
        navigate(AppRoutes?.home)
    }

    useEffect(() => {
        if (state?.tabValue) {
            setSelectedTab({ ...selectedTab, index: state?.tabValue })
        }
        getAnnouncementList({
            type: state?.tabValue ? ["Announcement"] : selectedTab?.value,
            limit: 10,
            offset: 0,
            company_id: accessType?.map((x, index) => {
                return (
                    x?.company_id
                )
            }),
            to_builds: [config.xbuildCode],
            is_draft: false,
        })
        // eslint-disable-next-line
    }, [selectedTab?.value])


    useEffect(() => {
        if (error?.status === 423) return errorRoute()
    }, [error])

    const viewNotification = (data) => {
        if (!data?.is_read) {
            const payload = {
                id: data?.id,
                update: {
                    is_read: true,
                },
            }
            NetworkCall(
                `${config.api_url}queries/notification/update`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                navigate(`${data?.redirect_url}`, { state: data?.id })
            })
                .catch((error) => {
                    console.log(error)
                });
        } else {
            navigate(`${data?.redirect_url}`, { state: data?.id })
        }
    };

    return (
        <Container
            sx={{ padding: "0px !important" }}
            maxWidth="sm"
        >
            <Box sx={{
                paddingTop: '6.6rem', // Default style
                '@media screen and (min-width: 600px)': {
                    paddingTop: '6.7rem', // Media query style for `md` breakpoint
                },
            }}>
                <Box sx={AnnouncementStyle?.TopNavBar}>
                    <Container maxWidth="sm" sx={{ padding: "0px !important" }}
                    >
                        <TopNavBar title={t("Announcement")} isDirect route={handleBack} t={t} />
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#F6F6F6", padding: "0px 16px", overflow: "auto" }}>

                            <NewTabsComponent
                                tab1Label={t("All")}
                                tab2Label={t("Announcement")}
                                tab3Label={t("Notifications")}
                                selectedTab={selectedTab?.index}
                                onTab1Clicked={() => setSelectedTab({
                                    index: 0,
                                    value: ["Announcement", "Push-Notification"]
                                })}
                                onTab2Clicked={() => setSelectedTab({ index: 1, value: ["Announcement"] })}
                                onTab3Clicked={() => setSelectedTab({ index: 2, value: ["Push-Notification"] })}
                            />
                        </Box>
                    </Container>
                </Box>

                <Box sx={AnnouncementStyle?.root}>
                    {selectedTab?.index === 0 &&
                        <>
                            {datas.bool ?
                                <ActivityCardsimmer count={10} />
                                :
                                datas?.announcementListing?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={datas?.announcementListing?.length ?? 0}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        style={AnnouncementStyle?.infiniteScroll}
                                        height={`calc(100vh - 118px)`}
                                    >
                                        {datas?.announcementListing?.map((item, index) => {
                                            return (
                                                <Box sx={AnnouncementStyle?.AnnouncementCardBox}>
                                                    {/* <AnnouncementCard
                                                        title={item?.title}
                                                        tag={item?.category}
                                                        subTitle={item?.triggered_at}
                                                        route={item?.content_type === "Announcement" ? () => navigate(AppRoutes.announcementView, { state: item?.id }) : false}
                                                        icons={
                                                            item?.content_type === "Announcement" ?
                                                                item?.description?.[0]?.images ?
                                                                    generateImageUrl(item?.description?.[0]?.images,
                                                                        img_size.small_square)
                                                                    : <NotificationLogo />
                                                                : <NotificationLogo />
                                                        }
                                                    /> */}
                                                    <NewAnnouncementCard
                                                        data={item}
                                                        isCommunity={false}
                                                        isAll={true}
                                                        viewNotification={viewNotification}
                                                    />
                                                </Box>
                                            )
                                        })}

                                    </InfiniteScroll>
                                    :
                                    <NoData />
                            }
                        </>
                    }
                    {selectedTab?.index === 1 && <Typography>
                        {datas?.announcementListing?.length > 0 ?
                            <InfiniteScroll
                                dataLength={datas?.announcementListing?.length ?? 0}
                                next={fetchMoreData}
                                hasMore={true}
                                style={AnnouncementStyle?.infiniteScroll}
                                height={`calc(100vh - 135px)`}

                            >
                                {datas?.announcementListing?.map((item, index) => {
                                    return (
                                        <Box sx={AnnouncementStyle?.AnnouncementCardBox} mt={2}>
                                            {/* <AnnouncementCard
                                            title={item?.title}
                                            tag={item?.category}
                                            subTitle={item?.triggered_at}
                                            route={item?.content_type === "Announcement" ? () => navigate(AppRoutes.announcementView, { state: item?.id }) : false}
                                            icons={
                                                item?.content_type === "Announcement" ?
                                                    item?.description?.[0]?.images ?
                                                        generateImageUrl(item?.description?.[0]?.images,
                                                            img_size.small_square)
                                                        : <NotificationLogo />
                                                    : <NotificationLogo />
                                            }

                                        /> */}
                                            <NewAnnouncementCard
                                                data={item}
                                                isAll={true}
                                                viewNotification={viewNotification}
                                            />
                                        </Box>
                                    )
                                })}

                            </InfiniteScroll>
                            :
                            <NoData />
                        }
                    </Typography>}
                    {selectedTab?.index === 2 &&
                        <Typography>
                            {datas?.announcementListing?.length > 0 ?
                                <InfiniteScroll
                                    dataLength={datas?.announcementListing?.length ?? 0}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    style={AnnouncementStyle?.infiniteScroll}
                                    height={`calc(100vh - 135px)`}
                                >
                                    {datas?.announcementListing?.map((item, index) => {
                                        return (
                                            <Box sx={AnnouncementStyle?.AnnouncementCardBox}>
                                                {/* <AnnouncementCard
                                                    title={item?.title}
                                                    icons={<NotificationLogo />}
                                                    tag={item?.category}
                                                    subTitle={item?.triggered_at}
                                                    route={item?.content_type === "Announcement" ? () =>
                                                        navigate(AppRoutes.announcementView, { state: item?.id }) : false}
                                                /> */}
                                                <NewAnnouncementCard
                                                    data={item}
                                                    isAll={true}
                                                    viewNotification={viewNotification}
                                                />
                                            </Box>
                                        )
                                    })}

                                </InfiniteScroll>
                                :
                                <NoData />
                            }
                        </Typography>}
                </Box>
            </Box>

        </Container>

    );
};
