import { Bold, Regular } from "../../utils";
export const AppartmentCard_Style = {

  sx: {
    backgroundColor: 'primary.contrastText',
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "12px",
    padding: "16px",
  },
  title: {
    textAlign: "left",
    fontFamily: Bold,
    fontSize: "0.875rem",
  },
  idBg: {
    width: "auto",
    height: "20px",
    backgroundColor: "backgroundcard.dark",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginInlineStart: "6px",
    padding: "2px"
  },
  id: {
    textAlign: "left",
    fontFamily: Bold,
    fontSize: "0.75rem",
    color: "primary.main",
  },
  plotName: {
    textAlign: "left",
    fontFamily: Regular,
    fontSize: "0.75rem",
    color: 'text?.light',
    marginInlineStart: '4px',
  },
  dot: {
    padding: "3px",
    backgroundColor: "backgroundcard.light",
    borderRadius: "50%",
    margin: '0px 4px'
  },
  address: {
    textAlign: "left",
    fontFamily: Regular,
    fontSize: "0.75rem",
    color: 'text?.light',
  },
  topLine: {
    display: "flex",
    alignItems: 'center'
  },
  bottomLine: {
    display: "flex",
    marginTop: "8px",
    alignItems: "center",
    flexWrap: 'wrap'
  },
  btn: {
    marginTop: "8px",
  },
  viewBtn: {
    fontFamily: "NotoSans-SemiBold !important",
    fontSize: "0.875rem !important",
  }
};
