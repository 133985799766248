import { Box, Typography } from "@mui/material"
import { GreenCheck } from "../../assets"
import { CorrespondenceCard_style } from './style'
export const CorrespondenceCard = ({
    type = "",
    status = "",
    content = "",
    postedDate = "",
    deadline = "",
    t = () => false,
    bgColor = {},
    replied = false,
    handleRoute = () => false
}) => {
    return (
        <Box sx={CorrespondenceCard_style?.card} onClick={() => { handleRoute() }}>
            <Box sx={CorrespondenceCard_style?.topSec}>
                <Box sx={CorrespondenceCard_style?.typeBg}><Typography sx={CorrespondenceCard_style?.typeTxt}>{type}</Typography></Box>
                <Box sx={{ ...CorrespondenceCard_style?.statusBg, ...bgColor }}><Typography sx={CorrespondenceCard_style?.statusTxt}>{status}</Typography></Box>
            </Box>
            <Typography sx={CorrespondenceCard_style?.content}>{content}</Typography>
            <Box sx={CorrespondenceCard_style?.bottomSec}>
                <Typography sx={CorrespondenceCard_style?.belowTxt} noWrap>{t("Posted on")} {postedDate}</Typography>
                <Box sx={CorrespondenceCard_style?.dot}></Box>
                <Typography sx={CorrespondenceCard_style?.belowTxt} noWrap>{t('Deadline')} {deadline}</Typography>
                {replied === true &&
                    <Box sx={CorrespondenceCard_style?.bottomSec}>
                        <Box sx={CorrespondenceCard_style?.dot}></Box>
                        <GreenCheck />
                        <Typography sx={CorrespondenceCard_style?.Replied}>{t('Replied')}</Typography>
                    </Box>}
            </Box>
        </Box>
    )
}