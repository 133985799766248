import React from "react";
import { withTranslation } from "react-i18next";
import { Vehicle } from "./vehicle";

class VehicleParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <Vehicle {...this?.props} />;
    }
}

export default withTranslation('Vehicle')(VehicleParent);