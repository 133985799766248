import { NewTabsComponent } from "../../components";
import { Spending } from "./spending";
import { Revenue } from "./revenue";
import {  Investment} from "./investment"
import { useState } from "react";
import { Box, Container } from "@mui/material";
import { InvestmentImage, SpendingImage ,RevenueImage} from "../../assets";
import { Statistcs_style } from "./style";
import { useLocation } from "react-router-dom";

export const Statistic = ({t = () => false}) => {
  const location =useLocation()
  const tabcount = location?.state
  const [selectedTab, setSelectedTab] = useState(tabcount ?? 0);


  return (
    <Box>
      <Box >
          <Container maxWidth="sm" sx={Statistcs_style?.sx} style={{ padding: '0px !important' , position:"fixed" , left:0,right:0 , zIndex:1}}>
            <div style={{paddingTop: "4px"}}>
            <NewTabsComponent
                      tab1Label={t("Revenue")}
                      tab2Label={t("Spending")}
                      tab3Label={t("Investment")}
                      selectedTab={selectedTab}
                      onTab1Clicked={() => setSelectedTab(0)}
                      onTab2Clicked={() => setSelectedTab(1)}
                      onTab3Clicked={() => setSelectedTab(2)}
                      tabIcon1={<RevenueImage />}
                      tabIcon2={<SpendingImage />}
                      tabIcon3={<InvestmentImage />}
        

            />
            </div>
          </Container>
      </Box>
      <Container maxWidth="sm" sx={{ padding: '0px !important' }}>
      <Box sx={{...Statistcs_style.selectedTab }} >
          {selectedTab === 0 && <Revenue t={t} height={`auto`}/>}
        {selectedTab === 1 && <Spending t={t} />}
        {selectedTab === 2 && <Investment t={t}/>}
      </Box>
    </Container>
    </Box>
  );
};