import { Bold, Regular, SemiBold } from "../../utils";


export const MaintanceRequestStyle = {
  root: {
    padding: "16px 16px 0px 16px",
    width: '100%',
    height: '100%',
    overflow: "auto",
    backgroundColor: "background.main",
    "&::-webkit-scrollbar": {
      display: "none",
    }
  },
  container: {
    backgroundColor: "background.Teritary",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  footer: {
    position: 'fixed',
    width: "100%",
    bottom: "0",
    left:0,
    right:0

  },
  contact: {
    fontSize: "1rem",
    color: "text.primary",
    fontFamily: Bold,
  },
  change: {
    fontSize: "1rem",
    color: "text.main",
    fontFamily: Bold,
    cursor: "pointer",
  },
  contactDetailsBox: {
    borderRadius: "4px",
    backgroundColor: "primary.contrastText",
  },
  requsettop: {
    backgroundColor: "background.Tertiary2",
    padding: "4px",
    borderRadius: "5px",
    fontSize: "0.75rem",
    fontFamily: Bold,
  },
  requsettopsub: {
    backgroundColor: "background.Tertiary2",
    padding: "4px",
    borderRadius: "5px",
    fontSize: "0.75rem",
    fontFamily: Bold,
    marginInlineStart: "16px"
  },
  requsettitle: {
    fontSize: "1rem",
    fontFamily: Bold,
  },
  requsetsub: {
    fontSize: "0.75rem",
    color: "text.Tertiary",
    marginTop: "8px",
    fontFamily: SemiBold
  },
  requsetsubs: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: "text.main",
    marginBottom: "6px"
  },
  requsetsubs2: {
    fontSize: "0.75rem",
    color: "text.secondary",
    fontFamily: Regular,
    direction: "ltr",
    textAlign: ""
  },
  requsetsubs3: {
    fontSize: "0.75rem",
    color: "text.secondary",
    marginTop: "4px",
    alignSelf: "end",
  },
  requsetsubs4: {
    fontSize: "0.75rem",
    color: "text.secondary",
    fontFamily: SemiBold,
  },
  screen: {
    borderRadius: "16px",
    background: "background.tertiary1",
  },
  screenPadding: {
    marginTop: "5px",
    // padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  requestBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "14px"
  },
  divider: {
    margin: "14px 0px 14px",
    height: "6px"
  },
  requsettitleBox:{
    marginBottom:"8px",
  },
  marginInline: {
    marginInlineStart: "8px"
  },
  footer1: {
    position: 'sticky',
    width: "100%",
    bottom: "0",
  },
  stepsBox: {
    backgroundColor: "#FFFFFF",
    padding: "16px",
    borderRadius: "12px 12px 0px 0px"
  },
  stepsBox2: {
    backgroundColor: "#FFFFFF",
    padding: "16px",
    borderRadius: "0px 0px 12px 12px"
  },
  details: {
    fontSize: "1rem",
    fontFamily: SemiBold,
    color: "text.main"
  },
  steps: {
    color: "text.secondary",
    fontSize: "0.75rem",
    fontFamily: SemiBold
  },
  productBox: {
    // border: "1px solid #CED3DD",
    borderRadius: "8px",
    marginBottom: "16px"
  },
  button: {
    padding: "14px 130px",
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    textTransform: "lowercase"
  },
  preferTitle:{
    fontSize:"0.75rem",
    fontFamily:SemiBold,
    color:"#98A0AC"
  },
  slotTime: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color:"text.primary"
  },
  slotBox:{
    borderRadius:"8px",
    border:"1px solid #CED3DD",
    display:"flex",
    justifyContent:"center",
    padding:"12px 0px"
  },
  selectedSlotBox:{
    borderRadius:"8px",
    border:"1px solid #5078E1",
    display:"flex",
    justifyContent:"center",
    backgroundColor:"#F1F7FF",
    padding:"12px 0px"
  },
}

