import { Bold, SemiBold } from "../../utils";


export const filterStyle = {
    root: {
        flexGrow: 1,
    },

    active: {
        width: {
            sm: "24px !important",
            xs: "27px !important"
        },
        height: {
            sm: "24px !important",
            xs: "27px !important"
        },
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontSize: "0.75rem",
        color: "#5078E1",
        backgroundColor: "#E4E8EE",
        borderRadius: "50%",
        fontFamily: Bold,
    },
    filterDataJson: {
        fontFamily: SemiBold
    },
    filterdata: {
        fontSize: "1rem",
        fontFamily: Bold,
    },
    roots: {
        height: "calc(100vh - 190px )",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    btncontainer: {
        padding: "14px",
        backgroundColor: "#F5F7FA",
    },
    applybtn: {
        padding: "12px",
        borderRadius: "4px",
        fontFamily: SemiBold
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        borderRadius: "4px",
        fontFamily: SemiBold
    },
    filterLabel: {
        "& .MuiFormControlLabel-label": {
            fontSize: "1rem",
            fontFamily: SemiBold,
        },
    },
    footer: {
        position: 'sticky',
        width: "100%",
        bottom: "0"

    },
    rightFilters: {
        padding: "12px",
        height: "100%",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    leftFilters: {
        borderInlineEnd: "1px solid #c1c1c1",
        "&::-webkit-scrollbar": {
            display: "none",
        },

    }


}