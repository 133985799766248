import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { HomeFogg, RightArrow } from "../../assets";
import {
  CarouselSliders,
  ChartBox,
  DashCards,
  DialogDrawer,
  DocumentViewer,
  FavCards,
  FooterComponent,
  InvoiceCard,
  LoderSimmer,
  PaymentModal,
  ServiceCard,
  TownCards,
} from "../../components";
import { Announcement } from "../../components/announcement";
import { LandlordApprovalRequest } from "../../components/landlordApproval";
import NoData from "../../components/noData";
import CommunityMemberModel from '../../components/notavailable';
import { NudgeCard } from "../../components/nudgeCard";
import { AgreementNudgeTypes, MaintanenceRequestsNudgeTypes, nudges_card_constants } from "../../components/nudgeCard/constant";
import { OtherFeatures } from "../../components/otherFeatures";
import { config } from "../../config";
import { useLazyAlreadypaidCreationQuery, useLazyDashboardCarouselQuery, useLazyDashboardQuery, useLazyGetApprovalListQuery, useLazyGetControlsQuery, useLazyGetCurrentWeatherQuery, useLazyGetDeviceTokenQuery, useLazyGetNudgeLIstQuery, useLazyGetRequestStatusEnumQuery, useLazyGetRevenueQuery, useLazyInvoicesOverdueQuery, useLazyInvoicesPdfQuery } from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { Contract, LocalStorageKeys, currencyFormatted, currentMonth, currentYear, dashboardCards, errorRoute, getTotal, getTotalByunit, getYear, month, otherFeatures, quickAccessCards } from "../../utils";
import { Towncard } from "../../utils/towncards";
import { AlreadyPaidDrawer } from "../invoices/alreadyPaidDrawer";
import Explore from "./explore";
import { RevenuePieCharts } from "./revenuechart";
import { DashboardSimmer } from "./simmerComponent/dashboardSimmer";
import {
  dashBoardStyle,
} from "./style";
import ScrollContainer from "react-indiana-drag-scroll";
import { ProjectProgressCard } from "../propertyUnitNew/components/projectProgressCard";


const initial = {
  yearSelect: {
    value: currentYear,
    label: currentYear
  },
  monthSelect: {
    value: currentMonth,
    label: currentMonth
  },
  categorySelect: ""
}
const initialData = {
  amount_paid: "",
  type: "",
  date: "",
  bank_location: "",
  transaction_id: "",
  notes: "",
  error: {
    amount_paid: "",
    date: "",
    type: "",
    bank_location: "",
    transaction_id: "",
    notes: "",

  }
}

export const Home = ({
  t = () => false
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  // const [decoded, setDecoded] = React.useState(null);
  const accessType = JSON.parse(localStorage.getItem(LocalStorageKeys?.accessType));
  const [Dashboard, { error }] = useLazyDashboardQuery();
  const [CarouselData, { error: isError }] = useLazyDashboardCarouselQuery();
  const [getDeviceToken] = useLazyGetDeviceTokenQuery();
  const [InvoicesOverdueAPI] = useLazyInvoicesOverdueQuery()
  const [nudgeList] = useLazyGetNudgeLIstQuery();
  const [getCurrentWeather] = useLazyGetCurrentWeatherQuery();
  const [getControlsList] = useLazyGetControlsQuery();
  const [PaymentEnumAPI, { error: isError2 }] = useLazyGetRequestStatusEnumQuery()
  const [AlreadypaidCreation, { error: isError3 }] = useLazyAlreadypaidCreationQuery()
  const [getApprovalList] = useLazyGetApprovalListQuery();
  const [nudges_card_state, set_nudges_card_state] = React.useState([]);
  const nudge_slide_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    cssEase: "linear",
    arrows: false,
  };

  const [paymentMode, setPaymentMode] = React.useState([]);
  const [open, setOpen] = React.useState(false)

  // eslint-disable-next-line
  const [select, setSelect] = useState({ ...initial, bool: true })
  // eslint-disable-next-line
  const [dashboardData, setDashboardData] = useState({
    listingDatas: [],
    carouselDatas: [],
    bool: true
  })
  const [drawer, setDrawer] = React.useState(false);
  const [overdue, setOverdue] = React.useState({
    listingDatas: {},
    simmer: true
  });
  const [selectedInvoice, setSelectedInvoice] = React.useState({
    id: "",
    is_checked: false,
    items: []
  });
  const [paymentForm, setPaymentForm] = React.useState(initialData);
  const [InvoicePdf, { error: isError4 }] = useLazyInvoicesPdfQuery()
  const [controlList, setControlList] = React.useState({
    quickLinks: [],
    otherFeaturesList: [],
    townShip: true,
    amenityGoTo: true,
    realtyGoTo: true,
    privateListings: true,
    invoiceWidget: true,
    revenueWidget: true
  })

  const [GetRevenue] = useLazyGetRevenueQuery();

  const [toggle, setToggle] = useState("1")
  const [graph, setGraph] = useState({
    bool: false,
    data: []
  })
  const [pdf, setPdf] = React.useState({
    pdf: "",
    bool: false,
    open: false
  })
  const [isQuickPay, setIsQuickPay] = React.useState(false);
  const [approvalData, setApprovalData] = React.useState([])

  const countDetails = dashboardData?.listingDatas

  const getStatusValue = {
    "total": {},
    "vacant": { state: "Vacant" },
    "occupied": { state: "Occupied" },
    "purchased": { state: "Purchased" },
  }


  const InvoicesOverdue = async (payload) => {
    const InvoicesOverdueRes = await InvoicesOverdueAPI(payload)
    return setOverdue({
      ...overdue,
      listingDatas: InvoicesOverdueRes?.data?.data,
      simmer: false
    })
  }

  const selectedInvoices = (x) => {
    if (x) {
      setSelectedInvoice({ ...selectedInvoice, items: [x] })

    }
    setDrawer(true);
  }
  const dboard = async (payload) => {
    const listdata = await Dashboard(payload)
    await InvoicesOverdue()
    const carousel = await CarouselData(
      {
        company_id: accessType?.map((x, index) => {
          return (
            x?.company_id
          )
        }),
        type: "Dashboard",
        offset: 0,
        limit: 1000
      })

    setDashboardData({
      ...dashboardData,
      carouselDatas: carousel?.data?.data?.[0]?.assets,
      listingDatas: listdata?.data,
      bool: false
    })
    // setDecoded(jwt_decode(authToken));
  }

  const deviceToken = async (payload) => {
    if(localStorage.getItem(LocalStorageKeys?.deviceToken)){
      await getDeviceToken({
        app_build: config?.xbuildCode,
        token: localStorage.getItem(LocalStorageKeys?.deviceToken)
      })
    }
  }
  const handlePaymentDrawerClose = () => {
    setDrawer(false);
  };

  React.useEffect(() => {
    if (error?.status === 423 || isError?.status === 423 || isError2?.status === 423 || isError3?.status === 423 || isError4?.status === 423) return errorRoute()
  }, [error, isError, isError2, isError3, isError4])

  const paymentEnum = async () => {
    const paymentEnumRes = await PaymentEnumAPI(["payment_mode"])
    setPaymentMode(paymentEnumRes?.data?.data)
  }
  useEffect(() => {
    getNudgeData()
    dboard()
    getGraph({
      year: select?.yearSelect?.value,
    })
    deviceToken()
    getControls()
    getLandLordApprovalList()
    // eslint-disable-next-line 
  }, [])

  const getGraph = async (payload) => {
    setGraph({
      data: [],
      bool: true
    })
    const data = await GetRevenue({
      year: payload?.year,
      month: payload?.month
    })
    if (data?.data?.data?.length) {
      const result = getTotalByunit(data?.data?.data)
      setGraph({
        data: result?.map((x) => {
          return {
            name: payload?.invoice_no,
            value: x?.amount ?? 0,
            symbol: x?.symbol,
            ...x
          }
        }),
        bool: false
      })
    }
    else {
      setGraph({
        data: [],
        bool: false
      })
    }

  }
  const getControls = async () => {
    const result = await getControlsList({
      company_id: accessType?.map((x, index) => {
        return (
          x?.company_id
        )
      })
    })
    const activeControls = result?.data?.data?.filter((x) => { return (x?.app_build === config?.xbuildCode || x?.is_common) })?.map((val) => { return val?.title })
    const quickList = quickAccessCards(t)?.filter((x) => {
      return activeControls.includes(x?.key_name)
    })
    const otherFeaturesList = otherFeatures(t, setFun)?.filter((x) => {
      return activeControls.includes(x?.key_name)
    })
    setControlList({
      quickList: [...quickList, {
        image: "/images/contractHistory.svg",
        title: t("Contract History"),
        is_image: true,
        router: AppRoutes.landLordApprovalList,
      },{
        title: t("Letters"),
        key_name:"Letters",
        image: "/images/letter_icon.svg",
        router: AppRoutes.caseManagement,
        is_image: true,
      }], otherFeaturesList: otherFeaturesList, invoiceWidget: activeControls?.includes("Invoice widget"), revenueWidget: activeControls?.includes("Revenue widget"), townShip: activeControls?.includes("Township"), realtyGoTo: activeControls?.includes("RealtyGOTO"), amenityGoTo: activeControls?.includes("AmenitiesGOTO"), privateListings: activeControls?.includes("Private Listings")
    })
  }
  const toggleChange = (e) => {
    setToggle(e)
    if (e === "2") {
      getGraph({
        month: month.indexOf(select?.monthSelect?.value) + 1,
        year: select?.yearSelect?.value,

      })
    }
    else {
      getGraph({
        year: select?.yearSelect?.value,
        category: select?.categorySelect?.value
      })
    }
  }
  const updateState = (k, v) => {
    setSelect({ ...select, [k]: v });
  };

  const handleChangePeriod = async (m, y) => {
    setSelect({
      ...select,
      yearSelect: y,
      monthSelect: m
    })
    await getGraph({
      month: toggle === "1" ? undefined : month.indexOf(m?.value) + 1,
      year: y.value,
    })

  }

  const handleChecked = (e) => {
    if (selectedInvoice?.items?.map((v) => v?.id).includes(e?.id)) {
      setSelectedInvoice({
        ...selectedInvoice,
        id: e?.id,
        is_checked: true,
        items: selectedInvoice?.items?.filter((x) => x?.id !== e?.id)
      })
      setIsQuickPay(false)
    }
    else {
      setSelectedInvoice({
        ...selectedInvoice,
        items: [...selectedInvoice?.items, e]
      })
      setIsQuickPay(false)
    }
  }
  const handleDelete = (e, index) => {
    handleChecked(e)
  }

  const handleAlreadyPaid = () => {
    paymentEnum()
    setDrawer({
      ...drawer, alreadyPaidDrawer: true
    })
  }

  const handlePdfDrawerClose = () => {
    setPdf({
      ...pdf,
      open: false
    })
  }

  const handleAlreadyPaidClose = () => {
    setDrawer({
      ...drawer, alreadyPaidDrawer: false
    })
  }
  const validate = () => {
    let isValid = true;
    let error = paymentForm?.error
    if (paymentForm?.amount_paid?.length === 0) {
      isValid = false
      error.amount_paid = t("Paid amount is Required")
    }
    if (paymentForm?.type?.length === 0) {
      isValid = false
      error.type = t("Type is Required")
    }
    if (paymentForm?.date?.length === 0) {
      isValid = false
      error.date = "Date is Required"
    }
    if (paymentForm?.bank_location?.length === 0) {
      isValid = false
      error.bank_location = t("Bank name/Location is Required")
    }
    if (paymentForm?.transaction_id?.length === 0) {
      isValid = false
      error.transaction_id = t("Transaction Id is Required")
    }
    if (paymentForm?.notes?.length === 0) {
      isValid = false
      error.notes = t("Notes is Required")
    }

    setPaymentForm({ ...paymentForm, error })
    return isValid
  }
  const SaveReconcile = async () => {
    if (validate()) {
      const payload = {
        amount_paid: paymentForm?.amount_paid,
        bank: paymentForm?.bank_location,
        invoice_id: selectedInvoice?.items?.[0]?.id,
        notes: paymentForm?.notes,
        paid_on: moment(new Date(paymentForm?.date)).tz(moment.tz.guess()).format("DD MMM YYYY"),
        payment_mode: paymentForm?.type,
        trx_no: paymentForm?.transaction_id
      }
      const payed = await AlreadypaidCreation(payload)

      if (payed?.isSuccess) {
        handleAlreadyPaidClose()
        setPaymentForm(initialData)
        handlePaymentDrawerClose()
        navigate(AppRoutes?.payment, { state: true })

      }
      else {
        navigate(AppRoutes?.payment, { state: false })

      }

    } else {
      enqueueSnackbar(t(`Please Fill Mandatory Fields`), {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      })
    }
  }
  const onClickPDF = async (x) => {
    await setPdf({
      pdf: "",
      bool: true,
      open: true
    })
    const data = await InvoicePdf({
      invoice_id: x?.id
    })
    await setPdf({
      pdf: data?.error?.data ?? "",
      bool: false,
      open: true
    })
  }
  const updateState1 = (key, value) => {
    let error = paymentForm?.error
    error[key] = "";
    setPaymentForm({
      ...paymentForm, [key]: value, error
    })
  }

  // LANDLORD APPROVAL PAGE REDIRECT
  const approvalPageRedirect = (data) => {
    navigate(AppRoutes.landlordApprovalView, { state: data })
  }
  const getLandLordApprovalList = async () => {
    const response = await getApprovalList({})
    setApprovalData(response?.data?.data)
  }
  const getWeatherDetails = (latitude, longitude, nudges) => {
    let payload = { lat: latitude, lon: longitude, };
    getCurrentWeather(payload)
      .then((_) => {
        let res = _?.data?.data;

        let weather_data = {
          type: nudges_card_constants({ t, nudge: _ })?.weather?.type,
          title: nudges_card_constants({ t, nudge: _ })?.weather?.title,
          subject: res?.name + ", " + parseInt(res?.main?.temp) + "°C",
          sub_text: res?.weather?.[0]?.main,
          icon: `https://openweathermap.org/img/w/${res?.weather?.[0]?.icon}.png`,
          background: nudges_card_constants({ t, nudge: _ })?.["weather"]?.background,
          dark_dash_color: nudges_card_constants({ t, nudge: _ })?.["weather"]?.dark_dash_color,
          light_dash_color: nudges_card_constants({ t, nudge: _ })?.["weather"]?.light_dash_color,
        };

        let nudge_data = constructNudgeState(nudges);

        set_nudges_card_state([weather_data, ...nudge_data]);
      }
      ).catch((err) => {
        let nudge_data = constructNudgeState(nudges);
        set_nudges_card_state(nudge_data);
        console.log("Error: ", err);
      });
  }

  const constructNudgeState = (nudges) => {
    let nudge_data = nudges?.map((_) => {
      let nudge_type = nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.type;
      let meta_data = nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.meta_data;
      return {
        type: nudge_type,
        title: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.title,
        subject: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.subject,
        icon: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.icon,
        image: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.image,
        background: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.background,
        dark_dash_color: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.dark_dash_color,
        light_dash_color: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.light_dash_color,
        onClick: () => {
          if (AgreementNudgeTypes?.includes(nudge_type)) {
            localStorage.setItem(
              LocalStorageKeys.agreement,
              JSON.stringify(_?.nudge?.meta_data)
            );
          }
          if (nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.route) {
            let history_state = {};
            if (MaintanenceRequestsNudgeTypes?.includes(nudge_type)) {
              history_state = {
                id: meta_data?.maintenance_id,
                request_type: "Maintenance",
                request_status: "Assigned"
              };
            }
            navigate(
              nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.route,
              {
                state: {
                  ...history_state,
                  type: nudge_type,
                  meta_data: meta_data,
                }
              })
          }
        },
        onClick_text: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.onClick_text,
        meta_data: _?.nudge?.meta_data,
      }
    })

    return nudge_data;
  }
  const getWeatherData = async (nudges) => {
    await new Promise((resolve, reject) => {
      try {
        if (navigator?.geolocation) {
          navigator?.geolocation?.getCurrentPosition?.(
            (_) => {
              getCoordinates(_, nudges);
            },
            (err) => {
              let nudge_data = constructNudgeState(nudges);
              set_nudges_card_state(nudge_data);
              reject(err);
            });
        } else {
          let nudge_data = constructNudgeState(nudges);
          set_nudges_card_state(nudge_data);
        }
        resolve(true);
      } catch (err) {
        let nudge_data = constructNudgeState(nudges);
        set_nudges_card_state(nudge_data);
        reject(err);
      }
    });
  }
  const getCoordinates = (position, nudges) => {
    getWeatherDetails(position.coords.latitude, position.coords.longitude, nudges);
  }
  const getNudgeData = () => {
    let payload = {
      current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    };
    nudgeList(payload)
      // NetworkCall(
      //   `${config.api_url}${BackendRoutes?.nudge_list}`,
      //   NetWorkCallMethods.post, payload, null, true
      // )
      .then((_) => {
        getWeatherData(_?.data?.data ?? []);
      }).catch((err) => {
        console.log(err);
        getWeatherData([]);
      });
  }

  const setFun = () => {
    setOpen(true)
  }

  const handleViewProgressAsset = (asset) => {
    navigate(AppRoutes.assetViewPage, { state: { assets: asset, hideVideoTab: true } })
}

  return (
    <>

      <Box>
        {dashboardData?.bool ?
          <DashboardSimmer />
          :
          <Box sx={dashBoardStyle?.root}>
            {/* <Box display="flex" alignItems="center" sx={dashBoardStyle?.main}>
              <Box flexGrow={1}>
                <Typography sx={dashBoardStyle?.username}>{t("Hi ") + (decoded && decoded.username ? decoded.username : "")}</Typography>
                <Typography sx={dashBoardStyle?.msg}>
                  {t("Welcome Back") + "!"}
                </Typography>
              </Box>
              <ProfileImageOuterDiv>
                <ProfileImageInnerDiv>
                  <Image
                    variant="circular"
                    src={countDetails?.image_url}
                    alt={"profile_img"}
                    onClick={() => navigate(AppRoutes.profileView)}

                  />
                  <UserID>{t("Owner")}</UserID>
                </ProfileImageInnerDiv>
              </ProfileImageOuterDiv>
            </Box> */}
            <Grid sx={dashBoardStyle?.nudgeBox} container justifyContent="center">
              <Grid item xs={12} alignItems={"center"}>
                <Slider {...nudge_slide_settings}>
                  {nudges_card_state?.map((nudge, i) => {
                    return <NudgeCard t={t} nudge={nudge} list={nudges_card_state} card_index={i} />
                  })}
                </Slider>
              </Grid>
            </Grid>
            {/* favourite */}
            {controlList?.quickList?.length > 0 &&
              < Box sx={dashBoardStyle?.main}>
                <Typography sx={dashBoardStyle?.favTitle}>{t("Quick Links")}</Typography>
                <Box sx={dashBoardStyle?.favParent}>
                  {controlList?.quickList?.map((x, index) => {
                    return (
                      <>
                        <Box key={index}>
                          <FavCards
                            title={x?.title}
                            image={x?.image}
                            is_image={x?.is_image}
                            router={x?.router}
                            bgcolor={{ backgroundColor: 'primary.contrastText' }}
                            root_style={{
                              padding: "0px !important"
                            }}
                          />
                        </Box>
                        {index < controlList?.quickList?.length - 1 && (
                          <Divider sx={dashBoardStyle?.favDivider} />
                        )}
                      </>
                    );
                  })}
                </Box>
              </Box>
            }
            {/* Announcement */}
            {countDetails?.list?.length > 0 &&
              <Box sx={dashBoardStyle?.main} mb={2.5}>
                <Box display="flex" justifyContent={"space-between"}>
                  <Typography sx={dashBoardStyle.invoiceTitle}>{t("Announcements")}</Typography>
                  <Box onClick={() => navigate(AppRoutes?.announcement, { state: { tabValue: 1 } })}>
                    <Typography sx={dashBoardStyle.viewAllText}>{t("View All")}</Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Announcement list={countDetails}
                    title={t("Announcements")}
                    count={countDetails?.count}
                    t={t} />
                </Box>
              </Box>
            }
            <Box sx={dashBoardStyle?.main}>
              <Grid container spacing={'10px'}>
                {dashboardCards(t, countDetails)?.map((x, index) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <DashCards
                        title={x?.title}
                        description={x?.description}
                        count={x?.count}
                        image={x?.image}
                        is_image={x?.is_image}
                        router={() => navigate(x?.onClick, getStatusValue[x?.type])}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {/* carousel */}
            <Box sx={dashBoardStyle?.main}>
              <CarouselSliders autoScroll={true} data={dashboardData?.carouselDatas} />
            </Box>
            {controlList?.revenueWidget &&
              <Box sx={dashBoardStyle?.main}>
                <ChartBox
                  onChange={toggleChange}
                  handleChangePeriod={handleChangePeriod}
                  symbol={graph?.data?.find((x) => x?.symbol)?.symbol}
                  options={[
                    { value: "1", label: t("Yearly") },
                    { value: "2", label: t("Monthly") },
                  ]}
                  data={graph?.data}
                  value={toggle}
                  title={t("Revenue")}
                  chart_parent={{
                    padding: "18px !important"
                  }}
                  title_style={{
                    marginBottom: "0px !important"
                  }}
                  year={getYear()?.map((x) => {
                    return {
                      value: x,
                      label: x
                    }
                  }) ?? []}
                  month={month?.map((x) => {
                    return {
                      value: x,
                      label: x
                    }
                  }) ?? []}
                  toggles={toggle === "2" ? true : false}
                  isFull
                  monthValue={select?.monthSelect?.value}
                  yearValue={select?.yearSelect?.value}
                  values={{
                    month: select?.monthSelect,
                    year: select?.yearSelect
                  }}
                  yearSelect={(e) => {
                    updateState("yearSelect", e)
                    getGraph({
                      year: e?.value,

                    })
                  }}
                  loader={graph?.bool}
                  graphcomponent={
                    <RevenuePieCharts
                      total={`${graph?.data?.find((x) => x?.symbol)?.symbol} ${currencyFormatted(getTotal(graph?.data, 'amount'))}`}
                      data={graph?.data}
                      totalText={t('Total')} width={140} t={t}

                    />
                  }
                />

              </Box>
            }
            {/* Unpaid Invoice */}
            {controlList?.invoiceWidget &&
              overdue?.listingDatas?.totalOverDueAmount > 0 &&
              <>
                <Box sx={dashBoardStyle?.main}>
                  <InvoiceCard
                    t={t}
                    data={overdue?.listingDatas?.data}
                    amt={`${overdue?.listingDatas?.data?.find((x) => x?.currency_code)?.currency_code} ${currencyFormatted(overdue?.listingDatas?.totalOutStandingAmount)}`}
                    title={`${overdue?.listingDatas?.data?.length ? overdue?.listingDatas?.data?.length : 0} 
              ${t("Unpaid Invoice")}`}
                    amttype={t("Outstanding amount")}
                    overdueamount={`${currencyFormatted(overdue?.listingDatas?.totalOverDueAmount)} 
              ${t("is overdue amount")}`}
                    paytype={t("Quick pay")}
                    btnText={t("Pay now")}
                    selectedInvoices={selectedInvoices}
                  />
                </Box>

              </>
            }

            {/* Project Progress */}

            {
              countDetails?.project_progress?.length > 0 &&
              <Box px={2}>
                <Box py={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                  <Typography sx={dashBoardStyle?.landLordTitle}>{"Project Progress"}</Typography>
                  {/* <Box onClick={() => navigate(AppRoutes.landLordApprovalList)}>
                    <Typography sx={dashBoardStyle.viewAllText}>{t("View All")}</Typography>
                  </Box> */}
                </Box>

                <Box>
                  <ScrollContainer className="scroll-container">
                    <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                      {
                        countDetails?.project_progress?.map((e) => {
                          return (
                            <Box>
                              <ProjectProgressCard
                                data={e}
                                // property={unitData?.property}
                                t={t}
                                onNavigate={() => navigate(AppRoutes.propertyDetails, { state: { id: e?.project_id, data: e, type: "project" } })}
                                handleViewAllAsset={() => handleViewProgressAsset(e?.images?.map((e) => { return { url: e?.asset_url } }) ?? [])} 
                                />
                            </Box>
                          )
                        })
                      }
                    </Stack>
                  </ScrollContainer>
                </Box>
              </Box>
            }

            {/* LANDLORD APPROVAL REQUIRED */}
            {approvalData?.length > 0 &&
              <Box>
                <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                  <Typography sx={dashBoardStyle?.landLordTitle}>{t("Landlord Approval Required")}</Typography>
                  <Box onClick={() => navigate(AppRoutes.landLordApprovalList)}>
                    <Typography sx={dashBoardStyle.viewAllText}>{t("View All")}</Typography>
                  </Box>
                </Box>
                <Box sx={dashBoardStyle?.lanlordParent} style={{ padding: "0px 0px 16px 16px" }}>
                  {approvalData.map((val) => {
                    return (
                      <Box width="320px">
                        <LandlordApprovalRequest
                          t={t}
                          button
                          data={val}
                          marginLeft={"25px"}
                          onClick={() => approvalPageRedirect(val)} />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            }
            {/* contract and invoice */}
            {dashboardData?.listingDatas?.is_contract &&
              <Box sx={dashBoardStyle?.main}>
                <Box sx={dashBoardStyle?.contracts}>
                  <Typography sx={dashBoardStyle?.contractTitle}>
                    {t("Contract Management")}
                  </Typography>
                  <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"} divider={<Divider sx={dashBoardStyle?.favDivider} />}>
                    {Contract(t)?.map((x, index) => {
                      return (
                        <>
                          <FavCards
                            title={x?.title}
                            image={x?.image}
                            is_image={x?.is_image}
                            router={x?.onClick}
                          />
                        </>
                      );
                    })}
                  </Stack>
                </Box>
              </Box>
            }
            {/* OTHER FEATURES */}
            {controlList?.otherFeaturesList?.length > 0 &&
              <Box sx={dashBoardStyle?.main}>
                <Typography mb={2} sx={dashBoardStyle?.favTitle}>{t("Other Features")}</Typography>
                <Grid spacing={1} container>
                  {controlList?.otherFeaturesList.map((item) => {
                    return (
                      <Grid item xs={6}>
                        <OtherFeatures
                          router={item?.router}
                          icon={item?.icon}
                          title={item?.title}
                          is_not={item?.is_not}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            }
            {(controlList?.townShip || controlList?.amenityGoTo || controlList?.realtyGoTo || controlList?.privateListings) &&
              <>
                {/* EXPLORE CARD */}
                <Explore t={t} setFun={setFun} controlList={controlList} />
                {/* ServiceCard */}
                <Box sx={dashBoardStyle?.main} style={{ display: "none" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <ServiceCard
                        t={t}
                        title={t("Home Services, on demand")}
                        image={<RightArrow />}
                        homeimage={<HomeFogg />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {Towncard(t)?.map((x, index) => {
                            return (
                              <Box key={index}>
                                <TownCards
                                  title={x?.title}
                                  image={x?.image}
                                  homeimage={x?.homeImage}
                                  index={index}
                                  des={x?.des}
                                />
                                {index === 0 && <Box height={"7px"} />}
                              </Box>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </>
            }
          </Box>
        }


      </Box >
      <DialogDrawer
        maxWidth="sm"
        open={drawer}
        onClick={handlePaymentDrawerClose}
        header={t("Payment")}
        height_style={
          { height: "auto !important" }
        }
        component={
          <>
            <PaymentModal
              symbol={selectedInvoice?.items?.find((x) => x?.symbol)?.symbol}
              t={t}
              price={
                currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_due_amount)?.reduce((a, b) => a + b, 0))}
              invoice_total_tax_amount={
                currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_total_tax_amount).reduce((a, b) => a + b, 0))}
              selectedInvoice={selectedInvoice?.items}
              handleDelete={handleDelete}
              isQuickPay={isQuickPay}
              onClickPDF={onClickPDF}
              handleChecked={handleChecked} />
          </>
        }
        footer={
          <>
            {/* footer Component */}
            < FooterComponent
              containedDisabled={true}
              outlinedDisabled={selectedInvoice?.items?.length !== 1 ?? true}
              outlinedText={t("Already Paid?")}
              containedText={t("Pay now")}
              containedonClick={() => null}
              outlinedonClick={() => {
                handleAlreadyPaid()
              }}
            />

          </>
        }
        onClose={() => handlePaymentDrawerClose()}
      />

      <DialogDrawer
        maxWidth="sm"
        open={pdf?.open}
        onClick={handlePdfDrawerClose}
        header={t("Invoice")}
        height_style={
          { height: "500px !important" }
        }
        header_style={{
          padding: "8px !important"
        }}
        footer_style={{
          padding: "8px !important"
        }}
        component={
          <>
            {
              pdf?.bool ?
                <>
                  <LoderSimmer card count={1} size={500} variant={"rectangular"} animation={"wave"} /></>
                :
                !!pdf?.pdf ?
                  <DocumentViewer url={`data:application/pdf;base64,${pdf?.pdf}`} /> : <NoData />
            }
          </>
        }
        onClose={() => handlePdfDrawerClose()}
      />

      <DialogDrawer
        maxWidth="sm"
        open={drawer?.alreadyPaidDrawer}
        onClick={handleAlreadyPaidClose}
        header={t("Already Paid ?")}
        height_style={
          { height: "429px !important" }
        }
        header_style={{
          padding: "8px !important"
        }}
        footer_style={{
          padding: "8px !important"
        }}
        component={<AlreadyPaidDrawer
          t={t}
          updateState={updateState1}
          paymentMode={paymentMode}
          data={paymentForm} />}
        footer={
          <>
            {/* footer Component */}
            < FooterComponent
              single
              containedText={t("Reconcile")}
              containedonClick={SaveReconcile}
            />
          </>
        }

        onClose={() => handleAlreadyPaidClose()}
      />

      <CommunityMemberModel
        open={open}
        onClose={() => setOpen(false)}
      />
    </>


  );
};

