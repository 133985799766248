/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Stack, Container, Box, Typography, Grid, Avatar, Divider } from "@mui/material"
import UnitIcon from './utils/unitIcon'
import SQRTICon from './utils/sqrtIcon'
import { PropertyUnitNewStyle } from './style'
import { DialogDrawer, TopNavBar } from '../../components'
import { ImageSlider } from '../../components/imageSlider'
import { AppRoutes } from '../../router/routes'
import { useLocation, useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { AgreementDetailCard } from './components/agreementDetailCard'
import UDSIcon from './utils/udsIcon'
import SellableAreaIcon from './utils/sellableArea'
import FurnishingTypeIcon from './utils/furnishingTypeIcon'
import TitleDeedIcon from './utils/titleDeedIcon'
import CalendarIcon from './utils/calendarIcon'
import HijirCalendarIcon from './utils/hijirCalendarIcon'
import BathroomIcon from './utils/bathroomIcon'
import BedRoomIcon from './utils/bedRoomIcon'
import BalconyIcon from './utils/balcanyIcon'
import OrientationIcon from './utils/orientationIcon'
import MarketPriceIcon from './utils/marketPriceIcon'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NationAddIcon from './utils/nationalAddIcon'
import ShareHolderIcon from './utils/shareHolderIcon'
import EmergencyContactIcon from './utils/emergencyContactIcon'
import { AllAmenities } from './allAmenities'
import { useLazyGetOwnerPropertyQuery, useLazyGetOwnerUnitQuery } from '../../redux/services'
import AmenityIcon from './utils/amenityIcon'
import ElevatorIcon from './utils/elevatorIcon'
import ParkingSlotIcon from './utils/parkingSlot'
import FloorIcon from './utils/floorIcon'
import PropUnitIcon from './utils/propunitIcon'
import PropBlockIcon from './utils/propBlockIcon'
import { Shareholder } from './shareHolder'
import { checkYouTubeLink, hijriFormatter, randomColor, stringAvatar } from '../../utils'
import { ContactCard } from './components/contactCard'
import moment from 'moment'
import { PropertyUnitViewPageSimmer } from './propertyUnitViewPageSimmer'
import { withTranslation } from 'react-i18next'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NoDataShow from '../../assets/noData'

const PropertyUnitView = ({
    t = () => false
}) => {
    const navigate = useNavigate();
    const classes = PropertyUnitNewStyle
    const location = useLocation()
    const type = location?.state?.type
    const language = localStorage.getItem("i18nextLng")

    const [data, setData] = React.useState("")
    const [shareHolder, setShareHolder] = React.useState(false)
    const [emeContact, setEmeContact] = React.useState(false)
    const [viewAllData, setViewAllData] = React.useState(false)
    const [loading, setLoading] = React.useState(true)

    const agreementCardData = [
        {
            icon: <UDSIcon />,
            title: `${data?.uds_area} ${data?.uom?.name}`,
            subtitle: t("Land Area/UDS"),
            isActive: data?.uds_area
        },
        {
            icon: <SellableAreaIcon />,
            title: `${data?.super_sellable_area} ${data?.uom?.name}`,
            subtitle: t("Sellable area"),
            isActive: data?.super_sellable_area
        },
        {
            icon: <FurnishingTypeIcon />,
            title: data?.furnishing,
            subtitle: t("Furnishing type"),
            isActive: data?.furnishing
        },
        {
            icon: <TitleDeedIcon />,
            title: data?.title_deed,
            subtitle: t("Title deed No"),
            isActive: data?.title_deed
        },
        {
            icon: <CalendarIcon />,
            hijirIcon: <HijirCalendarIcon />,
            title: data?.deed_date ? moment(data?.deed_date).format("DD MMM YY") : "-",
            subtitle: t("Title deed date"),
            hijirTitle: data?.deed_date ? hijriFormatter.format(new Date(data?.deed_date)) : "-",
            hijirSubtitle: "1445 AH",
            hijir: data?.hijri_date?.length > 0,
            isActive: true,
            isActiveHijir: data?.hijri_date?.length > 0,
            isNormalActive: data?.title_deed
        }
    ]

    const propertyCardData = [
        {
            icon: <TitleDeedIcon />,
            title: data?.title_deed,
            subtitle: t("Title deed No"),
            isActive: data?.title_deed
        },
        {
            icon: <CalendarIcon />,
            hijirIcon: <HijirCalendarIcon />,
            title: data?.deed_date ? moment(data?.deed_date).format("DD MMM YY") : "-",
            subtitle: t("Title deed date"),
            hijirTitle: data?.deed_date ? hijriFormatter.format(new Date(data?.deed_date)) : "-",
            hijirSubtitle: "1445 AH",
            hijir: data?.hijri_date?.length > 0,
            isActiveHijir: data?.hijri_date?.length > 0,
            isActive: true,
            isNormalActive: data?.title_deed
        },
        {
            icon: <UDSIcon />,
            title: `${data?.land_area} ${data?.property_uom?.name}`,
            subtitle: t("Total land area"),
            isActive: data?.land_area
        },
    ]

    const otherDetails = [
        {
            icon: <BathroomIcon />,
            title: t("Bathroom"),
            value: data?.total_baths ?? 0
        },
        {
            icon: <BedRoomIcon />,
            title: t("Bedroom"),
            value: data?.total_bed_rooms ?? 0
        },
        {
            icon: <BalconyIcon />,
            title: t("Balcony"),
            value: data?.balconies ?? 0
        },
        {
            icon: <OrientationIcon />,
            title: t("Orientation"),
            value: data?.orientation ?? "-"
        }
    ]

    const propertyOtherDetails = [
        {
            icon: <PropUnitIcon />,
            title: t("Unit"),
            value: data?.unit_count ?? 0
        },
        {
            icon: <PropBlockIcon />,
            title: t(data?.custom_block_label),
            value: data?.block_count ?? 0
        },
        {
            icon: <FloorIcon />,
            title: t(data?.custom_floor_label),
            value: data?.floor_count ?? 0
        },
        {
            icon: <ParkingSlotIcon />,
            title: t("Parking Slots"),
            value: data?.parking_slot_count ?? 0
        },
        {
            icon: <ElevatorIcon />,
            title: t("Elevators"),
            value: data?.elevators ?? 0
        },
        {
            icon: <AmenityIcon />,
            title: t("Amenities"),
            value: data?.facility_count ?? 0
        }
    ]

    const [getOwnerProperty] = useLazyGetOwnerPropertyQuery()
    const [getOwnerUnit] = useLazyGetOwnerUnitQuery()

    React.useEffect(() => {
        if (location?.state?.type === "property") {
            getPropertyDetailData()
        } else {
            getUnitDetailData()
        }
        // eslint-disable-next-line
    }, [type])

    const getPropertyDetailData = async () => {
        setLoading(true)
        const response = await getOwnerProperty({
            property_id: location?.state?.id,
        })
        await setLoading(false)
        setData(response?.data?.data)
    }

    const getUnitDetailData = async () => {
        setLoading(true)
        const res = await getOwnerUnit({
            unit_id: location?.state?.id
        })
        await setLoading(false)
        setData(res?.data?.data)
    }

    const filterUnitImage = () => {
        // eslint-disable-next-line array-callback-return
        let images = (type === "property" ? data?.assets : data?.unit_assets)?.map((i) => {
            if (!checkYouTubeLink(i?.url) && i?.url?.slice(i?.url?.length - 3, i?.url?.length) !== "pdf") {
                return i ?? false
            }
        })

        return images?.filter((i) => i !== undefined)
    }

    const handleViewAllImage = () => {
        navigate(AppRoutes.assetViewPage, { state: { assets: type === "property" ? data?.assets : data?.unit_assets } })
    }

    return (
        <>
            <TopNavBar title={type === "property" ? data?.name : data?.name} isDirect route={() => navigate(-1)} t={t} />
            <Container maxWidth={'sm'} sx={{ position: 'relative', padding: "0px !important" }}>
                {
                    loading ?
                        <Box p={2} sx={{ height: "calc(100vh - 58px)", overflow: "auto" }}>
                            <PropertyUnitViewPageSimmer />
                        </Box>
                        :
                        <Box p={2} sx={classes.body}>
                            {type === "unit" && <Typography sx={classes.title}>{t("Unit Details")}</Typography>}
                            <Box sx={classes.box} mt={1}>
                                <ImageSlider image={filterUnitImage()} handleViewAll={() => handleViewAllImage()} hideViewAll={type === "property" ? data?.assets?.length === 0 ? true : false : data?.unit_assets?.length === 0 ? true : false} />

                                <Stack direction={"row"} columnGap={"6px"} alignItems={"center"} mt={"20px"}>
                                    {(data?.property_type?.property_type || data?.unit_type?.name) && <Stack direction={"row"} columnGap={"6px"} alignItems={"center"} sx={classes.unitTypeBox}>
                                        <UnitIcon />
                                        <Typography sx={classes.unitAndSqrtText}>{type === "property" ? data?.property_type?.property_type : data?.unit_type?.name}</Typography>
                                    </Stack>}

                                    {(data?.property_uom?.name || data?.uom?.name) && <Stack direction={"row"} columnGap={"6px"} alignItems={"center"} sx={classes.sqrtBox}>
                                        <SQRTICon />
                                        <Typography sx={classes.unitAndSqrtText}>{data?.total_area} {type === "property" ? data?.property_uom?.name : data?.uom?.name}</Typography>
                                    </Stack>}
                                </Stack>

                                <Stack spacing={"4px"} mt={"12px"}>
                                    <Typography sx={classes.unitTitle}>{type === "property" ? data?.name : data?.name}</Typography>
                                    <Typography sx={classes.unitSubTitle}>{type === "property" ? data?.property_no : data?.unit_no}</Typography>
                                </Stack>
                                {type === "unit" && <Typography sx={classes.propertySubTitle} mt={"6px"}>
                                    {data?.address?.street_1 ?? ""}{data?.address?.city && ", "}{data?.address?.city ?? ""}{data?.address?.state && ", "} {data?.address?.state ?? ""}{data?.address?.zipcode && " - "}{data?.address?.zipcode}
                                </Typography>}

                                <Box mt={"16px"}>
                                    <ScrollContainer className="scroll-container">
                                        <Stack direction={"row"} columnGap={"12px"}>
                                            {
                                                (type === "unit" ? agreementCardData : propertyCardData)?.map((e) => {
                                                    return (
                                                        <>
                                                            {
                                                                e?.isActive &&
                                                                <AgreementDetailCard
                                                                    icon={e?.icon}
                                                                    title={e?.title}
                                                                    subtitle={e?.subtitle}
                                                                    hijirTitle={e?.hijirTitle}
                                                                    hijirSubtitle={e?.hijirSubtitle}
                                                                    hijir={e?.hijir}
                                                                    hijirIcon={e?.hijirIcon}
                                                                    isActiveHijir={e?.isActiveHijir}
                                                                    isNormalActive={e?.isNormalActive}
                                                                />
                                                            }
                                                        </>
                                                    )
                                                })
                                            }

                                        </Stack>
                                    </ScrollContainer>
                                </Box>
                                {
                                    type === "property" &&
                                    <Stack direction={"row"} mt={"16px"} sx={classes.propertyBox}>
                                        <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                            {/* <DeveloperIcon /> */}
                                            <Avatar src={data?.vendor?.image_url} {...stringAvatar(data?.vendor?.name)}
                                            sx={{height: "40px", width: "52px", borderRadius: "12px"}}/>
                                            <Stack>
                                                <Typography sx={classes.propertyTitle}>{data?.vendor?.name ?? "-"}</Typography>
                                                <Typography sx={classes.propertySubTitle}>{t("Developer")}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                }


                                <Box sx={{ ...classes.propertyBox, position: "relative", zIndex: 999, backgroundColor: "#fff" }} mt={2}>
                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                        <Typography sx={classes.contactText}>{type === "property" ? t("Property Details") : t("Unit Details")}</Typography>
                                        <Typography sx={classes.viewallText} onClick={() => setViewAllData(true)}>{t("View all")}</Typography>
                                    </Stack>
                                    <Grid container>
                                        {
                                            (type === "property" ? propertyOtherDetails : otherDetails)?.map((e, i) => {
                                                return (
                                                    <Grid item xs={6} sx={i % 2 === 0 ? language === "ar" ? classes.leftBorder : classes.rightBorder : ""}>
                                                        <Stack direction={"row"} alignItems={"center"} columnGap={1} p={"12px"}>
                                                            {e?.icon}
                                                            <Stack direction={"row"} alignItems={"center"} columnGap={"4px"}>
                                                                <Typography sx={classes.valueText}>{e?.value}</Typography>
                                                                <Typography sx={classes.secText}>{e?.title}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                                {
                                    type === "unit" &&
                                    <Box sx={classes.priceBox} p={"12px 16px 0px"}>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={"16px 0px 8px 0px"}>
                                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                                <MarketPriceIcon />
                                                <Typography sx={classes.marketPriceText}>{t("Market Price")}</Typography>
                                            </Stack>
                                            <Typography sx={classes.marketPriceTextBold}>{data?.market_price ?? 0} {data?.property?.company?.currency?.symbol}</Typography>
                                        </Stack>
                                    </Box>
                                }

                                {
                                    type === "unit" &&
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={"16px"} sx={classes.propertyBox}>
                                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                            <Avatar sx={classes.propertyAvatar} {...stringAvatar(data?.property?.name)} src={data?.property?.logo}></Avatar>
                                            <Stack>
                                                <Typography sx={{ ...classes.propertyTitle, ...classes.propertySubTitleWidth }}>
                                                    {data?.property?.name}
                                                </Typography>
                                                <Typography sx={{ ...classes.propertySubTitle, ...classes.propertySubTitleWidth }}>
                                                    {data?.property?.address?.street_1 ?? ""}{data?.property?.address?.city && ", "}{data?.property?.address?.city ?? ""}{data?.property?.address?.state && ", "} {data?.property?.address?.state ?? ""}{data?.property?.address?.zipcode && " - "}{data?.property?.address?.zipcode}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Box onClick={() => {
                                            navigate(AppRoutes.propertyUnitView, {
                                                state: {
                                                    id: data?.property?.id,
                                                    type: "property",
                                                }
                                            })
                                        }}>
                                            {
                                                language === "ar" ?
                                                    <ArrowBackIosIcon sx={{ color: "#98A0AC" }} /> :
                                                    <ArrowForwardIosIcon sx={{ color: "#98A0AC" }} />
                                            }
                                        </Box>
                                    </Stack>
                                }

                            </Box>

                            <Box sx={classes.box} mt={2}>
                                <Typography sx={classes.contactText} mb={"12px"}>{t("Location & Address")}</Typography>
                                <Stack direction={"row"} columnGap={"12px"} alignItems={"center"}>
                                    <a target='_blank' rel='' href={`https://www.google.com/maps?q=${data?.address?.latitude},${data?.address?.longitude}`}>
                                        <img src='/images/propertyUnitLocationMap.png' alt='' sx={classes.mapImg} />
                                    </a>
                                    {
                                        data?.address ?
                                            <Typography sx={classes.addressText}>
                                                {data?.address?.door_no ?? ""}{data?.address?.door_no && ", "}
                                                {data?.address?.street_1 ?? ""}{data?.address?.street_1 && ", "}
                                                {data?.address?.street_2 ?? ""}{data?.address?.street_2 && ", "}
                                                {data?.address?.street_3 ?? ""}{data?.address?.street_3 && ", "}
                                                {data?.address?.area ?? ""}{data?.address?.area && ", "}
                                                {data?.address?.city ?? ""}{data?.address?.city && ", "}
                                                {data?.address?.state ?? ""}{data?.address?.state && ", "}
                                                {data?.address?.country ?? ""}{data?.address?.country && ", "}
                                                {data?.address?.zipcode && " - "}{data?.address?.zipcode}
                                            </Typography> :
                                            <Typography sx={classes.addressText}>-</Typography>
                                    }
                                </Stack>

                                <Box py={2}><Divider></Divider></Box>

                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                        <NationAddIcon />
                                        <Typography sx={classes.tertiaryText}>{t("National Address")}</Typography>
                                    </Stack>
                                    <Typography sx={classes.secondaryText}>{data?.address?.smart_geo_address?.length > 0 ? data?.address?.smart_geo_address : "-"}</Typography>
                                </Stack>
                            </Box>

                            <Box sx={classes.box} mt={"16px"}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"12px"}>
                                        <ShareHolderIcon />
                                        <Stack>
                                            <Typography sx={classes.propertyTitle}>{`${data?.share_holders?.length} ${t("Shareholders")}`}</Typography>
                                            <Typography sx={classes.propertySubTitle}>{t("View shareholder details")}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Box onClick={() => setShareHolder(true)}>
                                        {
                                            language === "ar" ?
                                                <ArrowBackIosIcon sx={{ color: "#98A0AC" }} /> :
                                                <ArrowForwardIosIcon sx={{ color: "#98A0AC" }} />
                                        }
                                    </Box>
                                </Stack>
                            </Box>

                            {
                                type === "property" &&
                                <Box sx={classes.box} mt={"16px"}>
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
                                            <EmergencyContactIcon />
                                            <Stack>
                                                <Typography sx={classes.propertyTitle}>{t("Emergency Contact")}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Box onClick={() => setEmeContact(true)}>
                                            {
                                                language === "ar" ?
                                                    <ArrowBackIosIcon sx={{ color: "#98A0AC" }} /> :
                                                    <ArrowForwardIosIcon sx={{ color: "#98A0AC" }} />
                                            }
                                        </Box>
                                    </Stack>
                                </Box>
                            }

                        </Box>
                }
            </Container>

            <DialogDrawer
                maxWidth="sm"
                open={viewAllData}
                header={type === "property" ? t("Property Information") : t("Unit Information")}
                padding="0px"
                onClose={() => setViewAllData(false)}
                component={
                    <Box sx={{ height: "500px" }}>
                        <AllAmenities type={type} data={data} t={t} />
                    </Box>
                }
            />

            <DialogDrawer
                maxWidth="sm"
                open={shareHolder}
                header={t("Shareholders")}
                padding="0px"
                onClose={() => setShareHolder(false)}
                height={"500px"}
                component={
                    <Box p={2}>
                        <Shareholder data={data?.share_holders?.map((e) => {
                            return {
                                fill: randomColor(e?.contact?.first_name),
                                ...e
                            }
                        })} />
                    </Box>
                }
            />

            <DialogDrawer
                maxWidth="sm"
                open={emeContact}
                header={t("Emergency Contact")}
                padding="0px"
                onClose={() => setEmeContact(false)}
                height={"500px"}
                component={
                    <>
                        {
                            data?.emergency_contact?.length === 0 ?
                                <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }}>
                                    <NoDataShow />
                                </Stack> :
                                <Stack p={2} spacing={1}>

                                    {
                                        data?.emergency_contact?.map((data) => {
                                            return (
                                                <ContactCard
                                                    data={{
                                                        first_name: data?.name,
                                                        mobile_no: data?.contact_number,
                                                        department: data?.department
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </Stack>
                        }
                    </>
                }
            />

        </>
    )
}

export default withTranslation('propertyUnitNew')(PropertyUnitView)
