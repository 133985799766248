import { Bold } from "../../utils"

export const NewTabsComponent_Style = {
  tabroot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "backgroundcard.teritary",
  },
  tabItem: {
    textTransform: "capitalize",
    fontFamily: Bold,
    fontSize: "0.75rem",
    color: "text.main",
    padding: "0px",
    minHeight: "auto",
    minWidth: "auto",
    marginRight: "10px",
    borderRadius: "16px",
    backgroundColor: "primary.contrastText",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontFamily: Bold,
    fontSize: "0.75rem",
    color: "primary.contrastText",
    marginRight: "8px",
    borderRadius: "18px",
    backgroundColor: "primary.main",
    minHeight: "auto",
    padding: '0px !important',
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",

    },
    minWidth: "auto",
  },
  title: {
    padding: "8px 12px",
    borderRadius: "4px",
    color: '#000000'
  },
  titleselect: {
    padding: "8px 12px",
    borderRadius: "4px",
    backgroundColor: "primary.main",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  more: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    backgroundColor: "background.main",
  },
  label: {
    marginInlineStart: "5px",
    fontFamily: Bold,
    fontSize:'0.75rem !important'
  },
  root:{
    zIndex:0
  }
}