// eslint-disable-next-line
import { Box, Container, Grid, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material"
import { welcomePageStyle } from "./style"
import React, { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { AppRoutes } from "../../router/routes"
import { LoginForm } from "./loginForm"
import { useDispatch } from "react-redux";
import { useLoginMutation, useVerifyOtpMutation, useLazyForgotPasswordQuery, useLazyInitialRenderQuery, useLazyDomainListQuery } from "../../redux/services";
import { useSnackbar } from 'notistack';
import { Bold, ValidateEmail } from "../../utils"
import { Buttons } from '../../components'
import { TitleBarNew } from "../../components/titlebarNew"
import { initital_screen_state } from "../../utils/login/utils"
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { getCurrentBuildNumber } from "../../utils/version"
import { ExploreIcon } from "./assets/exploreIcon";

export const Login = ({ t = () => false }) => {
    const InitialState = {
        email: "",
        password: "",
        otp: '0000',
        forgotEmail: '',
        error: {
            email: "",
            password: "",
            forgotEmail: '',
        },
    };
    const [app_version, set_app_version] = React.useState(null);
    const [initialRender] = useLazyInitialRenderQuery();
    const [domainList] = useLazyDomainListQuery();

    const { enqueueSnackbar } = useSnackbar()
    const [login, setLogin] = React.useState({ ...InitialState });
    const navigate = useNavigate()
    const state = useLocation().state;
    const dispatch = useDispatch();
    const [logIn, { isLoading, isError = false, isSuccess, error }] = useLoginMutation();
    const [verifyOtp, { data: data1, isSuccess: isSuccess1 }] = useVerifyOtpMutation();
    const [forgotPassword, { isLoading: isLoading1, isError: isError1, isSuccess: isSuccess2, error: error1 }] = useLazyForgotPasswordQuery();
    const [is_loading, set_is_loading] = useState(true);
    const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
    const [screen_state, set_screen_state] = React.useState(initital_screen_state);
    const [regionAnchorEl, setRegionAnchorEl] = React.useState(null);
    const is_region_open = Boolean(regionAnchorEl);
    const [region_list, set_region_list] = React.useState({});

    React.useEffect(() => {
        getCurrentBuild();
        initialLoad();
        getRegionList();
        // eslint-disable-next-line
    }, []);

    const getCurrentBuild = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
                set_app_version(temp_app_version);
            } catch (error) {
                reject(error);
            }
        });
    }

    const initialLoad = () => {
        getInitialRender();
    }



    const getInitialRender = () => {
        initialRender({}).then((res) => {
            let is_custom_domain = res?.data?.data?.is_custom_domain;
            let _ = res?.data?.data?.render_data?.[0]
            if (_) {
                let temp_screen_state = {
                    ...screen_state,
                    [_?.id && "id"]: _?.id,
                    [_?.region && "region"]: _?.region,
                    [_?.login_logo && "logo"]: _?.login_logo,
                    [_?.web_background && "web_background"]: _?.web_background,
                    [_?.mobile_background && "mob_background"]: _?.mobile_background,
                    [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
                    [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
                    [_?.flag && "flag"]: _?.flag,
                    [_?.domain && "domain"]: _?.domain,
                    [is_custom_domain && "is_custom_domain"]: is_custom_domain,
                    [_?.explore_meta && "explore_meta"]: _?.explore_meta?.[0],
                }
                set_screen_state(temp_screen_state);
            }
            set_is_loading(false);
        }).catch(() => {
            enqueueSnackbar(t('Some thing Went Wrong'), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
            set_is_loading(false);
        });
    }

    const getRegionList = () => {
        domainList({}).then((res) => {
            set_region_list(res?.data?.data?.domainList ?? []);
        }).catch(() => {
            enqueueSnackbar(t('Some thing Went Wrong'), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
        });
    };
    const updateState = (key, value) => {
        let error = login.error;
        error[key] = "";
        setLogin({ ...login, [key]: value, error });
    };

    const validateTwo = () => {
        let isValid = true;
        let error = login?.error;
        if (login?.forgotEmail?.length === 0) {
            isValid = false;
            error.forgotEmail = t("Email Id is Required");
        }
        setLogin({ ...login, error });
        return isValid;
    }
    const validate = () => {
        let isValid = true;
        let error = login?.error;
        //Checking email
        if (login?.email?.length === 0) {
            isValid = false;
            error.email = t("Email is Required");
        }

        //Checking password
        if (login.password.length === 0) {
            isValid = false;
            error.password = t("Password is required");
        }
        setLogin({ ...login, error });
        return isValid;
    };

    const handleSubmit = () => {
        if (validate()) {
            let value = {
                password: login?.password,
            };
            // eslint-disable-next-line
            var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(login.email);
            // eslint-disable-next-line
            var email = ValidateEmail(login.email);
            // eslint-disable-next-line

            if (isphone) {
                value["mobile_no"] = login.email.trim();
            } else if (email) {
                value["email_id"] = login.email.trim();
            } else {
                value["ucid"] = login.email.trim();
            }
            dispatch(logIn({ ...value }));

        } else {
            return false;
        }
    }
    const handleforgot = () => {
        if (validateTwo()) {
            forgotPword({ email_id: login?.forgotEmail });
        } else {
            return false;
        }
    }
    const verifyOTPFnc = async () => {
        const responseData = await verifyOtp({ otp: "0000" })
        if (responseData === "No Access") {
            enqueueSnackbar("Sorry you do not have access", {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
        } else {
            if(responseData?.data){
                navigate(AppRoutes.companySelect, {
                    state: {
                        status: 'confirmed',
                        data: responseData?.data ?? []
                    },
                })
            }
            else{
                enqueueSnackbar("Sorry you do not have access", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" }
    
                })
            }
        }
    }
    const forgotPword = async (payload) => {
        const forgotdata = await forgotPassword(payload)
        if (!forgotdata?.isError) {
            updateState("forgotEmail", "")
            enqueueSnackbar(t(`Resend Request Send successfully`), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: Bold }

            });
        }
        else {
            updateState("forgotEmail", "")
            enqueueSnackbar(`${error1?.data?.message ?? t("Invalid Email ID")}`, {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: Bold }

            });
        }

    }

    React.useEffect(() => {
        if (isSuccess) {
            verifyOTPFnc()
        }
        if (isSuccess1) {
            if (data1?.status === 200) {
                enqueueSnackbar(t(`User Verfied SuccessFully`), {
                    variant: "success",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: Bold }

                });

            }
            else {
                enqueueSnackbar(t(`Some Thing Went Wrong`), {
                    variant: "success",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: Bold }

                });

            }

        }
        // eslint-disable-next-line
    }, [isSuccess, navigate, enqueueSnackbar])
    React.useEffect(() => {
        if (localStorage?.getItem('auth_token')) {
            navigate(AppRoutes?.home)
        } else {
            // navigate(AppRoutes?.login)
        }
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (isError) {
            enqueueSnackbar(`${error?.data?.message}`, {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: Bold }

            });
        }
    }, [isError, enqueueSnackbar, error?.data?.message])

    const handleGoBack = () => {
        navigate(-1);
    }
    const handleRegion = (e) => {
        setRegionAnchorEl(e.currentTarget);
    }
    const handleRegionClick = (_) => {
        setRegionAnchorEl(null);

        let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain?.[0]?.domain,
            [_?.explore_meta && "explore_meta"]: _?.explore_meta?.[0],
        }

        set_screen_state(temp_screen_state);

        let link = `${temp_screen_state?.domain}${window.location.pathname}${window.location.search}`;
        window.open(link, "_self");

        enqueueSnackbar(`${t("Region Changed to")} ${temp_screen_state?.region} ${t("successfully.")}`, {
            variant: "success",
            anchorOrigin: { horizontal: "center", vertical: "top" },
            className: { fontFamily: Bold }

        });
    }

    const handleImageOnLoad = () => {
        setIsBackgroundImageLoading(false);
    }

    const handleImageOnError = () => {
        set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
    }

    const handleExploreCard = () => {
        let finalUrl = screen_state?.explore_meta?.link ?? "";

        if (finalUrl && !finalUrl.startsWith("http://") && !finalUrl.startsWith("https://")) {
            finalUrl = "https://" + (finalUrl.startsWith("www.") ? finalUrl?.replace("www.", "") : finalUrl);
        }

        finalUrl && window.open(finalUrl, "_blank", "noreferrer");
    }

    return (
        is_loading
            ? <></>
            : <>
                <TitleBarNew is_back_button={[AppRoutes?.welcomepage, AppRoutes?.welcome]?.includes?.(state?.origin)}
                    title={t("Log In")} goBack={handleGoBack} />
                <Container
                    style={{ padding: 0, height: "calc(100vh - 54px)", }}
                    maxWidth="sm"
                >
                    <form
                        autoComplete="on">
                        <Grid container>
                            <Grid
                                item
                                sx={welcomePageStyle?.content}
                            ><Grid item xs={12}>
                                    <Box sx={welcomePageStyle?.mob_background}>
                                        {isBackgroundImageLoading && <Skeleton variant="rounded" sx={welcomePageStyle?.mob_background_image} />}
                                        <img
                                            style={{
                                                ...welcomePageStyle?.mob_background_image,
                                                display: isBackgroundImageLoading ? "none" : "block",
                                            }}
                                            src={screen_state?.mob_background} alt=""
                                            onLoad={handleImageOnLoad}
                                            onError={handleImageOnError} />
                                    </Box>
                                </Grid>
                                {Boolean(screen_state?.explore_meta?.is_active) &&
                                    <>
                                        <Box height={"16px"} />
                                        <Grid item xs={12}>
                                            <Stack sx={welcomePageStyle?.exploreCard}
                                                direction={"row"} columnGap={"10px"}
                                                alignItems={"center"} justifyContent={"space-between"}
                                                onClick={handleExploreCard}>
                                                <Stack direction={"row"} columnGap={"10px"}
                                                    alignItems={"center"}>
                                                    <ExploreIcon />
                                                    <Typography sx={welcomePageStyle?.exploreInformation}>
                                                        {screen_state?.explore_meta?.information}
                                                    </Typography>
                                                </Stack>
                                                <Typography sx={welcomePageStyle?.exploreCTA}>
                                                    {screen_state?.explore_meta?.cta_text}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </>}
                                <Box height={"16px"} />
                                <Grid item xs={12}>
                                    <Stack direction={"row"} columnGap={"16px"}
                                        justifyContent={"space-between"} alignItems={"start"}>
                                        <Typography sx={welcomePageStyle?.text}>
                                            {t("Login as Owner")}
                                        </Typography>
                                        {!Boolean(screen_state?.is_custom_domain) &&
                                            <Box sx={welcomePageStyle?.region_box} onClick={handleRegion}>
                                                <img style={welcomePageStyle?.region_flag} src={screen_state?.flag} alt="" />
                                                {is_region_open
                                                    ? <KeyboardArrowUpRoundedIcon fontSize="small" color="#4E5A6B" />
                                                    : <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                                                }
                                            </Box>
                                        }
                                    </Stack>

                                </Grid>
                                <Box height={"16px"} />
                                <LoginForm updateState={updateState}
                                    login={login} t={t} setData={setLogin} isError={isError} onClick={handleforgot} forgotdata={{
                                        isLoading: isLoading1,
                                        isSuccess: isSuccess2,
                                        isError: isError1
                                    }} />
                                <Box height={"120px"} />
                            </Grid>
                        </Grid>
                    </form>





                </Container >
                <Container maxWidth="sm" sx={welcomePageStyle?.btnContainer}>
                    <Box sx={welcomePageStyle?.btnGrid} >
                        <Buttons
                            fullWidth
                            variant="contained"
                            sx={welcomePageStyle?.btn}
                            onClick={handleSubmit}
                            type="submit"
                            btnLoading={isError ? false : isLoading}
                            btnName={t("LogIn")}
                        />
                    </Box >
                    <Stack direction={"row"} columnGap={"16px"} px={"16px"} width={"100%"} display={"flex"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        <img style={welcomePageStyle?.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                        {app_version &&
                            <Typography sx={welcomePageStyle?.version_no}>
                                {`V ${app_version}`}
                            </Typography>}
                    </Stack>
                    <Box height={"16px"} />
                </Container>
                {/* Region List */}
                <Menu
                    id="region-menu_list" open={is_region_open}
                    anchorEl={regionAnchorEl} elevation={0}
                    sx={welcomePageStyle.region_menu_list}
                    onClose={() => setRegionAnchorEl(null)}
                    MenuListProps={{ "aria-labelledby": "basic-button", }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
                    transformOrigin={{ vertical: "top", horizontal: "right", }}>
                    {region_list?.map?.((_) => {
                        return (
                            <MenuItem
                                onClick={() => handleRegionClick(_)}
                                sx={_?.id === screen_state?.id
                                    ? welcomePageStyle.selected_region_menu_item
                                    : welcomePageStyle.unselected_region_menu_item}>
                                <img style={welcomePageStyle.region_flag} src={_?.flag} alt="" />
                                <Box width={"10px"} />
                                <Typography sx={_?.id === screen_state?.id
                                    ? welcomePageStyle.selected_region_name
                                    : welcomePageStyle.unselected_region_name}>
                                    {_?.region}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </>
    )
}
