import { config } from "../config";
import { LocalStorageKeys, NetworkCall, NetWorkCallMethods } from "./constants";

/**
 * The below function convert the normal array of object to 
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
    if (!data || !data?.length) {
        return [];
    }

    return data.map((val) => {
        return {
            ...val,
            value: val[valueKey],
            label: val[labelKey],
        };
    });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
    if (!string) {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
    if (hex) {
        let tempHex = hex.replace("#", "");
        let r = parseInt(tempHex.substring(0, 2), 16);
        let g = parseInt(tempHex.substring(2, 4), 16);
        let b = parseInt(tempHex.substring(4, 6), 16);

        return `rgba(${r},${g},${b},${opacity / 100})`;
    }
    return null;
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {

    const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
    const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = (version) => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            for (const name of names) {
                caches.delete(name);
            }
        });
        localStorage.setItem(LocalStorageKeys?.version, version)
    }
    // delete browser cache and hard reload
    window.location.reload(true);
};

// To get distance between two lattitude and longitude
export const distance = (lat1, lon1, lat2, lon2, unit) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") { dist = dist * 1.609344 }
        if (unit === "N") { dist = dist * 0.8684 }
        return dist;
    }
}

export const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct", "Nov", "Dec"]

export const getYear = () => {
    let minOffset = 0, maxOffset = 10;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for (let x = minOffset; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }

    return allYears
}

export const currentYear = new Date().getFullYear()

export const currentMonth = month[new Date().getMonth()]

export const findFileFormat = (file_url = "") => {
    const extension = file_url.split('.').pop();
    return extension
}

export const getMonth = (index) => {
    const month = ["JAN", "FEB", "MARCH", "APRIL", "MAY", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

    const current = new Date().getMonth()

    if (current === index) {
        return "This Month"
    } else if (current - 1 === index) {
        return "Last Month"
    } else {
        return month[index]
    }

}
export const currencyFormatted =(amount)=>  {
    if(!!amount) {
        const value=amount.toFixed(2)
        return Intl.NumberFormat('en-US').format(value)
    }
    return 0


}

export const getTotalByunit=(dataArray , is_percenteage)=>{
    const uniqueUnits = {};
    const uniqueArray = [];

    dataArray?.forEach(item => {
        const unitNo = item?.unit_no;
        if (!uniqueUnits?.[unitNo]) {
            uniqueUnits[unitNo] = true;
            uniqueArray?.push({
            ...item , 
            percentage:dataArray?.filter((v)=>v?.unit_no === item?.unit_no)?.map((x) => x?.percentage).reduce((a, b) => a + b, 0),
            amount:dataArray?.filter((v)=>v?.unit_no === item?.unit_no)?.map((x) => x?.amount).reduce((a, b) => a + b, 0)
        });
        }
    });
    
    return uniqueArray
}

export const concat_string = (object = {}, object_key = [], connector = ", ", if_empty = " - ") => {
    let array = []
    for (let i = 0; i < object_key.length; i++) {
        if (object[object_key[i]]?.length > 0) {
            array.push(object[object_key[i]])
        }
    }
    let temp_string = array.join(`${connector} `)
    let final_string = temp_string?.length > 0 ? temp_string : if_empty
    return final_string
}

export const SetDeviceToken = (currentToken , is_delete=false) => {
    const authToken = localStorage.getItem(LocalStorageKeys.authToken);
    if(authToken){
      if(localStorage.getItem(LocalStorageKeys?.deviceToken) || currentToken){
        const token = localStorage.getItem(LocalStorageKeys?.deviceToken) || currentToken
        const variables = {
          app_build:config?.app_x_build,
          token: token,
          is_delete
        };
      
        NetworkCall(
          `${config.api_url}/user-device-token/token`,
          NetWorkCallMethods.post,
          variables,
          null,
          true,
          false
        )
          .then((response) => {
            console.log("device token update")
          })
          .catch((error) => {
            console.log(error)
          });
      }
    }
  };