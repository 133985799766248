import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { TickIcon, SerachIMG } from '../../assets';
import { FormControl, Typography } from "@mui/material";
import { Label } from '../label'
import { Regular, SemiBold } from '../../utils';

const Wrapper = styled(Stack)(({ theme }) => ({
    padding: 3,
    paddingLeft: theme.spacing(1),
    backgroundColor: theme?.palette?.background?.secondary,
    borderRadius: 4,
    minWidth: 200,
}));



const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                {children}
                {props?.isSelected && <TickIcon />}
            </Stack>
        </components.Option>
    );
};

export const SelectBox = React.memo((props) => {

    const {
        key,
        value,
        placeholder,
        loadOptions,
        onChange,
        options,
        loading,
        isReadOnly,
        isMulti,
        debounceTimeout,
        reduceOptions,
        isClearable = false,
        styles = {},
        isPaginate = false,
        label = "",
        noBorder = false,
        noSearch = false,
        prefix = false,
        labelColor = null,
        labelSize = null,
        fontFamily = null,
        selectHeight = "",
        padding = false,
        variant,
        isRequired = "",
        borderRadius = "",
       
    } = props;

    const theme = useTheme();

    const Control = ({ children, ...props }) => {
        return (
            <components.Control {...props}>
                {
                    prefix ? prefix : (noBorder ? "" :
                        !noSearch && <SerachIMG color={theme?.palette?.text?.secondary} />)
                }

                {children}
            </components.Control>
        );
    };


    const customStyles = (props) => {


        return {
            control: (base) => ({
                ...base,
                ...props?.controls,
                borderColor:
                    props?.noBorder ? "white" : theme?.palette?.border?.main,
                borderRadius: props?.radius ?? 4,
                fontSize: "0.875rem",
                height: selectHeight ? selectHeight : 48,
                padding: padding ? padding : "auto",
                fontFamily: SemiBold ?? 'NotoSans-SemiBold',
                paddingLeft: props?.noBorder ? 0 : 8,
                paddingRight: props?.noBorder ? 0 : 8,
                borderBottom: props?.borderBottom ?? "1px solid #d6dae3",
                borderStyle: props?.borderStyle ?? "none",
                border: props?.border ? "" : "1.5px solid #E2E2E2",
                '&:not(:focus)': {
                    boxShadow: "none"
                },
                '&:hover': {
                    borderColor:
                        props?.noBorder ? "white" : theme?.palette?.primary.main,
                },
                ...styles?.control ?? {},

            }),
            valueContainer: (base) => ({
                ...base,
                paddingBlock: 3,
                padding: 0
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    fontFamily: SemiBold ?? 'NotoSans-SemiBold',
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap",
                    color: theme?.palette?.text?.secondary
                };
            },
            menu: defaultStyles => {
                return {
                    ...defaultStyles,
                    zIndex: 2,
                    fontSize: "0.875rem",
                    boxShadow: `0px 10px 25px ${theme?.palette?.boxShadow?.main}`,
                    border: `1px solid ${theme?.palette?.border?.secondary}`,
                    borderRadius: 4,
                };
            },
            option: (defaultStyles, prop) => {
                return {
                    ...defaultStyles,
                    fontFamily: 'NotoSans-SemiBold',
                    fontSize: "0.875rem",
                    cursor: "pointer",
                    ...isSingleStyle(prop),
                };
            },
        }
    };

    const isSingleStyle = (prop) => {
        if (prop.isMulti) return {}
        return {
            color: prop?.isSelected ? theme?.palette?.primary?.main : theme?.palette?.text?.main,
            backgroundColor: "transparent",
            '&:hover,&:active': {
                backgroundColor: "transparent",
                color: theme?.palette?.primary?.main
            },
        }
    }

    const onClear = (val) => {
        let updateValue = value?.filter(({ value }) => value !== val.value);
        if (props?.isDeletedValue) {
            props?.deletedValue(val, updateValue)
        }
        else {
            props?.onChange(updateValue)
        }
    }

    return (
        <>
            {label && (
                <Stack direction="row">
                    <Label label={label} labelColor={labelColor} labelSize={labelSize} labelStyle={props?.labelStyle} fontFamily={fontFamily} isRequired={isRequired} />
                    {/* <Typography color="error" variant="caption" display={"none"}>
                        &nbsp;*
                    </Typography> */}
                </Stack>
            )}

            {
                isPaginate ? (
                    <FormControl variant={variant ?? "outlined"} fullWidth>
                        <AsyncPaginate
                            key={key}
                            isClearable={isClearable}
                            isSearchable
                            components={{
                                IndicatorSeparator: () => null,
                                Control,
                                Option,
                                MultiValueContainer: () => null,
                            }}
                            value={value}
                            placeholder={placeholder}
                            loadOptions={loadOptions}
                            onChange={onChange}
                            options={options}
                            isLoading={loading}
                            defaultOptions={options}
                            styles={customStyles(props)}
                            isDisabled={isReadOnly}
                            isMulti={isMulti}
                            debounceTimeout={debounceTimeout}
                            reduceOptions={reduceOptions}
                            borderRadius={borderRadius}

                        />
                    </FormControl>
                ) : (
                    <FormControl variant={variant ?? "outlined"} fullWidth>
                        <Select
                            isClearable={isClearable}
                            required={true}
                            isSearchable
                            components={{
                                IndicatorSeparator: () => null,
                                Control,
                                Option,
                                MultiValueContainer: () => null,
                            }}
                            value={value}
                            placeholder={placeholder}
                            options={options}
                            isLoading={loading}
                            onChange={onChange}
                            styles={customStyles(props)}
                            isMulti={isMulti}
                            isDisabled={isReadOnly}
                            noSearch={noSearch}
                            borderRadius={borderRadius}
                        />
                    </FormControl>
                )
            }

            {props?.isError && (
                <Typography variant={"caption"} color={"error"} style={{ fontFamily: Regular }}>
                    {props?.errorMessage}
                </Typography>
            )}
            {
                (isMulti && value?.length > 0) &&
                <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={1}
                    flexWrap={"wrap"}
                    marginTop={2}
                >
                    {
                        value?.map((_) => (
                            <Wrapper
                                direction={"row"}
                                alignItems={"center"}
                                key={_?.value}
                                gap={1}
                            >
                                <Label>
                                    {_?.label}
                                </Label>
                                <div
                                    onClick={() => onClear(_)}
                                    style={{ display: 'flex', cursor: "pointer" }}
                                >
                                    <IconButton size="small">
                                        <CloseIcon htmlColor={theme?.palette?.text?.secondary}
                                            fontSize={"12px"} />
                                    </IconButton>
                                </div>
                            </Wrapper>
                        ))
                    }
                </Stack>
            }
        </>

    )
})
