import { Box, Grid } from "@mui/material";
import React from "react";
import {
    TextBox,
    SelectBox,
    DatePickerNew,
    ToggleButton,
    AddressBox,
    MultiImageUpload,
    ToggleBlockButton
} from "../../components"
import { MobileComponent } from "../mobileComponent";




export const FormGenerator = ({

    components = []


}) => {

    const SwitchComponents = (val, index) => {
        if (val?.isActive) {
            switch (val?.component) {
                case "text":
                    return (
                        <Grid
                            item
                            key={index}
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <TextBox
                                isRequired={val?.isRequired}
                                label={val?.label}
                                labelStyle={val?.labelStyle}
                                placeholder={val?.placeholder}
                                value={val?.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                multiline={val?.multiline}
                                isReadonly={val?.isReadonly}
                                type={val?.type}
                                endAdornment={val?.endAdornment}
                                startAdornment={val?.startAdornment}
                                fontSize={val?.fontSize}
                                variant={val?.variant}
                                sx={val?.sx}
                            />
                        </Grid>
                    );

                case "select":
                    return (
                        <Grid
                            item
                            key={index}
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <SelectBox
                                menuPlacement={val?.menuPlacement}
                                parentID={val?.id}
                                isRequired={val?.isRequired}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val?.value}
                                zIndex={val?.zIndex}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                isReadOnly={val?.isReadonly}
                                isPaginate={val?.isPaginate}
                                loadOptions={val?.loadOptions}
                                loading={val?.loading}
                                debounceTimeout={800}
                                options={val?.options ?? []}
                                key={val?.key}
                                variant={val?.variant}
                                noSearch={val?.noSearch}
                                labelStyle={val?.labelStyle}
                                borderRadius={val?.borderRadius}
                                borderBottom={val?.borderBottom}
                                borderStyle={val?.borderStyle}
                                noBorder={val?.noBorder}
                                padding={val?.padding}
                                border={val?.border}
                                radius={val?.radius}
                            />
                        </Grid>
                    );
                case "date":
                    return (
                        <Grid
                            item
                            key={index}
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <DatePickerNew
                                isRequired={val?.isRequired}
                                minDate={val?.minDate}
                                isTime={val?.isTime}
                                maxDate={val?.maxDate}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val?.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                isReadonly={val?.isReadonly}
                                className={val?.className}
                                labelStyle={val?.labelStyle}
                                customStyle={val?.customStyle}
                                sx={val?.sx}
                                calanderStyle={val?.calanderStyle}
                                right={val?.right}
                            />
                        </Grid>
                    );
                case "toggle":
                    return (
                        <Grid
                            key={index}
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <ToggleButton
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val?.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                multiline={val?.multiline}
                                isReadonly={val?.isReadonly}
                                type={val?.type}
                                endAdornment={val?.endAdornment}
                                startAdornment={val?.startAdornment}
                                fontSize={val?.fontSize}
                                options={val?.options ?? []}
                                borderRadius={'24px !important'}
                                padding={"13.5px 14px"}
                                backgroundColorunparent={'transparent'}
                                spacing={val?.spacing}
                                is_disable={val?.is_disable}
                            />
                        </Grid>
                    )
                case "toggleBlockButton":
                    return (
                        <Grid
                            key={index}
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <ToggleBlockButton
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val?.value}
                                isRequired={val?.isRequired}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                multiline={val?.multiline}
                                isReadonly={val?.isReadonly}
                                type={val?.type}
                                endAdornment={val?.endAdornment}
                                startAdornment={val?.startAdornment}
                                fontSize={val?.fontSize}
                                options={val?.options ?? []}
                                borderRadius={'24px !important'}
                                padding={"10px 14px"}
                                //marginRight={'-10px'}
                                backgroundColorunparent={'transparent'}
                                spacing={val?.spacing}
                                backgroundColor1={val?.backgroundColor1}
                                backgroundColor={val?.backgroundColor}
                                toogle_style_unselect={val?.toogle_style_unselect}
                                toogle_style={val?.toogle_style}

                            />
                        </Grid>
                    )
                case "multiImage":
                    return (
                        <Grid
                            key={index}
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >

                            <MultiImageUpload
                                t={val?.t}
                                label={val?.label}
                                value={val?.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                multiline={val?.multiline}
                                isReadonly={val?.isReadonly}
                                type={val?.type}
                                endAdornment={val?.endAdornment}
                                startAdornment={val?.startAdornment}
                                fontSize={val?.fontSize}
                                options={val?.options ?? []}
                                borderRadius={'8px !important'}
                                padding={"13.5px 14px"}
                                backgroundColorunparent={'#F6F6F6'}
                                onDelete={val?.onDelete}
                                labelStyle={val?.labelStyle}
                                customStyle={val?.customStyle}
                                text={val?.text ?? "Upload Bill/Invocie"}
                                sizexs={val?.sizexs}
                                onlyFiveFilesUpload={val?.onlyFiveFilesUpload}
                                isRecordingEnabled={val?.isRecordingEnabled}
                            />
                        </Grid>
                    )
                case "address":
                    return (
                        <Grid
                            key={index}
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <AddressBox
                                label={val?.label}
                                value={val?.value}
                                placeholder={val?.placeholder}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                multiline={val?.multiline}
                                isReadonly={val?.isReadonly}
                                type={val?.type}
                                fontSize={val?.fontSize}
                                Edit={val?.Edit}
                                routes={val?.route}
                                data={val?.data}
                            />
                        </Grid>
                    )
                case "mobileNumber":
                    return (
                        <Grid
                            key={index}
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <MobileComponent
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val?.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                multiline={val?.multiline}
                                isReadonly={val?.isReadonly}
                                type={val?.type}
                                fontSize={val?.fontSize}
                                handleFile={val?.handleFile}
                            />
                        </Grid>
                    )

                default:
                    return null;
            }

        }

    };


    return (
        <Box>
            {
                <Grid container spacing={2}>
                    {components?.length &&
                        components?.map((_, index) => SwitchComponents(_, index))}
                </Grid>
            }
        </Box>
    );
};
