import { Container, Grid, Typography, Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { WelcomePage_style } from "./style";
import { useLazyInitialRenderQuery } from "../../redux/services";
import { Buttons } from "../../components";
import { initital_screen_state } from "../../utils/login/utils";
import { useSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { config } from "../../config";
import mob_welcome_background from '../../utils/login/assets/mob_welcome_background.png';

const Welcome = ({ t = () => false }) => {
  const navigate = useNavigate();
  const [initialRender] = useLazyInitialRenderQuery();
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = React.useState(initital_screen_state);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  React.useEffect(() => {
    initialLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const img = new Image();

    img.src = screen_state?.mob_welcome_background;

    img.onload = () => {
      setIsBackgroundImageLoading(true);
    };

    img.onerror = () => {
      set_screen_state({ ...screen_state, mob_welcome_background: initital_screen_state?.mob_welcome_background });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen_state?.mob_welcome_background]);

  useEffect(() => {
    if (screen_state?.promotion_page_meta && screen_state?.promotion_page_meta?.is_active) {
      const img = new Image();

      img.src = screen_state?.promotion_page_meta?.mobile_background;

      img.onload = () => {
        setIsBackgroundImageLoading(true);
      };

      img.onerror = () => {
        set_screen_state({
          ...screen_state,
          promotion_page_meta: {
            ...screen_state?.promotion_page_meta,
            mobile_background: mob_welcome_background
          }
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen_state?.promotion_page_meta]);

  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    initialRender({}).then((res) => {
      let _ = res?.data?.data?.render_data?.[0]
      if (_) {
        let temp_screen_state = {
          ...screen_state,
          [_?.id && "id"]: _?.id,
          [_?.region && "region"]: _?.region,
          [_?.login_logo && "logo"]: _?.login_logo,
          [_?.web_background && "web_background"]: _?.web_background,
          [_?.mobile_background && "mob_background"]: _?.mobile_background,
          [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
          [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
          [_?.flag && "flag"]: _?.flag,
          [_?.domain && "domain"]: _?.domain,
          [_?.promotion_page_meta && "promotion_page_meta"]: _?.promotion_page_meta?.find?.(pp => pp?.app_build?.build_code === config?.xbuildCode),
        }
        set_screen_state(temp_screen_state);
      }
      set_is_loading(false);
    }).catch(() => {
      enqueueSnackbar(t('Some thing Went Wrong'), {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" }

      })
      setTimeout(() => {
        closeSnackbar()
      }, 2000)
      set_is_loading(false);
    });
  }
  React.useEffect(() => {
    if (localStorage?.getItem('auth_token')) {
      navigate(AppRoutes?.home)
    } else {
    }
    // eslint-disable-next-line
  }, [])


  const logIn = () => {
    navigate(
      AppRoutes?.login,
      {
        state: {
          origin: AppRoutes?.welcome
        },
      }
    )
  }

  return (
    is_loading
      ? <></>
      : (screen_state?.promotion_page_meta?.is_active
        ? <Container maxWidth={'sm'} sx={{ position: 'relative', padding: '0px !important' }}>
          <Box sx={{
            ...WelcomePage_style?.root,
            backgroundImage: isBackgroundImageLoading
              ? `url(${screen_state?.promotion_page_meta?.mobile_background
              ?? (mob_welcome_background)})`
              : "none",
            backgroundColor: '#757575',
          }}>
            <Grid
              item
              xs={12}
            >
            </Grid>
            <Box sx={WelcomePage_style?.btnGrid}>
              <Container maxWidth='sm' style={{ padding: "0px !important" }}>
                <img style={WelcomePage_style?.mob_logo} src={screen_state?.logo} alt="" />
                <Box height={"20px"} />
                <Box width={"100%"} display={""}>
                  <Typography sx={WelcomePage_style?.mob_description}>
                    {screen_state?.promotion_page_meta?.description
                      ?? (t("Easy, Secure & Most Reliable Solution For Managing your agreements"))}
                  </Typography>
                </Box>
                <Box height={"40px"} />
                <Buttons
                  fullWidth
                  variant="contained"
                  sx={WelcomePage_style?.setup_button}
                  onClick={logIn}
                  btnName={screen_state?.promotion_page_meta?.cta_text ?? t("Get Start / Log In")}
                  buttonStyle={{
                    color: "#FFFFFF",
                    "&:hover": {
                      color: '#FFFFFF',
                      backgroundColor: "#F15A29",
                    },
                  }}
                />
                <Box height={"20px"} />
                <Stack direction={"row"} width={"100%"} display={"flex"}
                  justifyContent={"center"} alignItems={"center"}>
                  <img style={{ height: "11px", objectFit: "contain" }}
                    src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                </Stack>
              </Container>
            </Box>
          </Box>
        </Container>
        : <Container maxWidth={'sm'} sx={{ position: 'relative', padding: '0px !important' }}>
          <Box sx={{
            ...WelcomePage_style?.root,
            backgroundImage: isBackgroundImageLoading
              ? `url(${mob_welcome_background})`
              : "none",
            backgroundColor: '#757575',
          }}>
            <Grid
              item
              xs={12}
            >
            </Grid>
            <Box sx={WelcomePage_style?.btnGrid}>
              <Container maxWidth='sm' style={{ padding: "0px !important" }}>
                <img style={WelcomePage_style?.mob_logo} src={screen_state?.logo} alt="" />
                <Box height={"20px"} />
                <Box width={"100%"} display={""}>
                  <Typography sx={WelcomePage_style?.mob_description}>
                    {t("Easy, Secure & Most Reliable Solution For Managing the Properties You Own")}
                  </Typography>
                </Box>
                <Box height={"40px"} />
                <Buttons
                  fullWidth
                  variant="contained"
                  sx={WelcomePage_style?.setup_button}
                  onClick={logIn}
                  btnName={t("Get Start / Log In")}
                  buttonStyle={{
                    color: "#FFFFFF",
                    "&:hover": {
                      color: '#FFFFFF',
                      backgroundColor: "#F15A29",
                    },
                  }}
                />
                <Box height={"20px"} />
                <Stack direction={"row"} width={"100%"} display={"flex"}
                  justifyContent={"center"} alignItems={"center"}>
                  <img style={{ height: "11px", objectFit: "contain" }}
                    src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                </Stack>
              </Container>
            </Box>
          </Box>
        </Container>)
  );
};

export default withTranslation("welcome")(Welcome);