import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme?.palette?.text?.secondary,
    fontSize: (props) => props?.labelSize ?? theme?.palette?.labelFontSize,
    fontFamily: (props) => (props?.fontFamily === "bold" ? Bold : SemiBold),
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  calander: {
    position: 'absolute',
    top: "51%",
    right: (props) => props?.right ?? '15px',
  },
  calendarArabic: {
    position: 'absolute',
    top: "51%",
    left: (props) => props?.right ?? '15px',
  },
  textbox: {
    backgroundColor: theme?.palette?.error?.contrastText,
    borderRadius: theme?.palette?.borderRadius ?? 4,
    border: `1px solid ${theme?.palette?.border?.main}`,
    [`& fieldset`]: {
      borderRadius: theme?.palette?.borderRadius ?? 4,
      height: (props) => (props.multiline ? "unset" : 48),
      border: `1px solid ${theme?.palette?.border?.main}`,
      "&:hover": {
        border: `1px solid ${theme?.palette?.primary?.main}}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
      backgroundColor: theme?.palette?.error?.contrastText,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme?.palette?.error?.contrastText,
      fontFamily: SemiBold,
      '&:hover fieldset': {
        borderColor: theme?.palette?.border?.main,
      },

    },
    "&:hover": {
      border: `1px solid ${theme?.palette?.primary?.main}`,
    },
  },
  calendarText: {
    cursor: "pointer"
  },
  Datepicker:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    height: (props) => (props.multiline ? "unset" : 45),
    width: '100%',
    cursor: 'pointer',
    backgroundColor:"#fff",
    ":-webkit-autofill": {
        WebkitBoxShadow: "none !important",
        backgroundColor: "red !important",
    },
    '&:focusVisible': {
        border: '1.5px solid #E2E2E2',
    },

    padding: '20px 18px'
},
input:{
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    padding:"0px !important",
    color:"#091B29",
},
value: {
    color: "#a2a2a2",
    fontSize:"0.75rem",
    fontFamily: SemiBold
},
placeholder: {
    color: "#333333",
    fontSize: "0.8125rem",
},
}));